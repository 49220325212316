import LoginPage from "@/components/login/LoginPage.vue"
import AppointmentListing from "@/components/appointments/AppointmentListing.vue"
import TestPage from "@/components/TestPage.vue"
import AppointmentDetails from "@/components/pages/AppointmentDetails.vue"
import OpportunityEdit from "@/modules/Opportunity/Pages/OpportunityEdit.vue"
import { createRouter, createWebHistory } from "vue-router"

const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: "/login", component: LoginPage, name: "LoginPage" },
        {
            path: "/dashboard",
            component: AppointmentListing,
            name: "AppointmentListing",
        },
        {
            path: "/appointments/:id",
            component: AppointmentDetails,
            name: "AppointmentDetails",
        },
        {
            path: "/opportunities/:id/edit",
            component: OpportunityEdit,
            name: "OpportunityEdit",
        },
        {
            path: "/testPage",
            component: TestPage,
            name: "TestPage",
        },
        {
            path: "/",
            component: AppointmentListing,
            name: "AppointmentListingHome",
        },
    ],
})

router.beforeEach((to, from, next) => {
    const loggedIn = localStorage.getItem("user")

    if (to.name !== "LoginPage" && !loggedIn) {
        next({ name: "LoginPage" })
    } else {
        next()
    }
})

export default router
