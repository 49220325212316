<template>
    <div>
        <h2 class="heading text-center">Customer Information</h2>
        <div class="info-card shadow">
            <div class="row">
                <div class="col-sm-5">
                    <span class="card-label">Customer Id</span>
                </div>
                <div class="col-sm-7 text-sm-right">
                    <span id="customer_id" v-text="customer.id" class="card-text"></span>
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col-sm-5">
                    <span class="card-label">Name</span>
                </div>
                <div class="col-sm-7 text-sm-right">
                    <span id="customer_name" v-text="customer.display_name" class="card-text" />
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col-sm-5">
                    <span class="card-label">Address Line 1</span>
                </div>
                <div class="col-sm-7 text-sm-right">
                    <span id="customer_address_line_1" class="card-text">{{ customer.address_line_1 }}</span>
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col-sm-5">
                    <span class="card-label">Address Line 2</span>
                </div>
                <div class="col-sm-7 text-sm-right">
                    <span
                        id="customer_address_line_2"
                        v-if="customer.address_line_2 && customer.address_line_2.length > 0"
                        class="card-text"
                        >{{ customer.address_line_2 }} {{ cityStateZip }}</span
                    >
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col-sm-5">
                    <span class="card-label">Zip Code</span>
                </div>
                <div class="col-sm-7 text-sm-right">
                    <span id="customer_location" class="card-text">{{ cityStateZip }}</span>
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col-sm-5">
                    <span class="card-label">Email</span>
                </div>
                <div class="col-sm-7 text-sm-right">
                    <span id="customer_email" v-text="customer.emailaddr" class="card-text"></span>
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col-sm-5">
                    <span class="card-label">Preferred Phone</span>
                </div>
                <div class="col-sm-7 text-sm-right">
                    <span id="customer_phone" v-text="customer.preferred_phone" class="card-text"></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Panel from "@/components/base/Panel.vue"

export default {
    components: { Panel },
    props: {
        customer: {
            type: Object,
            required: true,
        },
    },
    computed: {
        cityStateZip() {
            return `${this.customer.city}, ${this.customer.state_abbr} ${this.customer.zip}`
        },
    },
}
</script>

<style scoped></style>
