import newMapper from "@/middleware/mapper"
import SlopeTypeApi from "@/modules/SlopeType/api"
import reset from "@/store/reset"

const defaultState = () => ({
    slopeTypes: [],
    slopeTypeLoaded: false,
})

export default {
    namespaced: true,
    state: defaultState,
    getters: {
        getSlopeTypes: (state) => state.slopeTypes,
        areSlopeTypesLoaded: (state) => state.slopeTypeLoaded,
    },
    mutations: {
        SET_SLOPE_TYPES: (state, payload) => (state.slopeTypes = payload),
        SET_LOADED: (state, payload) => (state.slopeTypeLoaded = payload),
        RESET: (state) => reset(state, defaultState()),
    },
    actions: {
        fetchSlopeTypes: ({ commit }, isOnline) => {
            newMapper({ handler: SlopeTypeApi.getAll, action: "GET" }, "slope_type", isOnline).then((slopeTypes) => {
                commit("SET_SLOPE_TYPES", slopeTypes)
                commit("SET_LOADED", true)
            })
        },
        loadSlopeTypes: ({ commit }) => {
            newMapper({ handler: SlopeTypeApi.getAll, action: "GET" }, "slope_type", false).then((slopeTypes) => {
                commit("SET_SLOPE_TYPES", slopeTypes)
                commit("SET_LOADED", true)
            })
        },
        reset: ({ commit }) => commit("RESET"),
    },
}
