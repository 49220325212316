import newMapper from "@/middleware/mapper"
import RailApi from "@/modules/Rail/api"
import reset from "@/store/reset"

const defaultState = () => ({
    rails: [],
    railsLoaded: false,
})

export default {
    namespaced: true,
    state: defaultState,
    getters: {
        getRailTypes: (state) => state.rails,
        areRailsLoaded: (state) => state.railsLoaded,
    },
    mutations: {
        SET_RAILS: (state, payload) => {
            state.rails = payload
        },
        SET_RAILS_LOADED: (state, payload) => {
            state.railsLoaded = payload
        },
        RESET: (state) => reset(state, defaultState()),
    },
    actions: {
        fetchRails: ({ commit }, isOnline) => {
            commit("SET_RAILS_LOADED", false)
            newMapper({ handler: RailApi.getAll, action: "GET" }, "rail_type", isOnline).then((rails) => {
                commit("SET_RAILS", rails)
                commit("SET_RAILS_LOADED", true)
            })
        },
        reset: ({ commit }) => commit("RESET"),
    },
}
