export default {
    accessory: "++id",
    appointment: "id",
    discount_types: "value",
    discount_options: "++id",
    franchisee: "++id",
    franchisee_financing_option: "[id+franchisee_id],franchisee_id",
    franchisee_pricing_option: "[id+franchisee_id],franchisee_id",
    franchisee_product_price: "[id+franchisee_id],franchisee_id",
    franchisee_tax_rate: "[id+franchisee_id],franchisee_id",
    jopportunity_product: "[opportunity_id+product_id]",
    opportunity: "++id",
    opportunity_financing_option: "[id+opportunity_id],opportunity_id",
    opportunity_pricing_option: "[id+opportunity_id],opportunity_id",
    opportunity_product: "[id+opportunity_id],opportunity_id",
    package: "++id",
    product: "++id",
    product_type: "++id",
    rail_type: "++id",
    slope_type: "++id",
}
