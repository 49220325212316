export default class OpportunityCalculator {
    constructor(opportunity, pricing_option, products, accessories) {
        this._opportunity = opportunity;
        //this._pricing_option = opportunity.pricing_option;
        this._pricing_option = pricing_option;
        this._opportunity_prices = opportunity.product_prices;
        this._franchisee_id = opportunity.franchisee_id;
        this._products = products;
        this._accessories = accessories;
    }

    fetchBaseCost(item, product) {
        let price = 0.0;

        if (this._opportunity_prices === undefined) {
            price = this.calculateBaseCost(item, product);
        } else {
            let opportunityProduct = this._opportunity_prices.find(
                (row) => row.product_id === item.product_id,
            );

            if (
                opportunityProduct === undefined ||
                product.is_sp_like === "y" ||
                product.look_up_item_number == "y"
            ) {
                price = this.calculateBaseCost(item, product);
            } else {
                price = opportunityProduct.base_cost;
            }
        }
        return parseFloat(price);
    }

    fetchBasePrice(item, product) {
        let price = this.fetchBaseCost(item, product);

        price = this.basePriceAdjustments(item, product, price);
        return parseFloat(price);
    }

    fetchUnitPrice(item) {
        let product = this._products.find(
            (product) => product.id === item.product_id,
        );
        if (product === undefined) {
            return 0.0;
        }

        if (item.unit_price) {
            return item.unit_price;
        }

        let price = this.fetchBasePrice(item, product);
        if (isNaN(price)) {
            price = this.fetchBasePrice(item, product);
        }

        let scoopPrice = this.getScoopPrice(item, product);
        let slopePrice = this.getSlopePrice(item, product);
        let dividerPrice =
            item.number_of_dividers * this._pricing_option.cost_dividers;
        let groovesPrice =
            item.number_of_additional_grooves *
            this._pricing_option.cost_grooves;
        let aBbothPrice = this.getAbOrBothPrice(item);

        //console.log("fetchUnitPrice", price, scoopPrice, slopePrice, dividerPrice, groovesPrice, aBbothPrice)
        return parseFloat(
            price +
                scoopPrice +
                slopePrice +
                dividerPrice +
                groovesPrice +
                aBbothPrice,
        );
    }

    fetchFinalPrice(item) {
        let unitPrice = this.fetchUnitPrice(item);
        return unitPrice * (parseFloat(item.qty) || 0);
    }

    calculateBaseCost(item, product) {
        if (product.is_sp_like === "y") {
            return this.spUnitPrice(item);
        }

        if (product.look_up_item_number === "y") {
            return this.accessoryUnitPrice(item, product);
        }

        /** Remove Default Bottom Color and Rail Costs **/
        let defaultBcCost = this.defaultBottomColorsCost(
            product,
            this._pricing_option,
        );
        let defaultRailCost = this.defaultRailsCost(
            product,
            this._pricing_option,
        );
        let price = product.franchisee_prices.find(
            (price) => price.franchisee_id === this._franchisee_id,
        );
        return parseFloat(price.retail - defaultBcCost - defaultRailCost);
    }

    spUnitPrice(item) {
        return parseFloat(item.opening_width);
    }

    accessoryUnitPrice(item, product) {
        let accessory = this._accessories.find(
            (accessory) =>
                accessory.id === item.comments &&
                accessory.distributor_id === product.distributor_id,
        );
        let accessoryPrice = accessory.find(
            (price) => price.franchisee_id === this._franchisee_id,
        );
        return parseFloat(accessoryPrice.retail);
    }
    basePriceAdjustments(item, product, price) {
        let defaultRailCost = this.defaultRailsCost(
            product,
            this._pricing_option,
        );

        /** Add Selected Bottom Color and Rail Costs **/
        let bottomColorCost = this.selectedBottomColorsCost(product, item);
        let selectedRailCost = this.selectedRailsCost(product, item);
        let railCost =
            selectedRailCost > defaultRailCost
                ? selectedRailCost
                : defaultRailCost;

        return parseFloat(price) + bottomColorCost + railCost;
    }

    calculateMaterialPrice(item) {
        let product = this._products.find(
            (product) => product.id === item.product_id,
        );
        if (product === undefined) {
            return 0.0;
        }

        let price = this.fetchUnitPrice(item);

        let isSrr = product.product_type.name === "SRR";
        if (product.product_type.name === "SRR") {
            if (this._pricing_option.stile_id === 1) {
                return parseFloat(price) * (parseFloat(item.qty) || 0);
            }
            return 0.0;
        }
        if (product.count_as_materials_only === "y") {
            return parseFloat(price) * (parseFloat(item.qty) || 0);
        }
        if (product.count_as_labor_only === "y") {
            return 0.0;
        }

        let materialRate =
            this._pricing_option.labor >= 1
                ? 1 - parseFloat(this._pricing_option.labor) / 100
                : 1 - parseFloat(this._pricing_option.labor);

        //console.log("calculateMaterialPrice", price, materialRate);
        return parseFloat(price * materialRate * (parseFloat(item.qty) || 0));
    }

    calculateLaborPrice(item) {
        let product = this._products.find(
            (product) => product.id === item.product_id,
        );
        if (product === undefined) {
            return 0.0;
        }

        let price = this.fetchUnitPrice(item);
        if (product.product_type.name === "SRR") {
            if (this._pricing_option.stile_id === 2) {
                return parseFloat(price) * (parseFloat(item.qty) || 0);
            }
            return 0.0;
        }
        if (product.count_as_labor_only === "y") {
            return parseFloat(price) * (parseFloat(item.qty) || 0);
        }
        if (product.count_as_materials_only === "y") {
            return 0.0;
        }

        let laborRate =
            this._pricing_option.labor >= 1
                ? parseFloat(this._pricing_option.labor) / 100
                : parseFloat(this._pricing_option.labor);
        let laborPrice = price * laborRate * (parseFloat(item.qty) || 0);

        //console.log("calculateLaborPrice", item.product_id || "new", product.name, laborPrice, price, item.unit_price, laborRate, (item.qty ?? 0));
        return parseFloat(laborPrice);
    }

    calculateDeliveryPrice(item) {
        let factor = this._pricing_option.cost_delivery;
        let factorType = this._pricing_option.delivery_type;
        let price = 0.0;

        //console.log("calculateDeliveryPrice", factor, factorType, this._pricing_option.shipping_id);

        switch (this._pricing_option.shipping_id) {
            case 1:
                /** shipping_id = 1 ~ Materials Only **/
                price = this.calculateMaterialPrice(item) * item.qty;
                return this.deliveryPriceCalculator(price, factor, factorType);
                break;
            case 2:
                /** shipping_id = 2 ~ Materials & Labor Only **/
                price = this.fetchUnitPrice(item) * item.qty;
                return this.deliveryPriceCalculator(price, factor, factorType);
        }

        return false;
    }

    calculateTaxPrice(item, taxRate, deliveryInDollars = false) {
        let total = 0.0;
        if (this._pricing_option.taxable_materials === "y") {
            total += this.calculateMaterialPrice(item);
        }
        if (this._pricing_option.taxable_labor === "y") {
            total += this.calculateLaborPrice(item);
        }
        if (
            this._pricing_option.taxable_delivery === "y" &&
            !deliveryInDollars
        ) {
            total += this.calculateDeliveryPrice(item);
        }

        return total * (taxRate / 100);
    }
    calculateDiscount(materialPrice, laborPrice) {
        let factor = this._pricing_option.discount_value;
        let factorType = this._pricing_option.discount_type;
        let price = 0.0;

        switch (this._pricing_option.discount_id) {
            case 1:
                /** discount_id = 1 ~ Materials Only **/
                price = materialPrice;
                break;
            case 2:
                /** discount_id = 2 ~ Labor Only **/
                price = laborPrice;
                break;
            case 3:
                /** discount_id = 3 ~ Materials & Labor Only **/
                price = materialPrice + laborPrice;
                break;
            default:
                return 0.0;
        }

        let discountFactor = 0.0;
        switch (factorType) {
            case "Percent":
                discountFactor = parseFloat(factor) / 100;
                return price * discountFactor;
            case "Dollars":
                discountFactor = parseFloat(factor);
                return discountFactor > price ? 0 : discountFactor;
        }
        return false;
    }

    deliveryPriceCalculator(price, factor, factorType) {
        let deliveryFactor = 0.0;
        switch (factorType) {
            case "Percent":
                deliveryFactor =
                    factor > 1 ? parseFloat(factor) / 100 : parseFloat(factor);
                //console.log("", price, deliveryFactor, factorType);
                return price * deliveryFactor;
                break;
            case "Dollars":
                //return 0;
                deliveryFactor = parseFloat(factor);
                //console.log("", price, deliveryFactor, factorType);
                return price + deliveryFactor;
        }
    }

    defaultBottomColorsCost(product, pricingOptions) {
        let costs = 0.0;
        let property = null;

        for (let idx in product.bottom_colors) {
            let bottomColor =
                product.bottom_colors[idx].product_code.toLowerCase();
            property = `base_cost_subtract_${bottomColor}_bottom_color`;
            if (product[property] === "y") {
                let bottomColorCost = pricingOptions[`cost_${bottomColor}`];
                costs += parseFloat(bottomColorCost);
            }
        }

        return costs;
    }

    defaultRailsCost(product, pricingOptions) {
        let costs = 0.0;
        let property = null;

        if (product.rail_types && product.rail_types.length === 1) {
            let product_code = product.rail_types[0].product_code.toLowerCase();
            let railCost = pricingOptions[`cost_${product_code}`];
            costs += parseFloat(railCost);
        } else {
            for (let idx in product.rail_types) {
                let product_code =
                    product.rail_types[idx].product_code.toLowerCase();
                property = `base_cost_subtract_${product_code}_rail`;
                if (product[property] === "y") {
                    let railCost = pricingOptions[`cost_${product_code}`];
                    costs += parseFloat(railCost);
                }
            }
        }
        return costs;
    }

    selectedBottomColorsCost(product, item) {
        let costs = 0.0;
        let bottomColor = product.bottom_colors.find(
            (bottomColor) => bottomColor.id === item.bottom_color_id,
        );

        if (bottomColor !== undefined) {
            let product_code = bottomColor.product_code.toLowerCase();
            if (product_code !== undefined) {
                let bottomColorCost =
                    this._pricing_option[`cost_${product_code}`];
                costs += parseFloat(bottomColorCost);
            }
        }
        return costs;
    }

    selectedRailsCost(product, item) {
        let costs = 0.0;

        if (product.rail_types.length === 1) {
            return 0.0;
        }

        let railType = product.rail_types.find(
            (rail) => rail.id === item.rail_type_id,
        );
        if (railType !== undefined) {
            let product_code = railType.product_code
                .toLowerCase()
                .replace("-", "");
            if (product_code !== undefined) {
                let railCost = this._pricing_option[`cost_${product_code}`];
                costs += parseFloat(railCost);
            }
        }
        return costs;
    }

    getAbOrBothPrice(item) {
        switch (item.a_b_or_both) {
            case "A":
                return this._pricing_option.cost_a;
            case "B":
                return this._pricing_option.cost_b;
            case "Both":
                return this._pricing_option.cost_both;
            default:
                return 0.0;
        }
    }

    getScoopPrice(item, product) {
        if (["n", "", null].includes(item.scoop)) {
            return 0;
        }

        switch (product.package.abbr) {
            case "DL":
                return this._pricing_option.cost_scoop_light;
                break;
            case "DD":
                return this._pricing_option.cost_scoop_dark;
                break;
            case "DM":
                return this._pricing_option.cost_scoop_medium;
                break;
            case "DW":
            case "WM":
                return this._pricing_option.cost_scoop_white;
                break;
            default:
                return this._pricing_option.cost_scoop_basic;
        }
    }

    getSlopePrice(item, product) {
        if ([1, "", null].includes(item.slope_type_id)) {
            return 0;
        }

        switch (product.package.abbr) {
            case "DL":
                return this._pricing_option.cost_slope_light;
                break;
            case "DD":
                return this._pricing_option.cost_slope_dark;
                break;
            case "DM":
                return this._pricing_option.cost_slope_medium;
                break;
            case "DW":
            case "WM":
                return this._pricing_option.cost_slope_white;
                break;
            default:
                return this._pricing_option.cost_slope_basic;
        }
    }
}
