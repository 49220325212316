<template>
    <div>
        <hr />
        <div class="row align-items-center">
            <div class="col-6">10% ORDER</div>
            <div class="col-6 text-right">
                <div class="d-inline-flex justify-content-end border-dark text-right p-1">
                    <span class="pr-3">$</span>
                    <span class="price">
                        <input
                            type="text"
                            class="form-control"
                            v-model="deposit"
                            @change="updateDepositPaid"
                            placeholder="0.00"
                        />
                    </span>
                </div>
            </div>
        </div>
        <hr />
        <div class="row mt-4">
            <div class="col-6">40% MSMT/PROD ORDER</div>
            <div class="col-6 text-right">
                <span class="pr-3">$</span>
                <span class="price">{{ currency(msmtAmount) }}</span>
            </div>
        </div>
        <hr />
        <div class="row mt-4">
            <div class="col-6">50% INSTALL BAL. DUE</div>
            <div class="col-6 text-right">
                <span class="pr-3">$</span>
                <span class="price">{{ currency(installAmount) }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import numeral from "numeral"
import { mapActions, mapGetters } from "vuex"

export default {
    data() {
        return {
            deposit: 0.0,
        }
    },
    computed: {
        ...mapGetters({
            totals: "opportunity/getTotals",
            opportunity: "opportunity/getOpportunity",
            opportunityProducts: "opportunity/getProducts",
        }),
        labelClasses() {
            return "col-md-6 col-lg-8 col-form-label text-right"
        },
        dataClasses() {
            return "col-md-6 col-lg-4"
        },
        balanceDue() {
            let sum = 0.0
            if (this.orderProducts.length === 0) {
                return sum
            }
            sum = this.totals.opportunity_total
            sum -= numeral(this.totals.initial_deposit).value() || 0.0

            return sum
        },
        msmtAmount() {
            if (this.orderProducts.length === 0) {
                return 0.0
            }
            return numeral((this.totals.opportunity_total * 2) / 5).value()
        },
        installAmount() {
            if (this.orderProducts.length === 0) {
                return 0.0
            }
            return numeral(this.totals.opportunity_total / 2).value()
        },
        orderProducts() {
            return this.opportunityProducts.filter(
                (product) => product.use_in_order === "y" && ![null, 0].includes(product.product_id),
            )
        },
    },
    watch: {
        totals() {
            this.setDepositValue()
        },
    },
    created() {
        this.setDepositValue()
    },
    methods: {
        ...mapActions({
            setTotals: "opportunity/fetchTotals",
            updateTotals: "opportunity/updateTotals",
        }),
        setDepositValue() {
            if (this.orderProducts.length === 0) {
                this.deposit = numeral(0.0).format(",3.00")
                return
            }
            this.deposit =
                numeral(this.totals.initial_deposit).value() > 0
                    ? numeral(this.totals.initial_deposit).format(",3.00")
                    : numeral(this.totals.opportunity_total / 10).format(",3.00")
        },
        updateDepositPaid(event) {
            this.totals.initial_deposit = numeral(event.target.value.toString()).format(",3.00")
            this.updateTotals({
                id: this.opportunity.id,
                totals: { initial_deposit: numeral(this.totals.initial_deposit).value() },
            })
        },
    },
}
</script>

<style scoped></style>