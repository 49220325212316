import SyncApi from "@/api/getSyncdataApi";
import Localbase from "localbase"

export default {
    
    async getAllSyncData(isOnline = true) {
        //console.log("D1")
        let db = new Localbase("wishmaker")

        let data = {}
        //console.log("D2")
        return await SyncApi.getSyncData(data)

    },
};

 