const prefix = `/auth`

export default {
    login,
    logout,
}

async function  login(username, password) {
    return await axios
        .post(`${prefix}/login`, { username, password })
        .then(handleResponse)
        .then(async(user) => {
            // login successful if there's a jwt token in the response
            if (user.token) {
                
                // store user details and jwt token in local storage to keep user logged in between page refreshes
               // await localStorage.setItem("user", JSON.stringify(user))
                await localStorage.setItem("user", JSON.stringify(user))
                await localStorage.setItem("back-user", JSON.stringify(user))
                window.axios.defaults.headers.common["Authorization"] =
                    "Bearer " + user.token
            }

            return user
        })
        .catch(async () => {
            let encodedString = btoa(username + "-" + password)
            let userGet = JSON.parse(localStorage.getItem("back-user"))
            // Decode the String
            //var decodedString = atob(encodedString);
            if (userGet.usertoken == encodedString) {
                window.axios.defaults.headers.common["Authorization"] =
                    "Bearer " + userGet.token
                await localStorage.setItem("user", JSON.stringify(userGet))
                return userGet
            }
        })
}

function logout() {
    // remove user from local storage to log user out
    //localStorage.removeItem("user")
}

function handleResponse(response) {
    if (!(response.statusText === "OK")) {
        if (response.status === 401) {
            // auto logout if 401 response returned from api
            //logout()
            this.$router.replace({ name: "LoginPage" })
        }
        const error = (response && response.message) || response.statusText
        return Promise.reject(error)
    }
    return response.data
}
