import newMapper from "@/middleware/mapper"
import AccessoryApi from "@/modules/Accessory/api"
import reset from "@/store/reset"

const defaultState = () => ({
    accessories: [],
    accessoriesLoaded: false,
})

export default {
    namespaced: true,
    state: defaultState,
    getters: {
        getAccessories: (state) => state.accessories,
        areAccessoriesLoaded: (state) => {
            return !!state.accessoriesLoaded
        },
    },
    mutations: {
        SET_ACCESSORIES: (state, payload) => {
            state.accessories = payload
            state.accessoriesLoaded = true
        },
        RESET: (state) => reset(state, defaultState()),
    },
    actions: {
        fetchAccessories: ({ commit }, { opportunity_id, isOnline }) => {
            newMapper(
                { handler: AccessoryApi.getAll, action: "GET", keys: { opportunity_id } },
                "accessory",
                isOnline,
            ).then((accessories) => {
                commit("SET_ACCESSORIES", accessories)
            })
        },
        loadAccessories: ({ commit }) => {
            newMapper(
                { handler: AccessoryApi.getAll, action: "GET", },
                "accessory",
                false,
            ).then((accessories) => {
                commit("SET_ACCESSORIES", accessories)
            })
        },
        reset: ({ commit }) => commit("RESET"),
    },
}
