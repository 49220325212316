import newMapper from "@/middleware/mapper"
import FranchiseeApi from "@/modules/Franchisee/api"
import reset from "@/store/reset"

const defaultState = () => ({
    taxRates: [],
    taxRatesLoaded: false,
    pricingOptions: [],
    pricingOptionsLoaded: false,
    financingOptions: [],
    financingOptionsLoaded: false,
    productPrices: [],
    productPricesLoaded: false,
})

export default {
    namespaced: true,
    state: defaultState,
    getters: {
        getTaxRates: (state) => state.taxRates,
        areTaxRatesLoaded: (state) => state.taxRatesLoaded,
        getPricingOptions: (state) => state.pricingOptions,
        arePricingOptionsLoaded: (state) => state.pricingOptionsLoaded,
        getFinancingOptions: (state) => state.financingOptions,
        areFinancingOptionsLoaded: (state) => state.financingOptionsLoaded,
        getProductPrices: (state) => state.productPrices,
        areProductPricesLoaded: (state) => state.productPricesLoaded,
    },
    mutations: {
        SET_TAX_RATES: (state, payload) => {
            state.taxRates = payload
        },
        SET_TAX_RATES_LOADED: (state, payload) => {
            state.taxRatesLoaded = payload
        },
        SET_PRICING_OPTIONS: (state, payload) => {
            state.pricingOptions = payload
        },
        SET_PRICING_OPTIONS_LOADED: (state, payload) => {
            state.pricingOptionsLoaded = payload
        },
        SET_FINANCING_OPTIONS: (state, payload) => {
            state.financingOptions = payload
        },
        SET_FINANCING_OPTIONS_LOADED: (state, payload) => {
            state.financingOptionsLoaded = payload
        },
        SET_PRODUCT_PRICES: (state, payload) => {
            state.productPrices = payload
        },
        SET_PRODUCT_PRICES_LOADED: (state, payload) => {
            state.productPricesLoaded = payload
        },
        RESET: (state) => reset(state, defaultState()),
    },
    actions: {
        fetchFranchiseeTaxRates: ({ commit, state }, isOnline) => {
            newMapper(
                {
                    handler: FranchiseeApi.taxRates,
                    action: "GET",
                },
                "franchisee_tax_rate",
                isOnline,
            ).then((tax_rates) => {
                commit("SET_TAX_RATES", tax_rates)
                commit("SET_TAX_RATES_LOADED", true)
            })
        },
        loadFranchiseeTaxRates: ({ commit, state }) => {
            newMapper(
                {
                    handler: FranchiseeApi.taxRates,
                    action: "GET",
                },
                "franchisee_tax_rate",
                false,
            ).then((tax_rates) => {
                commit("SET_TAX_RATES", tax_rates)
                commit("SET_TAX_RATES_LOADED", true)
            })
        },
        fetchFranchiseePricingOptions: ({ commit, state }, isOnline) => {
            newMapper(
                {
                    handler: FranchiseeApi.pricingOptions,
                    action: "GET",
                },
                "franchisee_pricing_option",
                isOnline,
            ).then((pricingOptions) => {
                commit("SET_PRICING_OPTIONS", pricingOptions)
                commit("SET_PRICING_OPTIONS_LOADED", true)
            })
        },
        fetchFranchiseeFinancingOptions: ({ commit, state }, isOnline) => {
            newMapper(
                {
                    handler: FranchiseeApi.financingOptions,
                    action: "GET",
                },
                "franchisee_financing_option",
                isOnline,
            ).then((financingOptions) => {
                commit("SET_FINANCING_OPTIONS", financingOptions)
                commit("SET_FINANCING_OPTIONS_LOADED", true)
            })
        },
        fetchFranchiseeProductPrices: ({ commit, state }, isOnline) => {
            newMapper(
                {
                    handler: FranchiseeApi.productPrices,
                    action: "GET",
                },
                "franchisee_product_price",
                isOnline,
            ).then((productPrices) => {
                commit("SET_PRODUCT_PRICES", productPrices)
                commit("SET_PRODUCT_PRICES_LOADED", true)
            })
        },
        reset: ({ commit }) => commit("RESET"),
    },
}
