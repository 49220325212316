<template>
    <div class="row text-nowrap justify-content-center justify-content-sm-start">
        <div class="col flex-grow-0 my-1" v-for="option in optionList" :key="`bind-${option.attr}`">
            <button :class="option.btnClass" :key="`options-${option.attr}`" @click="toggleOptionChecks(option.attr)">
                {{ checkButtonLabel(option.attr) }} <br />
                {{ option.label }}
            </button>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"

export default {
    computed: {
        ...mapGetters({
            opportunity: "opportunity/getOpportunity",
            items: "opportunity/getProducts",
            beingSaved: "opportunity/getBeingSaved",
        }),
    },
    data() {
        return {
            buttonClasses: "btn btn-primary",
            optionList: [
                { attr: "a_option", label: "Option A", btnClass: "btn btn-primary" },
                { attr: "b_option", label: "Option B", btnClass: "btn btn-primary" },
                { attr: "c_option", label: "Option C", btnClass: "btn btn-primary" },
                { attr: "d_option", label: "Option D", btnClass: "btn btn-primary" },
                { attr: "use_in_order", label: "In Order", btnClass: "btn btn-secondary" },
            ],
            optionCounter: {},
            productUpdates: [],
            localItems: [],
        }
    },
    watch: {
        items(newVal) {
            this.localItems = newVal
            this.buildOptionCounters()
        },
    },
    created() {
        this.localItems = this.items
        this.buildOptionCounters(this.items)
    },
    methods: {
        ...mapActions({
            updateProduct: "opportunity/saveProduct",
            setBeingSaved: "opportunity/setBeingSaved",
            unsetBeingSaved: "opportunity/unsetBeingSaved",
        }),
        isAllChecked(option) {
            let checkableItems = this.items.filter((row) => {
                return !["0", 0, "", null].includes(row.product_id)
            })
            return this.optionCounter[option] >= checkableItems.length
        },
        checkButtonLabel(option) {
            let allChecked = this.isAllChecked(option)
            return allChecked ? `Uncheck All` : `Check All`
        },
        buildOptionCounters() {
            this.resetOptionList()
            this.localItems.forEach((row) => {
                this.optionList.forEach((option) => {
                    if (row[option.attr] === "y" && !["0", 0, "", null].includes(row.product_id)) {
                        this.optionCounter[option.attr]++
                    }
                })
            })
        },
        resetOptionList() {
            this.optionList.forEach((option) => {
                if (this.optionCounter[option.attr] === undefined) {
                    this.optionCounter[option.attr] = 0
                } else {
                    this.optionCounter[option.attr] = 0
                }
            })
        },
        toggleOptionChecks(option) {
            this.setBeingSaved([`updating-${option}`])
            let productUpdates = []
            let optionValue = this.isAllChecked(option) ? "n" : "y"
            this.localItems.forEach((item, idx) => {
                if (item[option] !== optionValue && !["0", 0, "", null].includes(item.product_id)) {
                    item[option] = optionValue
                    productUpdates.push(
                        this.updateProduct(
                            {
                                idx,
                                opportunityId: this.opportunity.id,
                                product: item,
                                whereAt: "optionButtons",
                            },
                            this.isOnline,
                        ),
                    )
                }
            })

            Promise.all(productUpdates).then((response) => {
                this.unsetBeingSaved([`updating-${option}`])
            })
        },
    },
}
</script>

<style scoped></style>