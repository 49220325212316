<template>
    <div>
    
       <div class="row">
                <div class="col text-right pb-4">
                    <button class="btn btn-success" @click="syncData()">Sync Data</button>
                </div>
        </div>
        
        <Modal :show="isLoading">
            <div class="container-fluid">
            <p  v-html="loadingMessage()" />
            </div>
        </Modal>
    </div>
</template>

<script>
//import { mapActions, mapGetters } from "vuex"
import SyncApi from "@/api/syncdataApi"
import syncDataMiddleware from "@/middleware/syncdataMiddleware.js"
import Modal from "@/components/base/Modal.vue"
//opportunity/sync
export default {
    components: {
        Modal,
    },
    data() {
        return {
            isLoading: false,
        }
    },
    computed: {
       
    },
    methods: {
        
        syncData (){
            this.isLoading = true;
            this.loadingMessage()
            syncDataMiddleware.sendSyncData(this.isOnline).then(async ({ data }) => {
                this.isLoading = false;
            })
            .catch(async (e) => {
                this.isLoading = false;
            });
        },
        loadingMessage() {
            let name = 'Sync data is in progress'
            let loadingIcon = `<i class="loading-icons fas fa-spinner fa-spin text-info fa-lg" style="margin-right: 35px;"></i>`
            let loadingMessage = `${name}...`
            return `${loadingIcon} ${loadingMessage}`
        }
        
    }
}
</script>

<style scoped></style>
