<template>
    <div>
        <!-- <div class="form-group row">
<label :class="labelClasses">Deposit Paid</label>
<div :class="dataClasses">
<div class="input-group input-group-sm option-prices-field">
    <div class="input-group-prepend">
        <span class="input-group-text">$</span>
    </div>
    <input type="text" class="form-control fix-rounded-right"
           v-model="totals.initial_deposit" @change="updateDepositPaid"/>
</div>
</div>
</div> -->
        <hr />
        <div class="row align-items-center">
            <div class="col-6">DEPOSIT PAID</div>
            <div class="col-6 text-right">
                <div class="d-inline-flex justify-content-end border-dark text-right p-1">
                    <span class="pr-3">$</span>
                    <span class="price">
                        <input
                            type="text"
                            class="form-control"
                            v-model="totals.initial_deposit"
                            @change="updateDepositPaid"
                            placeholder="0.00"
                        />
                    </span>
                </div>
            </div>
        </div>
        <hr />
        <div class="row mt-4">
            <div class="col-6">BALANCE DUE</div>
            <div class="col-6 text-right">
                <span class="pr-3">$</span>
                <span class="price">{{ currency(balanceDue) }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import numeral from "numeral"
import { mapActions, mapGetters } from "vuex"

export default {
    computed: {
        ...mapGetters({
            totals: "opportunity/getTotals",
            opportunity: "opportunity/getOpportunity",
            opportunityProducts: "opportunity/getProducts",
        }),
        labelClasses() {
            return "col-md-6 col-lg-8 col-form-label text-right"
        },
        dataClasses() {
            return "col-md-6 col-lg-4"
        },
        balanceDue() {
            let sum = 0.0
            if (this.orderProducts.length === 0) {
                return sum
            }
            sum = this.totals.opportunity_total
            sum -= numeral(this.totals.initial_deposit).value() || 0.0

            return sum
        },
        orderProducts() {
            return this.opportunityProducts.filter(
                (product) => product.use_in_order === "y" && ![null, 0].includes(product.product_id),
            )
        },
    },
    methods: {
        ...mapActions({
            setTotals: "opportunity/fetchTotals",
            updateTotals: "opportunity/updateTotals",
        }),
        updateDepositPaid(event) {
            this.totals.initial_deposit = numeral(event.target.value.toString()).format(",3.00")
            this.updateTotals({
                id: this.opportunity.id,
                totals: { initial_deposit: numeral(this.totals.initial_deposit).value() },
            })
        },
    },
}
</script>

<style scoped></style>