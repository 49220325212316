<template>
  <div class="card my-2">
    <div class="card-header" :id="`heading_${optionKey.toUpperCase()}`">
      <h3
          class=""
          type="button"
          data-toggle="collapse"
          :data-target="`#collapse_${optionKey.toUpperCase()}`"
          aria-expanded="true"
          :aria-controls="`collapse_${optionKey.toUpperCase()}`"
      >
        Option {{ optionKey.toUpperCase() }}
      </h3>
    </div>

    <div :id="`collapse_${optionKey.toUpperCase()}`" class="collapse show" :aria-labelledby="`heading_${optionKey.toUpperCase()}`" data-parent="">
      <div class="card-body shadow">
        <input
            type="text"
            placeholder="DESCRIPTION"
            class="form-control label-description text-center border-dark p-2 mb-4"
            v-model="description"
            @change="updateDescription(`${optionKey.toUpperCase()}_description`)"
        />

        <div class="row m-0" v-if="optionLists.length > 0" v-for="(row, idx) in optionLists" :key="`${optionSelector}_${idx}`">
          <div class="col-6">Item {{itemNumber(idx + 1)}}</div>
          <div class="col-6 text-right">{{ unitName(row.product_id) }}</div>
          <hr />
        </div>

        <div class="row m-0" v-else>
          <div class="col-4"></div>
          <div class="col-8 text-right">No Products Selected...</div>
        </div>
        <hr />

        <div class="py-4 px-3 mt-5">
          <div class="row">
            <div class="col-6">Total:</div>
            <div class="col-6 text-right">
              <span class="pr-2">$</span>
              <span class="price">{{ currency(optionTotal) }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OpportunityCalculator from "@/modules/Opportunity/OpportunityCalculator";
import {mapGetters} from "vuex";

export default {
  props: {
    optionKey: {
      type: String,
      default: null
    },
    description: {
      type: String,
      default: null
    }
  },
  watch: {
    opportunityProducts: {
      handler: function (newValue) {
        //console.log(newValue)
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters({
      opportunity: "opportunity/getOpportunity",
      pricingOption: "opportunity/getPricingOptions",
      opportunityProducts: "opportunity/getProducts",
      areTotalsCalculated: "opportunity/getTotalsCalculated",
      accessories: "accessory/getAccessories",
      products: "product/getProducts",
      taxRates: "franchisee/getTaxRates"
    }),
    optionSelector() {
      return `${this.optionKey.toLowerCase()}_option`;
    },
    optionLists() {
      let option = this.optionSelector;
      return this.opportunityProducts.filter((product) => product[option] === "y" && ![0,"0", "", null].includes(product.product_id) );
    },
    optionTotal() {
      let option = this.optionSelector;
      if (!this.areTotalsCalculated) {
        return 0.0
      }
      if (this.opportunityProducts === undefined) {
        return 0.0
      }

      let optionProducts = this.optionLists

      if (optionProducts.length === 0) {
        return 0
      }

      let tax = this.taxRates.find((taxRate) => taxRate.id === this.opportunity.franchisee_tax_rate_id)
      let subTotal = 0.0
      let materialTotal = 0.0
      let laborTotal = 0.0
      let deliveryTotal = 0.0
      let taxTotal = 0.0
      let taxRate = tax !== undefined ? parseFloat(tax.tax_rate) : 0.0
      let deliveryInDollars = this.pricingOption.delivery_type === "Dollars"

      this.calculator = new OpportunityCalculator(
          this.opportunity,
          this.pricingOption,
          this.products,
          this.accessories,
      )
      optionProducts.forEach((product) => {
        let pSubtotal = this.calculator.fetchFinalPrice(product)
        let pMaterialTotal = this.calculator.calculateMaterialPrice(product)
        let pLaborTotal = this.calculator.calculateLaborPrice(product)
        let pDeliveryTotal = this.calculator.calculateDeliveryPrice(product)
        let pTaxTotal = this.calculator.calculateTaxPrice(product, taxRate, deliveryInDollars)

        subTotal += pSubtotal
        materialTotal += pMaterialTotal
        laborTotal += pLaborTotal
        deliveryTotal += pDeliveryTotal
        taxTotal += pTaxTotal

        //console.log("OptionsBreakdown::getOptionTotals", option, product.product_id, pSubtotal,pMaterialTotal,pLaborTotal,pDeliveryTotal,pTaxTotal);
      })

      if (materialTotal + laborTotal === 0) {
        return 0.0
      }

      if (deliveryInDollars) {
        deliveryTotal = this.pricingOption.cost_delivery
        if (this.pricingOption.taxable_delivery === "y") {
          taxTotal += (deliveryTotal * taxRate) / 100
        }
      }

      //console.log("OptionsBreakdown::getOptionTotals::after", option, materialTotal, laborTotal, deliveryTotal, taxTotal);
      return materialTotal + laborTotal + deliveryTotal + taxTotal
    }
  },
  methods: {
    updateDescription() {

    },
    unitName(productId) {
      let product = this.products.find(row => row.id === productId);
      return product ? product.name : ""
    },
    itemNumber(index) {
      return `1.${index.toString().padStart(2, "0")}`
    },
  }
}
</script>

<style scoped>

</style>