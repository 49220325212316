<template>
    <section class="main">
        <UserDataLoadingModal :opportunity="{ id: opportunityId }" />
        <div class="container-fluid">
            <h2 class="heading text-center">Product Selection</h2>

            <OrderTable v-if="renderOrderTable" :is-loaded="!displayLoadingModal" v-on:saving-row="updateRowSaving" />

            <QuickAction v-if="!isPageLoading" :saving-list="rowsBeingSaved" />
            <!--   OptionsButtons  -->

            <hr class="my-5" />
            <h2 class="heading text-center">Option Selection</h2>

            <OptionsBreakdown v-if="!isPageLoading" />

            <hr class="my-5" />
            <h2 class="heading text-center">Final Selection</h2>

            <div class="row">
                <div class="col flex-grow-1">
                    <ErrorSection v-if="!isPageLoading" />
                    <div class="row mb-5">
                        <div class="col-lg-10 col-xl-7 opportunity-notes-card">
                            <div class="shadow bg-white p-4">
                                <textarea
                                    rows="5"
                                    id="input-wm_notes"
                                    class="form-control"
                                    placeholder="Opportunity Notes"
                                    v-model="opportunityNotes"
                                    @focus="markProductRowIsEditing('opportunity_notes')"
                                    @blur="updateOpportunityNote"
                                />
                            </div>
                        </div>
                    </div>

                    <OpportunityFooter v-if="!isPageLoading" :opportunity="opportunity" />
                </div>
                <div class="col flex-grow-1 flex-md-grow-0 mt-3 mt-sm-0">
                    <OrderTotals v-if="pricingOptionLoaded" />
                </div>
            </div>
            <PostOpportunityButton v-if="!isPageLoading" :opportunity="opportunity" />
            <!--  OpportunityFooter -->
        </div>
    </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex"

import OrderTable from "@/modules/Opportunity/Components/OrderTable.vue"
import OpportunityFooter from "@/modules/Opportunity/components/OpportunityFooter.vue"
import QuickAction from "@/modules/Opportunity/components/partials/QuickAction.vue"
import OptionsBreakdown from "@/modules/Opportunity/components/partials/OptionsBreakdown.vue"
import ErrorSection from "@/modules/Opportunity/components/partials/ErrorSection.vue"
import OptionsButtons from "@/modules/Opportunity/components/partials/OptionsButtons.vue"
import OrderTotals from "@/modules/Opportunity/components/partials/OrderTotals.vue"
import UserDataLoadingModal from "@/components/UserDataLoadingModal.vue"
import PostOpportunityButton from "@/modules/Opportunity/Components/Partials/PostOpportunityButton.vue"

export default {
    components: {
        UserDataLoadingModal,
        OptionsButtons,
        ErrorSection,
        OptionsBreakdown,
        QuickAction,
        OpportunityFooter,
        OrderTable,
        OrderTotals,
        PostOpportunityButton,
    },
    data() {
        return {
            opportunityNotes: "",
            displayLoadingModal: true,
            renderOrderTable: false,
            rowsBeingSaved: [],

            opportunityProducts: [],
            opportunityId: this.$route.params.id,
        }
    },
    computed: {
        ...mapGetters({
            opportunity: "opportunity/getOpportunity",
            opportunityLoaded: "opportunity/getOpportunityLoaded",
            opportunityProductsLoaded: "opportunity/areOpportunityProductsLoaded",
            discountSelectionsLoaded: "opportunity/areDiscountSelectionsLoaded",
            pricingOptionLoaded: "opportunity/arePricingOptionsLoaded",
            productPricesLoaded: "opportunity/areProductPricesLoaded",
        }),
        isPageLoading() {
            return !(
                this.opportunityLoaded &&
                this.pricingOptionLoaded &&
                this.productPricesLoaded &&
                this.opportunityProductsLoaded
            )
        },
        rowIsSaving() {
            return this.rowsBeingSaved.length > 0
        },
    },
    mounted() {
        this.loadAccessories()
        this.loadPackages()
        this.loadProducts()
        this.loadProductTypes()
        this.loadSlopeTypes()
        this.loadFranchiseeTaxRates()
        this.fetchDiscountSelections(false)
    },
    watch: {
        isPageLoading(newValue) {
            if (!newValue) {
                setTimeout(() => {
                    this.displayLoadingModal = false
                    this.renderOrderTable = true
                }, 1000)
                return
            }
            this.displayLoadingModal = newValue
        },
    },
    methods: {
        ...mapActions({
            addRow: "opportunity/addNewProducts",
            loadOpportunity: "opportunity/loadOpportunity",
            loadOptions: "opportunity/loadOptions",
            //updateOpportunity: "opportunity/updateOpportunity",
            updateNote: "opportunity/updateOpportunityNote",
            unsetBeingSaved: "opportunity/unsetBeingSaved",
            setBeingSaved: "opportunity/setBeingSaved",
            markProductRowIsEditing: "opportunity/markProductRowIsEditing",
            unmarkProductRowIsEditing: "opportunity/unmarkProductRowIsEditing",

            // Quick Loads from Local Storage
            loadAccessories: "accessory/loadAccessories",
            loadPackages: "pkg/loadPackages",
            loadProductTypes: "product/loadProductTypes",
            loadProducts: "product/loadProducts",
            loadSlopeTypes: "slopeType/loadSlopeTypes",
            loadFranchiseeTaxRates: "franchisee/loadFranchiseeTaxRates",
            //loadOpportunityProducts: "opportunity/loadOpportunityProducts",
            fetchDiscountSelections: "opportunity/fetchDiscountSelections",
        }),
        updateRowSaving(savingData) {
            if (savingData.isSaving) {
                this.rowsBeingSaved.push(savingData.row)
            } else {
                let idx = this.rowsBeingSaved.indexOf(savingData.row)
                this.rowsBeingSaved.splice(idx, 1)
            }
        },
        updateOpportunityNote() {
            this.setBeingSaved(["opportunity_notes"])
            this.unmarkProductRowIsEditing("opportunity_notes")
            this.updateNote({
                id: this.opportunity.id,
                note: this.opportunityNotes,
            })
                .then(() => this.unsetBeingSaved(["calculate_totals"]))
                .catch((error) => {
                    this.unsetBeingSaved(["calculate_totals"])
                    alert("There was an issue attempting to calculate totals.")
                })
        },
        loadingMessage(name, isLoading) {
            let loadingIcon = `<i class="loading-icons fas fa-spinner fa-spin text-info fa-lg" style="margin-right: 35px;"></i>`
            let loadedIcon = `<i class="loading-icons far fa-check-circle text-success  fa-lg" style="margin-right: 35px;"></i>`

            let loadingMessage = `Loading ${name}...`
            let loadedMessage = `${name} are loaded...`
            return isLoading ? `${loadingIcon} ${loadingMessage}` : `${loadedIcon} ${loadedMessage}`
        },
    },
}
</script>

<style>
.main {
    padding-bottom: 100px;
}
</style>
