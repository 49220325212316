export default {
    emits: ["online", "offline"],
    data() {
        return {
            isOnline: false,
            //isOnline: true,
            isOffline: false,
        }
    },
    created() {
        if (typeof window !== "undefined") {
            navigator.onLine ? (this.isOnline = true) : (this.isOffline = true)

            window.addEventListener("online", this.onlineHandler)
            window.addEventListener("offline", this.offlineHandler)
        }
    },

    beforeUnmount() {
        window.removeEventListener("online", this.onlineHandler)
        window.removeEventListener("offline", this.offlineHandler)
    },

    methods: {
        onlineHandler() {
            console.info("online")
            this.$emit("online")
            this.isOnline = true
            this.isOffline = false
        },
        offlineHandler() {
            console.info("offline")
            this.$emit("offline")
            this.isOffline = true
            this.isOnline = false
        },
    },
}
