<template>
    <div>
       <div class="row">
                <div class="col text-right pb-4">
                    <button class="btn btn-success" @click="getSyncData()">Get Sync Data</button>
                </div>
        </div>
    </div>
</template>

<script>
//import { mapActions, mapGetters } from "vuex"
import getSyncDataMiddleware from "@/middleware/getSyncDataMiddleware.js"
import Localbase from "localbase"
//opportunity/sync
export default {
    name: "Get Sync Data",
    //components: { Panel },
    /* props: {
        appointment: {
            type: Object,
            required: true,
        },
    }, */
    computed: {
       
    },
    methods: {
        
        getSyncData (){
            const localbase = new Localbase("wishmaker")
            getSyncDataMiddleware.getAllSyncData(this.isOnline).then(async ({ data }) => {

                console.log("Api called");

                let opportunity = [];
                let opportunity_financing_option = [];
                let opportunity_pricing_option = [];
                let opportunity_product = [];

                /* opportunity =  await db.collection("opportunity").get()
                opportunity_financing_option =  await db.collection("opportunity_financing_option").get()
                opportunity_pricing_option =  await db.collection("opportunity_pricing_option").get()
                opportunity_product =  await db.collection("opportunity_product").get()
 */
                //db.collection('users').doc({ id: 1 }).delete()
               // db.collection('users').doc({ id: 1 }).delete()


                  await localbase.collection("opportunity").set(data.opportunity)
                  await localbase.collection("opportunity_financing_option").set(data.opportunity_financing_option)
                  await localbase.collection("opportunity_pricing_option").set(data.opportunity_pricing_option)
                  await localbase.collection("opportunity_product").set(data.opportunity_product)
               
                /* console.log("opportunity",data.opportunity);
                console.log("opportunity_financing_option",data.opportunity_financing_option);
                console.log("opportunity_pricing_option",data.opportunity_pricing_option);
                console.log("opportunity_product",data.opportunity_product); */

               /*  let opportunity = [];
                let opportunity_financing_option = [];
                let opportunity_pricing_option = [];
                let opportunity_product = []; */

            })
            .catch(async (e) => {
                //alert(e);
            });
        }
    }
}
</script>

<style scoped></style>
