<template>
    <div class="modal fade" :id="uid" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog" :class="{ 'modal-dialog-centered': centered }">
            <div class="modal-content">
                <div class="modal-header" v-if="headerText.length > 0">
                    <h5 class="modal-title" id="staticBackdropLabel" v-text="headerText" />
                    <button type="button" class="close" @click="$emit('close')" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <slot />
                </div>
                <div class="modal-footer" v-if="includeFooter">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="$emit('close')">
                        Close
                    </button>
                    <button type="button" class="btn btn-primary">Understood</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        headerText: {
            type: String,
            default: "",
        },
        staticBackdrop: {
            type: Boolean,
            default: false,
        },
        centered: {
            type: Boolean,
            default: false,
        },
        show: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            includeFooter: false,
        }
    },
    computed: {
        uid() {
            return `modal_${this._.uid}`
        },
    },
    mounted() {
        let options = {
            backdrop: this.staticBackdrop ? "static" : true,
            keyboard: true,
            focus: false,
            show: this.show,
        }
        $(`#${this.uid}`).modal(options)
    },
    watch: {
        show(newValue) {
            $(`#${this.uid}`).modal(newValue ? "show" : "hide")

            // Handle the case where the modal is still showing/hiding when this initially runs
            setTimeout(() => {
                $(`#${this.uid}`).modal(newValue ? "show" : "hide")
            }, 1000)
        },
    },
}
</script>
