<template>
    <div class="order-total-card">
        <div class="card-header">
            <h3 class="card-title">Order Total</h3>
        </div>
        <div class="card-body shadow">
            <div class="row">
                <div class="col-6">MATERIALS</div>
                <div class="col-6 text-right">
                    <span class="pr-3">$</span>
                    <span class="price">{{ currency(currentTotals.material_total) }}</span>
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col-6">{{ laborLabel }}</div>
                <div class="col-6 text-right">
                    <span class="pr-3">$</span>
                    <span class="price">{{ currency(currentTotals.labor_total) }}</span>
                </div>
            </div>
            <hr />
            <div class="row align-items-center">
                <div class="col-6">DELIVERY</div>
                <div class="col-6 text-right">
                    <div class="d-inline-flex justify-content-end border-dark text-right p-1">
                        <span class="pr-3">$</span>
                        <span class="price">
                            <input
                                type="text"
                                class="form-control"
                                placeholder="0.00"
                                v-model="currentTotals.delivery_total"
                                @blur="updateDeliveryTotal"
                            />
                        </span>
                    </div>
                </div>
            </div>
            <hr />
            <div v-if="showDiscount">
                <div class="row">
                    <div class="col-6">Discount</div>
                    <div class="col-6 text-right">
                        <span class="pr-3">$</span>
                        <span class="price">{{ discountValue }}</span>
                    </div>
                </div>
                <hr />
            </div>
            <div class="row">
                <div class="col-6">TAX</div>
                <div class="col-6 text-right">
                    <span class="pr-3">$</span>
                    <span class="price">{{ currency(currentTotals.tax_total) }}</span>
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col-6">TOTAL</div>
                <div class="col-6 text-right">
                    <span class="pr-3">$</span>
                    <span class="price">{{ currency(totalDue) }}</span>
                </div>
            </div>

            <component :is="depositType" />
        </div>
    </div>
</template>

<script>
import numeral from "numeral"
import { mapActions, mapGetters } from "vuex"
import OpportunityCalculator from "@/modules/Opportunity/OpportunityCalculator"
import TotalBreakdown from "@/modules/Opportunity/Components/Partials/TotalBreakdown.vue"
import CaliforniaDeposit from "@/modules/Opportunity/Components/Deposit/CaliforniaDeposit.vue"
import MarylandDeposit from "@/modules/Opportunity/Components/Deposit/MarylandDeposit.vue"
import DefaultDeposit from "@/modules/Opportunity/Components/Deposit/DefaultDeposit.vue"

export default {
    components: {
        TotalBreakdown,
        DefaultDeposit,
        MarylandDeposit,
        CaliforniaDeposit,
    },
    data() {
        return {
            deliveryHasBeenEdited: false,
            currentTotals: {
                material_total: null,
                labor_total: null,
                delivery_total: null,
                discount_total: null,
                tax_total: null,
            },
        }
    },
    computed: {
        ...mapGetters({
            totals: "opportunity/getTotals",
            opportunity: "opportunity/getOpportunity",
            opportunityProducts: "opportunity/getProducts",
            products: "product/getProducts",
            accessories: "accessory/getAccessories",
            taxRates: "franchisee/getTaxRates",
            pricingOption: "opportunity/getPricingOptions",
            isSaving: "opportunity/isSaving",
        }),
        discountValue() {
            let discountValue = numeral(this.currentTotals.discount_total).format(",3.00")
            return `( ${discountValue} )`
        },
        depositType() {
            if (!this.opportunity) {
                return "DefaultDeposit"
            }

            switch (this.opportunity.state.abbreviation) {
                case "MD":
                    return "MarylandDeposit"
                case "CA":
                    return "CaliforniaDeposit"
                default:
                    return "DefaultDeposit"
            }
        },
        laborLabel() {
            if (!this.opportunity) {
                return "Labor"
            }

            return this.opportunity.customer.country_id === 2 ? `Labour` : `Labor`
        },
        deliveryEdited() {
            return this.pricingOption.delivery_type === "Dollars" || this.deliveryHasBeenEdited
        },
        labelClasses() {
            return "col-md-6 col-lg-8 col-form-label text-right"
        },
        dataClasses() {
            return "col-md-6 col-lg-4"
        },
        totalDue() {
            let sum = 0.0

            if (this.orderProducts.length === 0) {
                return sum
            }

            return numeral(this.currentTotals.opportunity_total).format(",3.00")
        },
        orderProducts() {
            return this.opportunityProducts.filter(
                (product) => product.use_in_order === "y" && ![null, 0].includes(product.product_id),
            )
        },
        showDiscount() {
            return this.pricingOption?.discount_id > 0 ?? false
        },
    },
    watch: {
        opportunity(newVal) {
            this.resetOpportunityCalculator()
        },
        pricingOption() {
            this.resetOpportunityCalculator()
        },
        products(newValue) {
            this.resetOpportunityCalculator()
        },
        totals(newValue) {
            this.currentTotals = Object.assign({}, newValue)
            this.currentTotals.delivery_total = numeral(newValue.delivery_total).format(",3.00")
        },
    },
    created() {
        this.resetOpportunityCalculator()
        this.currentTotals = this.totals
    },
    methods: {
        ...mapActions({
            setTotals: "opportunity/fetchTotals",
            updateTotals: "opportunity/updateTotals",
            updateDelivery: "opportunity/updateDelivery",
            updateOpportunity: "opportunity/updateOpportunity",
            setBeingSaved: "opportunity/setBeingSaved",
            unsetBeingSaved: "opportunity/unsetBeingSaved",
        }),
        updateDeliveryTotal(event) {
            this.deliveryHasBeenEdited = true
            this.setBeingSaved(["delivery_totals"])
            let newDeliveryTotal = numeral(event.target.value.toString()).format(",3.00")
            let currentDeliveryTotal = numeral(this.totals.delivery_total).format(",3.00")

            if (newDeliveryTotal === currentDeliveryTotal) {
                this.unsetBeingSaved(["delivery_totals"])
                return
            }

            this.currentTotals.delivery_total = newDeliveryTotal
            this.updateDelivery({
                id: this.opportunity.id,
                payload: {
                    delivery_type: "Dollars",
                    cost_delivery: newDeliveryTotal,
                },
            }).then(() => {
                this.unsetBeingSaved(["delivery_totals"])
            })
        },
        updateDepositPaid(event) {
            this.setBeingSaved(["deposit_totals"])
            this.totals.initial_deposit = numeral(event.target.value.toString()).format(",3.00")
            this.updateTotals({
                id: this.opportunity.id,
                totals: {
                    initial_deposit: numeral(this.totals.initial_deposit).value(),
                },
            }).then(() => {
                this.unsetBeingSaved(["deposit_totals"])
            })
        },
        calculateTotals() {
            this.calculator = new OpportunityCalculator(
                this.opportunity,
                this.pricingOption,
                this.products,
                this.accessories,
            )
            let optionProducts = this.orderProducts

            if (optionProducts.length === 0) {
                return
            }

            let tax = this.taxRates.find((taxRate) => taxRate.id === this.opportunity.franchisee_tax_rate_id)
            let subTotal = 0.0
            let material_total = 0.0
            let labor_total = 0.0
            let delivery_total = 0.0
            let tax_total = 0.0
            let taxRate = tax !== undefined ? parseFloat(tax.tax_rate) : 0.0
            let deliveryInDollars = this.pricingOption.delivery_type === "Dollars"

            let discountableLaborTotal = 0.0
            optionProducts.forEach((item) => {
                let product = this.products.find((product) => product.id === item.product_id)
                let pSubtotal = this.calculator.fetchFinalPrice(item)
                let pMaterialTotal = this.calculator.calculateMaterialPrice(item)
                let pLaborTotal = this.calculator.calculateLaborPrice(item)
                let pDeliveryTotal = this.calculator.calculateDeliveryPrice(item)
                let pTaxTotal = this.calculator.calculateTaxPrice(item, taxRate, deliveryInDollars)

                if (this.pricingOption && product) {
                    if (
                        this.pricingOption.exclude_labor_only_products_from_discount === "y" ||
                        (product.product_type.name !== "SRR" && product.count_as_labor_only === "n") ||
                        (product.product_type.name === "SRR" && this.pricingOption.stile_id !== 1)
                    ) {
                        discountableLaborTotal += pLaborTotal
                    }
                }

                subTotal += pSubtotal
                material_total += pMaterialTotal
                labor_total += pLaborTotal
                delivery_total += pDeliveryTotal
                tax_total += pTaxTotal
            })

            let discount_total = this.calculator.calculateDiscount(material_total, discountableLaborTotal)

            discount_total = parseFloat(discount_total.toFixed(2))
            tax_total -= discount_total * (taxRate / 100)

            if (deliveryInDollars) {
                delivery_total = this.pricingOption.taxable_delivery === "y" ? this.pricingOption.cost_delivery : 0
                tax_total += (delivery_total * taxRate) / 100
            }

            delivery_total = parseFloat(delivery_total)
            delivery_total = parseFloat(delivery_total.toFixed(2))
        },
        resetOpportunityCalculator() {
            if (this.opportunity) {
                this.calculator = new OpportunityCalculator(
                    this.opportunity,
                    this.pricingOption,
                    this.products,
                    this.accessories,
                )
            }
        },
    },
}
</script>

<style>
.opportunity-order-totals.form-group {
    margin-bottom: 0;
}

.opportunity-order-totals.form-group input {
    text-align: right;
}
</style>