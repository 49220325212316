<template>
<!--    <vue-select
        :options="options"
        :disabled="disabled"
        v-model="selectedValue"
        searchable/>
    -->
    <select
        class="form-control form-control-sm"
        ref="select-field"
        :disabled="disabled"
        v-model="selectedValue"
        @click="evt($event)"
        @focus="evt($event)"
        @blur="evt($event)"
        @change="evt($event)"
    >
        <option v-if="!disableNullOption" :value="nullValue" v-html="placeholder"/>
        <option v-for="(option, idx) in options" :key="idx" :value="option.value" v-html="option.label"/>
    </select>
</template>

<script>
import 'vue-select/dist/vue-select.css';

import VueSelect from 'vue-select';

export default {
    components: {VueSelect},
    props: {
        modelValue: {
            type: [String,Number],
            default: null,
        },
        options: {
            type: Array,
            default: () => ([])
        },
        placeholder: {
            type: String,
            default: 'Select'
        },
        nullValue: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        disableNullOption: {
            type: Boolean,
            default: false
        }
    },
    emits: ['update:modelValue'],
    created() {
        this.selectedValue = this.modelValue;
    },
    computed: {
        selectedOption () {
            return this.options.find(row => row.value === this.modelValue)
        },
        selectedValue: {
            get() {
                return this.modelValue
            },
            set(newValue) {
                this.$emit('update:modelValue', newValue);
            }
        }
    },
    methods: {
        evt(event) {
          console.log(`triggered dropdown ${event}`);
        }
    }
}
</script>


