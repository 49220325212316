const prefix = "opportunities"

export default {
    addRows: (id, quantity) => axios.post(`/${prefix}/${id}/addRows`, quantity),
    update: (id, opportunity) => axios.patch(`/${prefix}/${id}`, opportunity),
    updateNote: (id, note) => axios.patch(`/${prefix}/${id}/updateNote`, { wm_note: note }),

    fetchPricingOption: (id) => axios.get(`/${prefix}/${id}/setPricingOption`),
    updatePricingOption: (id, pricingOption) => axios.patch(`/${prefix}/${id}/pricingOption`, pricingOption),
    fetchFinancingOption: (id) => axios.get(`/${prefix}/${id}/setFinancingOptions`),
    fetchProductPrices: (id) => axios.get(`/${prefix}/${id}/setProductPrices`),

    fetchTotals: (id) => axios.get(`/${prefix}/${id}/totals`),
    updateTotals: (id, totals) => axios.patch(`/${prefix}/${id}/totals`, totals),
    calculateTotals: (id) => axios.get(`/${prefix}/${id}/totals/calculate`),

    fetchOpportunity: (id) => axios.get(`/${prefix}/${id}`),
    fetchRecentOpportunities: () => axios.get(`/${prefix}/recent`),
    fetchRecentOpportunitiesProducts: () => axios.get(`/${prefix}/products/recent`),
    fetchRecentOpportunitiesFinancingOptions: () => axios.get(`/${prefix}/financing_options/recent`),
    fetchRecentOpportunitiesPricingOptions: () => axios.get(`/${prefix}/pricing_options/recent`),

    fetchProducts: (id) => axios.get(`/${prefix}/${id}/products`),
    updateProduct: (id, product) => {
        return axios.patch(`/${prefix}/${id}/products`, product)
    },
    deleteProduct: (id, { product }) => {
        console.log("deleteProduct", id, product, product.id)
        return axios.delete(`/${prefix}/${id}/products/${product.id}`, product)
    },
    fetchDiscountSelections: () => axios.get(`/${prefix}/discount_selections`),
    fetchCalculationDetails: (id, product) => axios.get(`/${prefix}/${id}/products/${product.id}/pricing_details`),
}
