<template>
  <div class="row alert d-flex justify-content-end py-0 my-0 " :class="{
            'alert-info text-info': !closeToQuote,
            'alert-danger text-danger': closeToQuote,
        }">
    Local Storage: {{ this.usedStorage }} MB of {{this.availableStorage}} MB used.
  </div>
</template>

<script>
import numeral from "numeral"

export default {
  name: "LocalStorageStats",
  data() {
    return {
      localStorageUsage: 0,
      localStorageAvailable: 0
    }
  },
  computed: {
    closeToQuote() {
      return this.localStorageUsage >= this.localStorageAvailable * 0.75
    },
    usedStorage() {
      return numeral(this.localStorageUsage).format(",3.00");
    },
    availableStorage() {
      return numeral(this.localStorageAvailable).format(",3.00");
    }
  },
  created() {
    this.updateLocalStorageData();
    window.addEventListener('storage', () => {
      this.updateLocalStorageData();
    })
  },
  methods: {
    updateLocalStorageData() {
      navigator.storage.estimate()
        .then( results => {
          this.localStorageAvailable = results.quota / 1000/1000
          this.localStorageUsage = results.usage / 1000/1000
        })
    }
  }
}
</script>

<style scoped>

</style>