<template>
    <header class="header">
        <div class="row align-items-center">
            <div class="col-sm-5 col-lg-4">
                <div
                    class="
                        d-flex
                        align-items-center
                        justify-content-center justify-content-md-start
                    "
                >
                    <div class="logo">
                        <a href="/">
                            <img src="/logo.svg" alt="ShelfGenie::WishMaker" />
                        </a>
                    </div>
                    <div class="header-nav">
                        <RouterLink to="/dashboard" active-class="active">
                            Dashboard
                        </RouterLink>
                    </div>
                </div>
            </div>
            <div class="col-sm-4 col-lg-4 text-center">
                <div class="header-tagline">WishMaker</div>
            </div>
            <div class="col-sm-3 col-lg-4 text-center text-md-right">
                <div class="header-dropdown">
                    <div class="dropdown">
                        <button
                            class="btn dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-expanded="false"
                        >
                            {{ user.name }}
                        </button>
                        <div
                            class="dropdown-menu"
                            aria-labelledby="dropdownMenuButton"
                        >
                            <a
                                class="dropdown-item"
                                href="javascript:void(0);"
                                v-on:click="logOut()"
                                >Logout</a
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
export default {
    computed: {
        user() {
            return this.$store.state.authentication.user
        },
    },
    methods: {
        logOut() {
            localStorage.removeItem("user")
            this.$router.replace({ name: "LoginPage" })
        },
    },
}
</script>

<style></style>
