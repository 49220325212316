<template>
    <div class="container-fluid d-flex flex-column p-0">
        <NetworkIndicator />
        <Header v-if="user && user.token" />
        <RouterView class="content-section" :key="[currentTime]" />
        <Footer v-if="user && user.token" />
    </div>
</template>

<script>
import moment from "moment-timezone"
import Header from "@/components/common/Header.vue"
import Footer from "@/components/common/Footer.vue"
import NetworkIndicator from "@/components/NetworkIndicator.vue"

export default {
    components: {
        NetworkIndicator,
        Header,
        Footer,
    },
    computed: {
        user() {
            return this.$store.state.authentication.user
        },

        currentTime() {
            return this.$route.fullPath + "&" + moment().format("YYYYMMDDhhmmss")
        },
    },
}
</script>

<style>
  .content-section {
    padding-bottom: 50px;
  }
</style>