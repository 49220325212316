import "@/boot"

import { createApp } from "vue"
import App from "@/App.vue"

import "bootstrap/dist/css/bootstrap.min.css"
import "@/assets/css/custom.css"
import "bootstrap"

import store from "@/store"
import router from "@/router"
import networkIndicator from "@/mixins/networkIndicator"
import currency from "@/mixins/currency"
import dateformat from "@/mixins/dateformat"

/**
 * https://vite-plugin-pwa.netlify.app/guide/auto-update.html#runtime
 * Register the serviceworker
 * TODO stuff when the offline mode is ready
 */
import { registerSW } from "virtual:pwa-register"

const updateSW = registerSW({
    onOfflineReady() {},
})

createApp(App)
    .use(router)
    .use(store)
    .mixin(networkIndicator)
    .mixin(currency)
    .mixin(dateformat)
    .directive("tooltip-raw", function (el, binding) {
        const options = {
            title: binding.value.content || binding.value.title,
            placement: binding.arg,
            //trigger: binding.value.show ? 'hover' : 'manual'
            trigger: "hover",
        }

        $(el).tooltip("dispose")
        $(el).tooltip(options)
        $(el).tooltip(binding.value.show ? "enable" : "disable")
        $(el).attr("data-original-title", binding.value.content || binding.value.title)
    })
    .directive("tooltip", function (el, binding) {
        $(el).tooltip({
            title: binding.value,
            placement: binding.arg,
            trigger: "hover",
        })
    })
    .mount("#app")
