<template>
    <div>
        <div class="option-accordion">
            <div class="accordion" id="accordionExample">
                <option-card option-key="A" />
                <option-card option-key="B" />
                <option-card option-key="C" />
                <option-card option-key="D" />
            </div>
        </div>
        <div class="row my-4 justify-content-center">
            <div class="col flex-grow-1 flex-sm-grow-0">
                <div class="update-item my-2">
                    <span class="label">Tax</span>
                    <select class="form-control w-auto" v-model="franchisee_tax_rate_id">
                        <option :value="null"></option>
                        <option v-for="(taxRate, idx) in taxRates" :key="idx" :value="taxRate.id">
                            {{ taxRate.tax_rate_name }} ({{ displayTaxRate(taxRate.tax_rate) }})
                        </option>
                    </select>
                </div>
            </div>
            <div class="col flex-grow-1 flex-sm-grow-0">
              <div class="update-item my-2 input-group-prepend">
                <select-field
                    v-model="localPricingOption.discount_id"
                    :class-value="'form-control text-blue'"
                    :options="discountSelectOptions"
                    disable-null-option
                    @input="selectUpdateTrigger('discount_type')"
                />
                <input
                    type="text"
                    class="form-control text-right mx-3 discount-input-field"
                    :class="{ 'd-none': internalUseOnly }"
                    v-model="localPricingOption.discount_value"
                    @focus="fieldFocus('discount_value')"
                    @blur="fieldUnfocus('discount_value')"
                />
                <select-field
                    :class="{ 'd-none': internalUseOnly }"
                    v-model="localPricingOption.discount_type"
                    :options="discountTypes"
                    disable-null-option
                    @input="selectUpdateTrigger('discount_type')"
                />
              </div>
            </div>
        </div>
    </div>
</template>

<script>
import numeral from "numeral"
import { mapActions, mapGetters } from "vuex"
import SelectField from "@/components/base/forms/SelectField.vue"
import OpportunityCalculator from "@/modules/Opportunity/OpportunityCalculator"
import OptionCard from "@/modules/Opportunity/Components/Partials/OptionCard.vue"

export default {
    components: { OptionCard, SelectField },
    data() {
        return {
            franchisee_tax_rate_id: 0,
            focusedElements: new Set(),
            localPricingOption: {
                discount_id: 0,
                discount_value: 0,
                discount_type: "Dollars",
            },
            a_description: "",
            b_description: "",
            c_description: "",
            d_description: "",

            optionATotals: this.getOptionTotals("a_option"),
            optionBTotals: this.getOptionTotals("b_option"),
            optionCTotals: this.getOptionTotals("c_option"),
            optionDTotals: this.getOptionTotals("d_option"),
        }
    },
    computed: {
        ...mapGetters({
            opportunity: "opportunity/getOpportunity",
            totals: "opportunity/getTotals",
            options: "opportunity/getOptions",
            opportunityProducts: "opportunity/getProducts",
            products: "product/getProducts",
            accessories: "accessory/getAccessories",
            taxRates: "franchisee/getTaxRates",
            pricingOption: "opportunity/getPricingOptions",
            discountTypes: "opportunity/getDiscountTypes",
            discountOptions: "opportunity/getDiscountOptions",
            areTotalsCalculated: "opportunity/getTotalsCalculated",
        }),

        discountSelectOptions() {
            return [{ value: 0, label: "Internal Use Only" }, ...this.discountOptions]
        },
        internalUseOnly() {
            return !!(this.pricingOption && this.pricingOption.discount_id === 0)
        },
    },
    watch: {
        pricingOption() {
            if (this.pricingOption !== null) {
                this.localPricingOption = this.pricingOption
            }
            this.calculator = new OpportunityCalculator(
                this.opportunity,
                this.pricingOption,
                this.products,
                this.accessories,
            )
        },
        franchisee_tax_rate_id() {
            this.updateOpportunity(
                {
                    id: this.opportunity.id,
                    opportunity: {
                        franchisee_tax_rate_id: this.franchisee_tax_rate_id,
                    },
                    isOnline: this.isOnline,
                },

            )
        },
        totals() {
            this.calculateTotals()
        },
        opportunityProducts() {
            this.calculateTotals()
        },
        options() {},
        focusedElements(newValue) {
            if (newValue.size > 0) {
                return
            }
            let id = this.opportunity.id
            if (newValue.discount_type === "Percent") {
                newValue.discount_value = newValue.discount_value < 100 ? newValue.discount_value : 100
            }
            this.updateOption(
                {
                    opportunity_id: id,
                    pricingOption: { ...this.localPricingOption },
                    isOnline: this.isOnline
                }
            ).then(() => {
                this.fetchTotals(this.opportunity.id, this.isOnline)
            })
        },
        taxRates() {
            this.checkOpportunityTaxRate()
            this.calculateTotals()
        },

        products(newValue) {
            this.calculator = new OpportunityCalculator(
                this.opportunity,
                this.pricingOption,
                newValue,
                this.accessories,
            )
            this.calculateTotals()
        },
    },
    created() {
        this.a_description = this.options.a_description
        this.b_description = this.options.b_description
        this.c_description = this.options.c_description
        this.d_description = this.options.d_description

        this.checkOpportunityTaxRate()
        this.calculateTotals()
        if (this.pricingOption !== null) {
            this.localPricingOption = this.pricingOption
        }
    },
    methods: {
        ...mapActions({
            loadOpportunity: "opportunity/loadOpportunity",
            updateOpportunity: "opportunity/updateOpportunity",
            fetchPricingOption: "opportunity/fetchPricingOption",
            updateOption: "opportunity/updatePricingOption",
            fetchTotals: "opportunity/fetchTotals",
        }),
        displayTaxRate(value) {
            let taxNumeric = numeral(value).format(",3.0000")
            return `${taxNumeric}%`
        },
        calculateTotals() {
            this.calculator = new OpportunityCalculator(
                this.opportunity,
                this.pricingOption,
                this.products,
                this.accessories,
            )
            this.optionATotals = this.getOptionTotals("a_option")
            this.optionBTotals = this.getOptionTotals("b_option")
            this.optionCTotals = this.getOptionTotals("c_option")
            this.optionDTotals = this.getOptionTotals("d_option")
        },
        updateDescription(key) {
          /**
            this.updateOpportunity({
                id: this.opportunity.id,
                opportunity: { [key]: this.$data[key] },
            })
           **/
        },
        checkOpportunityTaxRate() {
            if (this.opportunity.franchisee_tax_rate_id === null) {
                let defaultTaxRate = this.taxRates.find((rate) => rate.is_default === "y")
                if (defaultTaxRate !== undefined) {
                    const opportunity = this.opportunity
                    opportunity.franchisee_tax_rate_id = defaultTaxRate.id || null
                    this.franchisee_tax_rate_id = defaultTaxRate.id || null
                    let id = this.opportunity.id
                    //this.updateOpportunity({ id, opportunity }, this.isOnline)
                }
            } else {
                this.franchisee_tax_rate_id = this.opportunity.franchisee_tax_rate_id
            }
        },
        getOptionTotals(option) {
            if (!this.areTotalsCalculated) {
                return 0.0
            }
            if (this.opportunityProducts === undefined) {
                return 0.0
            }

            let optionProducts = this.opportunityProducts.filter((product) => product[option] === "y")
            if (optionProducts.length === 0) {
                return 0
            }
            let tax = this.taxRates.find((taxRate) => taxRate.id === this.opportunity.franchisee_tax_rate_id)
            let subTotal = 0.0
            let materialTotal = 0.0
            let laborTotal = 0.0
            let deliveryTotal = 0.0
            let taxTotal = 0.0
            let taxRate = tax !== undefined ? parseFloat(tax.tax_rate) : 0.0
            let deliveryInDollars = this.pricingOption.delivery_type === "Dollars"

            this.calculator = new OpportunityCalculator(
                this.opportunity,
                this.localPricingOption,
                this.products,
                this.accessories,
            )
            optionProducts.forEach((product) => {
                let pSubtotal = this.calculator.fetchFinalPrice(product)
                let pMaterialTotal = this.calculator.calculateMaterialPrice(product)
                let pLaborTotal = this.calculator.calculateLaborPrice(product)
                let pDeliveryTotal = this.calculator.calculateDeliveryPrice(product)
                let pTaxTotal = this.calculator.calculateTaxPrice(product, taxRate, deliveryInDollars)

                subTotal += pSubtotal
                materialTotal += pMaterialTotal
                laborTotal += pLaborTotal
                deliveryTotal += pDeliveryTotal
                taxTotal += pTaxTotal

                //console.log("OptionsBreakdown::getOptionTotals", option, product.product_id, pSubtotal,pMaterialTotal,pLaborTotal,pDeliveryTotal,pTaxTotal);
            })

            if (materialTotal + laborTotal === 0) {
                return 0.0
            }

            if (deliveryInDollars) {
                deliveryTotal = this.pricingOption.cost_delivery
                if (this.pricingOption.taxable_delivery === "y") {
                    taxTotal += (deliveryTotal * taxRate) / 100
                }
            }

            //console.log("OptionsBreakdown::getOptionTotals::after", option, materialTotal, laborTotal, deliveryTotal, taxTotal);
            return materialTotal + laborTotal + deliveryTotal + taxTotal
        },
        fieldFocus(fieldName) {
            const currentState = this.focusedElements
            currentState.add(fieldName)
            this.focusedElements = new Set(currentState)
        },
        fieldUnfocus(fieldName) {
            setTimeout(() => {
                this.focusedElements.delete(fieldName)
                this.focusedElements = new Set(this.focusedElements.values())
            }, 500)
        },
        selectUpdateTrigger(fieldName) {
            this.fieldFocus(fieldName)
            this.fieldUnfocus(fieldName)
        },
    },
}
</script>

<style scoped>
.options-breakdown div {
    margin-left: auto;
    margin-right: auto;
}

.option-labels {
    padding-right: 25px;
    clear: both;
    float: left;
}

.option-prices-field {
    width: 300px;
    margin-top: 15px;
}
.discount-input-field {
  border-bottom: solid 1px #343a40;
  border-radius: 0px;
}
</style>