<template>
    <div class="card">
        <div v-if="header.length > 0" class="card-header" v-html="header"/>
        <div class="card-body">
            <slot/>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        header: {
            type: String,
            default: "",
        }
    }
}
</script>
