<template>
    <section class="main">
        <div v-if="!isLoading" class="container-fluid">
            <div class="row">
                <div class="col-lg-6 mb-4 mb-lg-0">
                    <CustomerDetailWidget class="widget" :customer="appointment.customer" />
                </div>
                <div class="col-lg-6">
                    <AppointmentDetailWidget class="widget" :appointment="appointment" />
                </div>
            </div>

            <div class="row">
                <div class="col text-right pt-5">
                    <RouterLink class="btn btn-success" :to="`/opportunities/${appointment.opportunity.id}/edit`">
                        Edit Order
                    </RouterLink>
                </div>
            </div>
        </div>
        <PageLoading v-else />
    </section>
</template>

<script>
import CustomerDetailWidget from "@/components/customers/DetailWidget.vue"
import AppointmentDetailWidget from "@/components/appointments/DetailWidget.vue"
import PageLoading from "@/components/PageLoading.vue"
import { mapActions, mapGetters } from "vuex"

export default {
    components: { PageLoading, CustomerDetailWidget, AppointmentDetailWidget },
    data() {
        return {
            isLoading: true,
            userSession: JSON.parse(localStorage.getItem("user")),
            id: this.$route.params.id,
        }
    },
    computed: {
        ...mapGetters({
            appointment: "appointment/getAppointment",
        }),
    },
    async mounted() {
        this.fetchAppointment({ id: this.id, isOnline: this.isOnline }).then((response) => (this.isLoading = false))
    },
    methods: {
        ...mapActions({
            fetchAppointment: "appointment/fetchAppointment",
        }),
    },
}
</script>