import Db from "@/middleware/db"

const wishDB = new Db(import.meta.env.VITE_INDEXDB_DEXIE_DB, import.meta.env.VITE_INDEXDB_DEXIE_VERSION)

/**
 * Check if the provided table name uses composite keys
 * If it does, return the name of the key
 *
 * @param {string} name
 * @returns {null|string}
 */
function getCompositeParentKey(name) {
    // These tables have composite keys, we need to add additional constraints
    const compositeParentKeys = {
        opportunity_product: "opportunity_id",
        opportunity_pricing_option: "opportunity_id",
        opportunity_financing_option: "opportunity_id",
        franchisee_pricing_option: "franchisee_id",
        franchisee_financing_option: "franchisee_id",
        franchisee_product_price: "franchisee_id",
    }

    return compositeParentKeys[name] !== undefined ? compositeParentKeys[name] : null
}

/**
 * If the app is online, retrieve data from the provided promise and update the indexedDB
 * Otherwise, retrieve and return the already synced data
 *
 * @param {Promise} handler
 * @param {string} action
 * @param {Object} keys
 * @param {Object} data
 * @param {Boolean} reset
 * @param {string} name
 * @param {boolean} isOnline
 * @returns {Promise<Array<any>|*>}
 */
export default async function ({ handler, offlineHandler, action, keys = {}, data = {}, reset = true }, name, isOnline) {
    /** Offline Mode **/
    if (isOnline === false) {
        if (action.toLowerCase() === "get") {
            return wishDB.get(name, keys, getCompositeParentKey(name) !== null)
        }

        return callHandler(offlineHandler, keys, data)
    }

    return await callHandler(handler, keys, data).then(({ data }) => {
        return wishDB.post(name, data[name])
    })
}

function callHandler(handler, keys, data)
{
    switch (Object.keys(keys).length) {
        case 2:
            return handler(keys.id, keys[getCompositeParentKey(name)], data)
        case 1:
            let keysList = Object.keys(keys)
            return handler(keys[keysList[0]], data)
        default:
            return handler()
    }
}