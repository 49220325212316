<template>
    <div>
        <h2 class="heading text-center">Appointment Information</h2>
        <div class="info-card shadow">
            <div class="row">
                <div class="col-sm-5">
                    <span class="card-label">ID</span>
                </div>
                <div class="col-sm-7 text-sm-right">
                    <span id="appointment_id" v-text="appointment.id" class="card-text"></span>
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col-sm-5">
                    <span class="card-label">Type</span>
                </div>
                <div class="col-sm-7 text-sm-right">
                    <span id="appointment_type" v-text="appointmentType" class="card-text"></span>
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col-sm-5">
                    <span class="card-label">Status</span>
                </div>
                <div class="col-sm-7 text-sm-right">
                    <span id="appointment_progress" v-text="appointment.progress" class="card-text"></span>
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col-sm-5">
                    <span class="card-label">Designer</span>
                </div>
                <div class="col-sm-7 text-sm-right">
                    <span
                        id="appointment_designer"
                        v-text="(appointment.opportunity && appointment.opportunity.wm_designer) || ''"
                        class="card-text"
                    />
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col-sm-5">
                    <span class="card-label">Designer Phone</span>
                </div>
                <div class="col-sm-7 text-sm-right">
                    <span
                        id="appointment_designer_phone"
                        v-text="(appointment.opportunity && appointment.opportunity.wm_designer_phone) || ''"
                        class="card-text"
                    ></span>
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col-sm-5">
                    <span class="card-label">Referred By</span>
                </div>
                <div class="col-sm-7 text-sm-right">
                    <span
                        id="appointment_referrer"
                        v-text="appointment.opportunity && appointment.opportunity.wm_referred_by"
                        class="card-text"
                    ></span>
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col-sm-5">
                    <span class="card-label">Opportunity ID</span>
                </div>
                <div class="col-sm-7 text-sm-right">
                    <span
                        id="appointment_opportunity"
                        v-text="appointment.opportunity && appointment.opportunity_id"
                        class="card-text"
                    ></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Panel from "@/components/base/Panel.vue"

export default {
    name: "DetailWidget",
    components: { Panel },
    props: {
        appointment: {
            type: Object,
            required: true,
        },
    },
    computed: {
        appointmentType() {
            return this.appointment.type ? this.appointment.type.name : "--"
        },
    },
}
</script>

<style scoped></style>
