import lodash from "lodash"
import { popper } from "@popperjs/core"
import jquery from "jquery"
import axios from "axios"
import "@fortawesome/fontawesome-free/js/all"

window._ = lodash

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

window.Popper = popper
window.$ = window.jQuery = jquery

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */
window.axios = axios
// window.addEventListener("contextmenu", (event) => event.preventDefault())
window.axios.defaults.baseURL = "http://localhost:8000/api/v1"
//window.axios.defaults.baseURL = import.meta.env.VITE_WISHMAKER_API
window.axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*"
window.axios.defaults.headers.head["Content-Type"] = "application/json"

const user = JSON.parse(localStorage.getItem("user"))
if (user && user.token) {
    window.axios.defaults.headers.common["Authorization"] = "Bearer " + user.token
}

window.axios.interceptors.response.use(undefined, (error) => {
    let errorResponse = error.response
    if (
        errorResponse &&
        errorResponse.status === 401 &&
        errorResponse.config &&
        !errorResponse.config.__isRetryRequest
    ) {
        window.location = "/login"
    }

    return Promise.reject(error)
})
