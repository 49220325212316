import newMapper from "@/middleware/mapper"
import AppointmentApi from "@/modules/Appointments/api"
import reset from "@/store/reset"

const defaultState = () => ({
    appointments: [],
    appointment: null,
    appointmentsLoaded: false,
})

export default {
    namespaced: true,
    state: defaultState(),
    getters: {
        getAppointment: (state) => state.appointment,
        getAppointments: (state) => state.appointments,
        getAppointmentsLoaded: (state) => state.appointmentsLoaded,
    },
    mutations: {
        SET_APPOINTMENTS: (state, payload) => (state.appointments = payload),
        SET_APPOINTMENT: (state, payload) => (state.appointment = payload),
        SET_APPOINTMENTS_LOADED: (state, payload) => (state.appointmentsLoaded = payload),
        RESET: (state) => reset(state, defaultState()),
    },
    actions: {
        fetchAppointments: ({ commit, state }, isOnline) => {
            const mapperParams = {
                handler: AppointmentApi.fetchAppointments,
                action: "GET",
            }
            return newMapper(mapperParams, "appointment", isOnline).then((appointments) => {
                commit("SET_APPOINTMENTS", appointments)
                commit("SET_APPOINTMENTS_LOADED", true)
            })
        },
        fetchAppointment: ({ commit, state }, { id, isOnline }) => {
            id = parseInt(id)
            const mapperParams = {
                handler: AppointmentApi.fetchAppointment,
                action: "GET",
                keys: { id },
            }
            return newMapper(mapperParams, "appointment", isOnline).then((appointment) => {
                commit("SET_APPOINTMENT", appointment[0])
                //commit("SET_APPOINTMENTS_LOADED", true)
            })
        },
        reset: ({ commit }) => commit("RESET"),
    },
}
