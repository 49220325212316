<template>
    <div class="row">
        <div class="col flex-grow-1">
            <div class="row">
                <div class="col">
                    <h6 class="footer-info-line">{{ payableLabel }}</h6>
                </div>
            </div>
            <div class="row">
                <div class="col text-right pt-4">
                    <form :action="opportunity.routes.submit" method="POST" @submit="checkErrors">
                        <input type="hidden" name="_token" :value="csrfToken" />
                        <button
                            class="btn btn-success"
                            :class="{
                                disabled: hasErrors || rowIsBeingEdited || !areTotalsCalculated,
                            }"
                            v-tooltip-raw:top="{
                                content: postOpportunityDisabledMessage,
                                show: hasErrors || rowIsBeingEdited || !areTotalsCalculated,
                            }"
                        >
                            Post Opportunity
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PrintModal from "@/modules/Opportunity/components/partials/PrintModal.vue"
import PaymentOptionModal from "@/modules/Opportunity/components/partials/PaymentOptionModal.vue"
import { mapActions, mapGetters } from "vuex"

export default {
    components: { PaymentOptionModal, PrintModal },
    props: {
        opportunity: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapGetters({
            errorList: "opportunity/getErrors",
            rowIsBeingEdited: "opportunity/getProductRowEditing",
            areTotalsCalculated: "opportunity/getTotalsCalculated",
            paymentOptions: "opportunity/getFinanceOptions",
        }),
        hasErrors() {
            return this.errorList != undefined && this.errorList.length > 0
        },
        payableLabel() {
            return this.opportunity.customer.country_id === 2
                ? `TERMS OF PAYMENT: BALANCE DUE ON INSTALL`
                : `Please make checks payable to: ShelfGenie`
        },
        customerName() {
            return this.opportunity.customer.display_name
        },
        noPaymentOptionsAvailable() {
            return this.paymentOptions.length === 0
        },
        postOpportunityDisabledMessage() {
            if (this.errorList.length > 0) {
                return `There are errors on the page that need to be fixed.`
            }
            if (!this.areTotalsCalculated) {
                return `You need to calculate totals before posting the opportunity.`
            }
            return `There is an issue preventing to be able to post this opportunity.`
        },
        csrfToken() {
            return document.querySelector('meta[name="csrf-token"]')
                ? document.querySelector('meta[name="csrf-token"]').getAttribute("content")
                : null
        },
    },
    watch: {
        errorList() {},
        areTotalsCalculated() {},
        rowIsBeingEdited() {},
    },
    methods: {
        ...mapActions({
            updateOpportunity: "opportunity/updateOpportunity",
            fetchTotals: "opportunity/fetchTotals",
            setBeingSaved: "opportunity/setBeingSaved",
            unsetBeingSaved: "opportunity/unsetBeingSaved",
            updateErrors: "opportunity/updateErrors",
        }),
        checkErrors(e) {
            if (!this.areTotalsCalculated) {
                alert("You need to calculate totals before submitting.")
                e.preventDefault()
            }
            if (this.hasErrors) {
                alert("Please resolve errors before attempting to submit")
                e.preventDefault()
            }
        },
    },
}
</script>