import Db from "@/middleware/db";
import {defaultProduct} from "@/modules/Opportunity/store"

const wishDB = new Db(import.meta.env.VITE_INDEXDB_DEXIE_DB, import.meta.env.VITE_INDEXDB_DEXIE_VERSION)



export default {
     addRows: async (opportunity_id, quantity) => {
        let opportunityProducts = await wishDB.get('opportunity_product',{opportunity_id}, true)
        console.log("OfflineActions::before", opportunityProducts)

        delete defaultProduct.isNew;

        _.range(quantity).forEach(() => {
            opportunityProducts.push({...defaultProduct, opportunity_id, quoted_product_id: 0, id: Math.max(opportunityProducts.map(row => row.id))+1 })
        })

        console.log("OfflineActions", opportunityProducts)

        return new Promise(resolve => resolve(opportunityProducts))
    },

    update: (id, opportunity) => {
        let oldOpporunity = wishDB.get('opportunity',{id})

        return new Promise(resolve => {
            resolve({ ...oldOpporunity, ...opportunity })
        })
    },

    updateNote: (id, note) => {
        let opportunity = wishDB.get('opportunity',{id})

        return new Promise(resolve => {
            resolve({ ...opportunity, note })
        })
    },

    updatePricingOption: (opportunity_id, pricingOption) => {

        let oldPricingOption = wishDB.get('opportunity_pricing_option',{opportunity_id, id: 1})
        return new Promise(resolve => {
            resolve({ ...oldPricingOption, ...oldPricingOption })
        })
    },

    updateTotals: (id, totals) => {
        let opportunity = wishDB.get('opportunity',{id})
        return new Promise(resolve => resolve({...getTotals(opportunity), ...totals}))

    },

    calculateTotals: (id) => axios.get(`/${prefix}/${id}/totals/calculate`),

    updateProduct: (id, product) => {
        return new Promise(resolve => resolve(product))
    },

    deleteProduct: (id, { product }) => {
        return wishDB.get('opportunity_product', {opportunity_id}).filter(row => row.id !== product.id)
    },
}

function getTotals(opportunity) {
    return {
        material_total: parseFloat(opportunity.material_total),
        labor_total: parseFloat(opportunity.labor_total),
        delivery_total: parseFloat(opportunity.delivery_total),
        discount_total: parseFloat(opportunity.discount_total),
        tax_total: parseFloat(opportunity.tax_total),
        initial_deposit: parseFloat(opportunity.initial_deposit),
        opportunity_total: parseFloat(opportunity.opportunity_total),
    }
}