const prefix = `/opportunity`

export default  {
    getSyncData,
}

function getSyncData(sendData) {
        return axios.get(`${prefix}/get-sync-data`,sendData)
}

