<template>
    <div class="col my-1 flex-grow-0">
        <button v-tooltip-raw:top="{ content: localDisabledMessage, show: isDisabled }"
            class="btn btn-primary"
            :class="{'disabled':  isDisabled}"
            @click="clickButton">
            Print
        </button>
    </div>
</template>

<script>
export default {
    name: "PrintModal",
    props: {
        opportunity: {
          type: Object,
          required: true,
        },
        disabled: {
            type: Boolean,
            default: false
        },
        disabledMessage: {
            type: String,
            default: `This is disabled`
        }
    },
    data() {
        return {
            enableModal: false,
            isGenerating: false,
            localDisabledMessage: this.disabledMessage,
            isDisabled: this.disabled

        }
    },
    watch: {
        disabled: {
            handler: function (newVal) {
                this.isDisabled = newVal;
            }
        },
        disabledMessage: {
            handler: function (newVal) {
                this.localDisabledMessage = newVal;
            }
        }
    },
    methods: {
        clickButton() {
            console.log(this.isDisabled);
            if(!this.isDisabled) {
                this.enableModal = true;
                console.log("In CLICK section");
                window.open(`/opportunities/${this.opportunity.id}/pdf`, '_blank');
            }
        }
    }
}
</script>

