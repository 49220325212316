import newMapper from "@/middleware/mapper"
import ProductApi from "@/modules/Product/product_api"
import ProductTypeApi from "@/modules/Product/type_api"
import reset from "@/store/reset"

const defaultState = () => ({
    products: [],
    productTypes: [],
    productsLoaded: false,
    productTypesLoaded: false,
})

export default {
    namespaced: true,
    state: defaultState,
    getters: {
        getProducts: (state) => {
            return state.products
        },
        getProductTypes: (state) => {
            return state.productTypes
        },
        areProductsLoaded: (state) => {
            return state.productsLoaded
        },
        areProductTypesLoaded: (state) => {
            return state.productTypesLoaded
        },
    },
    mutations: {
        SET_PRODUCTS: (state, payload) => {
            state.products = payload
            state.productsLoaded = true
        },
        SET_PRODUCT_TYPES: (state, payload) => {
            state.productTypes = payload
            state.productTypesLoaded = true
        },
        RESET: (state) => reset(state, defaultState()),
    },
    actions: {
        fetchProducts: ({ commit }, isOnline) => {
            newMapper({ handler: ProductApi.getAll, action: "GET" }, "product", isOnline).then((products) => {
                commit("SET_PRODUCTS", products)
            })
        },
        loadProducts: ({ commit }) => {
            newMapper({ handler: ProductApi.getAll, action: "GET" }, "product", false).then((products) => {
                commit("SET_PRODUCTS", products)
            })
        },
        fetchProductTypes: ({ commit }, isOnline) => {
            newMapper({ handler: ProductTypeApi.getAll, action: "GET" }, "product_type", isOnline).then((productTypes) => {
                commit("SET_PRODUCT_TYPES", productTypes)
            })
        },
        loadProductTypes: ({ commit }) => {
            newMapper({ handler: ProductTypeApi.getAll, action: "GET" }, "product_type", false).then((productTypes) => {
                commit("SET_PRODUCT_TYPES", productTypes)
            })
        },
        reset: ({ commit }) => commit("RESET"),
    },
}
