<template>
    <section class="main">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-md-8">
                    <div class="card">
                        <div class="card-body">
                            <img src="/logo.png" id="loginLogo" alt="ShelfGenie::Wishmaker" />
                            <div class="alert alert-danger" v-if="loggingIn && loggingIn.login">
                                These credentials do not match our records.
                            </div>
                            <form @submit.prevent="handleSubmit">
                                <div class="form-group">
                                    <label for="username">Username</label>
                                    <input
                                        id="username"
                                        type="text"
                                        v-model="username"
                                        name="username"
                                        class="form-control"
                                        :class="{
                                            'is-invalid': submitted && !username,
                                        }"
                                    />
                                    <div v-show="submitted && !username" class="invalid-feedback">
                                        Username is required
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label for="password">Password</label>
                                    <input
                                        id="password"
                                        type="password"
                                        v-model="password"
                                        name="password"
                                        class="form-control"
                                        :class="{
                                            'is-invalid': submitted && !password,
                                        }"
                                    />
                                    <div v-show="submitted && !password" class="invalid-feedback">
                                        Password is required
                                    </div>
                                </div>
                                <div class="form-group pt-3">
                                    <div class="d-flex align-items-center justify-content-center">
                                        <button class="btn btn-primary mr-2" :disabled="loggingIn">
                                            Login
                                            <i v-if="loggingIn" class="fas fa-spinner fa-pulse fa-lg fa-fw" />
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import NetworkIndicator from "@/components/NetworkIndicator.vue"

export default {
    components: { NetworkIndicator },
    data() {
        return {
            username: "wot.designer",
            password: "WoT!DeSigner#",
            submitted: false,
        }
    },
    computed: {
        loggingIn () {
            return this.$store.state.authentication.status.loggingIn;
        }
    },
    created() {
        // reset login status
        this.$store.dispatch("authentication/logout")
    },
    methods: {
        handleSubmit (e) {
            this.submitted = true; 
            const { username, password } = this;
            const { dispatch } = this.$store;
            if (username && password) {
                dispatch('authentication/login', { username, password });
            }
        }
    }
}
</script>