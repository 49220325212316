<template>
    <modal :show="displayLoadingModal" static-backdrop>
        <div class="container-fluid">
            <p v-for="(loaded, name) in storeState" :key="name" v-html="loadingMessage(name, loaded)" />
        </div>
    </modal>
</template>

<script>
import Modal from "@/components/base/Modal.vue"
import { mapActions, mapGetters } from "vuex"

export default {
    props: {
        opportunity: {
            type: Object,
            default: null,
        },
    },
    components: {
        Modal,
    },
    computed: {
        ...mapGetters({
            productsLoaded: "product/areProductsLoaded",
            franchiseePricingOptionLoaded: "franchisee/arePricingOptionsLoaded",
            franchiseeFinancingOptionsLoaded: "franchisee/areFinancingOptionsLoaded",
            franchiseeProductPricesLoaded: "franchisee/areProductPricesLoaded",
            productTypesLoaded: "product/areProductTypesLoaded",
            packagesLoaded: "pkg/arePackagesLoaded",
            accessoriesLoaded: "accessory/areAccessoriesLoaded",
            railsLoaded: "rail/areRailsLoaded",
            taxRatesLoaded: "franchisee/areTaxRatesLoaded",
            discountSelectionsLoaded: "opportunity/areDiscountSelectionsLoaded",
            slopeTypesLoaded: "slopeType/areSlopeTypesLoaded",
            appointmentsLoaded: "appointment/getAppointmentsLoaded",
            opportunitiesLoaded: "opportunity/getOpportunitiesLoaded",
            opportunitiesProductsLoaded: "opportunity/opportunitiesProductsLoaded",
            opportunitiesFinancingOptionsLoaded: "opportunity/opportunitiesFinancingOptionsLoaded",
            opportunitiesPricingOptionsLoaded: "opportunity/opportunitiesPricingOptionsLoaded",

            // Opportunity specific
            opportunityLoaded: "opportunity/getOpportunityLoaded",
            pricingOptionLoaded: "opportunity/arePricingOptionsLoaded",
            financingOptionsLoaded: "opportunity/areFinancingOptionsLoaded",
            productPricesLoaded: "opportunity/areProductPricesLoaded",
            opportunityProductsLoaded: "opportunity/areOpportunityProductsLoaded",
        }),
        displayLoadingModal() {
            if (this.opportunity) {
                return !(
                    this.opportunityLoaded &&
                    this.pricingOptionLoaded &&
                    this.productPricesLoaded &&
                    this.opportunityProductsLoaded &&
                    this.productPricesLoaded
                )
            }

            return !(
                this.franchiseePricingOptionLoaded &&
                this.franchiseeFinancingOptionsLoaded &&
                this.franchiseeProductPricesLoaded &&
                this.packagesLoaded &&
                this.productsLoaded &&
                this.productTypesLoaded &&
                this.accessoriesLoaded &&
                this.railsLoaded &&
                this.taxRatesLoaded &&
                this.discountSelectionsLoaded &&
                this.slopeTypesLoaded &&
                this.appointmentsLoaded &&
                this.opportunitiesLoaded &&
                this.opportunitiesProductsLoaded &&
                this.opportunitiesFinancingOptionsLoaded &&
                this.opportunitiesPricingOptionsLoaded
            )
        },
        storeState() {
            if (this.opportunity) {
                return {
                    "Opportunity": !this.opportunityLoaded,
                    "Pricing Options": !this.pricingOptionLoaded,
                    "Product Prices": !this.productPricesLoaded,
                    "Opportunity Products": !this.opportunityProductsLoaded,
                    "Opportunity Prices": !this.productPricesLoaded,
                }
            }
            return {
                "Franchisee Pricing Options": !this.franchiseePricingOptionLoaded,
                "Franchisee Financing Options": !this.franchiseeFinancingOptionsLoaded,
                "Franchisee Product Prices": !this.franchiseeProductPricesLoaded,
                "Appointments": !this.appointmentsLoaded,
                "Opportunities": !this.opportunitiesLoaded,
                "Opportunities Products": !this.opportunitiesProductsLoaded,
                "Opportunities Financing Options": !this.opportunitiesFinancingOptionsLoaded,
                "Opportunities Pricing Options": !this.opportunitiesPricingOptionsLoaded,
                "Packages": !this.packagesLoaded,
                "Products": !this.productsLoaded,
                "Product Types": !this.productTypesLoaded,
                "Accessories": !this.accessoriesLoaded,
                "Rails": !this.railsLoaded,
                "Tax Rates": !this.taxRatesLoaded,
                "Discount Selections": !this.discountSelectionsLoaded,
                "Slope Types": !this.slopeTypesLoaded,
            }
        },
    },
    created() {
        if (this.isOnline) {
            this.reset()
        }
        const params = this.opportunity ? { opportunity_id: this.opportunity.id ?? null } : {}

        params.isOnline = this.isOnline

        if (this.opportunity) {
            this.fetchOpportunity(params).then((response) => {
                const params = this.opportunity ? { opportunity_id: this.opportunity.id ?? null } : {}
                params.isOnline = this.isOnline
                this.fetchOpportunityProducts(params)
            })
            this.fetchPricingOptions(params)
            this.fetchFinancingOption(params)
            this.fetchProductPrices(params)
        } else {
            this.fetchFranchiseePricingOptions(this.isOnline)
            this.fetchFranchiseeFinancingOptions(this.isOnline)
            this.fetchFranchiseeProductPrices(this.isOnline)
            this.fetchProductTypes(this.isOnline)
            this.fetchPackages(this.isOnline)
            this.fetchProducts(this.isOnline)
            this.fetchRails(this.isOnline)
            this.fetchDiscountSelections(this.isOnline)
            this.fetchSlopeTypes(this.isOnline)
            this.fetchFranchiseeTaxRates(this.isOnline)
            this.fetchAccessories(params)
            this.fetchAppointments(this.isOnline)
            this.fetchOpportunities(this.isOnline)
            this.fetchOpportunitiesProducts(this.isOnline)
            this.fetchOpportunitiesFinancingOptions(this.isOnline)
            this.fetchOpportunitiesPricingOptions(this.isOnline)
        }
    },
    methods: {
        ...mapActions({
            // Global Store Reset
            reset: "reset",

            fetchProductTypes: "product/fetchProductTypes",
            fetchProducts: "product/fetchProducts",
            fetchPackages: "pkg/fetchPackages",
            fetchRails: "rail/fetchRails",
            fetchDiscountSelections: "opportunity/fetchDiscountSelections",
            fetchFranchiseeTaxRates: "franchisee/fetchFranchiseeTaxRates",
            fetchSlopeTypes: "slopeType/fetchSlopeTypes",
            fetchFranchiseePricingOptions: "franchisee/fetchFranchiseePricingOptions",
            fetchFranchiseeFinancingOptions: "franchisee/fetchFranchiseeFinancingOptions",
            fetchFranchiseeProductPrices: "franchisee/fetchFranchiseeProductPrices",
            fetchAccessories: "accessory/fetchAccessories",
            fetchAppointments: "appointment/fetchAppointments",
            fetchOpportunities: "opportunity/fetchOpportunities",
            fetchOpportunitiesProducts: "opportunity/fetchOpportunitiesProducts",
            fetchOpportunitiesFinancingOptions: "opportunity/fetchOpportunitiesFinancingOptions",
            fetchOpportunitiesPricingOptions: "opportunity/fetchOpportunitiesPricingOptions",

            // Opportunity specific
            fetchPricingOptions: "opportunity/fetchPricingOption",
            fetchFinancingOption: "opportunity/fetchFinancingOption",
            fetchProductPrices: "opportunity/fetchProductPrices",
            fetchOpportunity: "opportunity/fetchOpportunity",
            fetchOpportunityProducts: "opportunity/fetchProducts",
        }),
        loadingMessage(name, isLoading) {
            let loadingIcon = `<i class="loading-icons fas fa-spinner fa-spin text-info fa-lg" style="margin-right: 35px;"></i>`
            let loadedIcon = `<i class="loading-icons far fa-check-circle text-success  fa-lg" style="margin-right: 35px;"></i>`

            let loadingMessage = `Loading ${name}...`
            let loadedMessage = `${name} are loaded...`
            return isLoading ? `${loadingIcon} ${loadingMessage}` : `${loadedIcon} ${loadedMessage}`
        },
    },
}
</script>
