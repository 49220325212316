<template>
  <button @click="triggerAll">Grab All</button>
  <button @click="triggerOne">Grab One</button>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "TestPage",
  methods: {
    ...mapActions({
      fetchAppointments: "appointment/fetchAppointments",
      fetchAppointment: "appointment/fetchAppointment"
    }),
    triggerAll() {
      this.fetchAppointments(this.isOnline);
    },
    triggerOne() {
      this.fetchAppointment({id: 6266576, isOnline: this.isOnline});
    }
  }
}
</script>

<style scoped>

</style>