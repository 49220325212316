<template>
    <Panel header="Appointment List">
        <!--   <GetSyncDataButton></GetSyncDataButton> -->
        <SyncDataButton></SyncDataButton>
        <table class="table table-striped table-responsive-sm">
            <thead>
                <tr>
                    <th>Appointment ID</th>
                    <th>Appointment Date/Time</th>
                    <th>Customer Name</th>
                    <th>Opportunity ID</th>
                    <th>Progress</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-if="isLoading">
                    <td colspan="7" class="text-center">
                        <i class="my-5 fas fa-spinner fa-3x fa-pulse"></i>
                    </td>
                </tr>
                <tr v-else v-for="(appointment, idx) in appointmentLists" :key="idx">
                    <td>{{ appointment.id }}</td>
                    <td>{{ dateFormat(appointment.date_and_time_starts) }}</td>
                    <td>
                        {{ appointment.customer ? appointment.customer.display_name : "" }}
                    </td>
                    <td>{{ appointment.opportunity_id }}</td>
                    <td>{{ appointment.progress }}</td>
                    <td class="text-right">
                        <RouterLink class="btn btn-info" :to="`/appointments/${appointment.id}`"> Details</RouterLink>
                    </td>
                </tr>
            </tbody>
        </table>
        <UserDataLoadingModal />
    </Panel>
</template>

<script>
import Panel from "@/components/base/Panel.vue"
import UserDataLoadingModal from "@/components/UserDataLoadingModal.vue"
import SyncDataButton from "@/components/appointments/SyncDataButton.vue"
import GetSyncDataButton from "@/components/appointments/GetSyncDataButton.vue"
import { mapGetters } from "vuex"

export default {
    components: {
        UserDataLoadingModal,
        Panel,
        SyncDataButton,
        GetSyncDataButton,
    },
    data() {
        return {
            apptsession: JSON.parse(localStorage.getItem("user")),
        }
    },
    computed: {
        ...mapGetters({
            appointments: "appointment/getAppointments",
            appointmentsLoaded: "appointment/getAppointmentsLoaded",
        }),
        appointmentLists() {
            return Array.from(this.appointments).sort((a, b) => {
                return new Date(a.date_of_appointment) < new Date(b.date_of_appointment) ? 1 : -1
            })
        },
        isLoading() {
            return !this.appointmentsLoaded
        },
    },
}
</script>