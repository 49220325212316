<template>
    <div class="row">
        <div class="col flex-grow-1">
            <div class="row text-nowrap">
                <div class="col my-1 flex-grow-0">
                    <a
                        type="button"
                        v-if="opportunity.appointment"
                        :href="opportunity.appointment.routes.show"
                        class="btn btn-primary"
                        :class="{ disabled: rowIsBeingEdited }"
                        :disabled="rowIsBeingEdited"
                    >
                        Appt Details
                    </a>
                </div>
                <PrintModal
                    :opportunity="opportunity"
                    :disabled="rowIsBeingEdited || !areTotalsCalculated"
                    :disabled-message="printModalDisabledMessage"
                />

                <PaymentOptionModal
                    :opportunity="opportunity"
                    :disabled="rowIsBeingEdited || !areTotalsCalculated || noPaymentOptionsAvailable"
                    :disabled-message="paymentOptionsDisabledMessage"
                />
                <div class="col my-1 flex-grow-0">
                    <button
                        v-tooltip-raw:top="{
                            content: calculateTotalDisabledMessage,
                            show: hasErrors || rowIsBeingEdited,
                        }"
                        class="btn btn-success"
                        :class="{ disabled: hasErrors || rowIsBeingEdited }"
                        @click="calculateTotals"
                    >
                        Calculate Totals
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PrintModal from "@/modules/Opportunity/Components/partials/PrintModal.vue"
import PaymentOptionModal from "@/modules/Opportunity/Components/partials/PaymentOptionModal.vue"
import { mapActions, mapGetters } from "vuex"

export default {
    components: { PaymentOptionModal, PrintModal },
    props: {
        opportunity: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapGetters({
            errorList: "opportunity/getErrors",
            rowIsBeingEdited: "opportunity/getProductRowEditing",
            areTotalsCalculated: "opportunity/getTotalsCalculated",
            paymentOptions: "opportunity/getFinanceOptions",
            paymentOptionsLoaded: "opportunity/areFinancingOptionsLoaded",
        }),
        hasErrors() {
            return this.errorList != undefined && this.errorList.length > 0
        },
        payableLabel() {
            return this.opportunity.customer.country_id === 2
                ? `TERMS OF PAYMENT: BALANCE DUE ON INSTALL`
                : `Please make checks payable to: ShelfGenie`
        },
        customerName() {
            return this.opportunity.customer.display_name
        },
        noPaymentOptionsAvailable() {
            if (!this.paymentOptionsLoaded) {
                return true
            }
            return this.paymentOptions.length === 0
        },
        paymentOptionsDisabledMessage() {
            if (this.noPaymentOptionsAvailable) {
                return `No Payment Options Available.`
            }
            if (this.errorList.length > 0) {
                return `There are errors on the page that need to be fixed.`
            }
            if (!this.areTotalsCalculated) {
                return `You need to calculate totals before accessing Payment Options.`
            }
            return `Payment Options Disabled.`
        },
        printModalDisabledMessage() {
            if (this.errorList.length > 0) {
                return `There are errors on the page that need to be fixed.`
            }
            if (!this.areTotalsCalculated) {
                return `You need to calculate totals before printing.`
            }
            return `There is an issue preventing to be able to print.`
        },
        disableCalculateTotalsButton() {
            return this.hasErrors || this.rowIsBeingEdited
        },
        calculateTotalDisabledMessage() {
            if (this.errorList.length > 0) {
                return `There are errors on the page that need to be fixed.`
            }
            return `There is an issue preventing to be able to calculate totals.`
        },
        postOpportunityDisabledMessage() {
            if (this.errorList.length > 0) {
                return `There are errors on the page that need to be fixed.`
            }
            if (!this.areTotalsCalculated) {
                return `You need to calculate totals before posting the opportunity.`
            }
            return `There is an issue preventing to be able to post this opportunity.`
        },
        csrfToken() {
            return document.querySelector('meta[name="csrf-token"]')
                ? document.querySelector('meta[name="csrf-token"]').getAttribute("content")
                : null
        },
    },
    watch: {
        errorList() {},
        areTotalsCalculated() {},
        rowIsBeingEdited() {},
    },
    methods: {
        ...mapActions({
            updateOpportunity: "opportunity/updateOpportunity",
            fetchTotals: "opportunity/fetchTotals",
            setBeingSaved: "opportunity/setBeingSaved",
            unsetBeingSaved: "opportunity/unsetBeingSaved",
            updateErrors: "opportunity/updateErrors",
        }),
        checkErrors(e) {
            if (!this.areTotalsCalculated) {
                alert("You need to calculate totals before submitting.")
                e.preventDefault()
            }
            if (this.hasErrors) {
                alert("Please resolve errors before attempting to submit")
                e.preventDefault()
            }
        },
        calculateTotals() {
            if (this.disableCalculateTotalsButton) {
                return false
            }
            this.setBeingSaved(["calculate_totals"])
            this.fetchTotals(this.opportunity.id)
                .then(() => this.unsetBeingSaved(["calculate_totals"]))
                .catch((error) => {
                    console.log(error, error.response, error.message)
                    this.unsetBeingSaved(["calculate_totals"])
                    alert("There was an issue attempting to calculate totals.")
                })
        },
    },
}
</script>