<template>
    <div class="alert alert-danger" v-if="hasErrors">
        <div v-for="row in errorList">{{ row.rowId }}: {{ row.errors.join(" ") }}</div>
    </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
    computed: {
        ...mapGetters({
            errorList: "opportunity/getErrors",
        }),
        hasErrors() {
            return this.errorList != undefined && this.errorList.length > 0
        },
    },
    watch: {
        errorList() {},
    },
}
</script>