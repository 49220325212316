<template>
    <div class="form-check">
        <input
            @change="onClick"
            ref="checkbox"
            v-model="isChecked"
            type="checkbox"
            class="form-check-input"
            :disabled="disabled"
            @focus="isFocused = true"
            @blur="isFocused = false"
        />
    </div>
</template>

<script>
export default {
    props: {
        modelValue: {
          type: [String,Boolean],
          default: null,
        },
        isCheckValue: String,
        isUncheckedValue: String,
        disabled: Boolean,
    },
    emits: ["focus", "focusState", "update:modelValue"],
    data() {
        return {
            isChecked: false,
            isFocused: false,
        }
    },
    created() {
        this.setCheckState(this.modelValue)
    },
    watch: {
        isFocused: function (newValue) {
            this.$emit("focusState", newValue)
        },
        isChecked: function (newValue) {
            let status = newValue ? this.isCheckValue : this.isUncheckedValue
            this.$emit("update:modelValue", status)
        },
        modelValue: function (newValue) {
            this.setCheckState(newValue)
        },
    },
    methods: {
        setCheckState(value) {
            if (value !== null) {
                if (value === this.isCheckValue) {
                    this.isChecked = true
                }
                if (value === this.isUncheckedValue) {
                    this.isChecked = false
                }
            }
        },
        onClick() {
            $(this.$refs.checkbox)[0].focus()
            this.isFocused = true
            this.$emit("focus", this.isFocused)
        },
    },
}
</script>

<style>
  .form-check-input {
    position: relative;
  }
</style>
