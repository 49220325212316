<template>
    <tbody>
        <template v-for="(item, idx) in items" :key="`${listKeyBuilder(item, idx)}`">
            <order-table-header class="header-animation" :class="{ hide: !isFocused(idx) }" />
            <order-product-row
                :row-key="`${listKeyBuilder(item, idx)}`"
                v-model="items[idx]"
                :product-item="items[idx]"
                :is-loaded="true"
                v-on:saving-row="triggerSavingRow"
                v-on:is-focused="(value) => triggerIsFocused(idx, value)"
                :is-new-row="items[idx].isNew"
                :read-only="markReadOnly(idx)"
            />
        </template>
    </tbody>
</template>

<script>
import OrderTableHeader from "@/modules/Opportunity/Components/Partials/OrderTableHeader.vue"
import OrderProductRow from "@/modules/Opportunity/Components/Partials/OrderProductRow.vue"

export default {
    components: { OrderTableHeader, OrderProductRow },
    props: {
        items: {
            type: Array,
            default: () => [],
        },
        isLoaded: {
            type: Boolean,
            default: false,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
    },
    emits: ["saving-row"],
    data() {
        return {
            rowIsFocused: null,
            rowsBeingSaved: [],
        }
    },
    watch: {
        items() {
            this.rowIsFocused = null
        },
    },
    methods: {
        triggerIsFocused(idx, value) {
            this.rowIsFocused = value ? idx : null
        },
        isFocused(idx) {
            return this.rowIsFocused === idx && this.rowsBeingSaved.length === 0
        },
        listKeyBuilder(product, idx) {
            let joinList = []
            joinList.push(idx)
            if (product.hasOwnProperty("id")) {
                joinList.push(product.id)
            }
            return joinList.join(".")
        },
        triggerSavingRow(value) {
            this.updateRowSaving(value)
            this.$emit("saving-row", value)
        },
        updateRowSaving(savingData) {
            if (savingData.isSaving) {
                this.rowsBeingSaved.push(savingData.row)
            } else {
                let idx = this.rowsBeingSaved.indexOf(savingData.row)
                this.rowsBeingSaved.splice(idx, 1)
            }
        },
        markReadOnly(idx) {
            return this.readOnly || (this.rowIsFocused !== null && this.rowIsFocused !== idx)
        },
    },
}
</script>

<style scoped>
.header-animation {
    opacity: 1;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    height: auto;
}

.header-animation.hide {
    opacity: 0;
    height: 0;
    display: none;
}
</style>