import Dexie from "dexie"
import schema from "@/middleware/schema"

export default class {
    #db

    constructor(dbName, version = 1) {
        this.#db = new Dexie("wishmaker")
        this.#db.version(version).stores(schema)
    }

    async get(name, keys, hasMany = false) {
        const table = this.#db.table(name)

        switch (Object.keys(keys).length) {
            case 1:
                let params = this.getNumericalIds(keys)
                let result = await (hasMany ? table.where(params).toArray() : table.get(params))
                return Array.isArray(result) ? result : [result]
            case 0:
                return await table.toArray()
            default:
                return await table.bulkGet(this.getNumericalIds(keys)).toArray()
        }
    }

    post(name, data) {
        // Can't put empty data into the indexedDB, bail
        if ([[], {}, undefined].includes(data)) {
            return data
        }

        const table = this.#db.table(name)
        if (Array.isArray(data)) {
            table.bulkPut(data)
        } else {
            // If the response is not an array (i.e. a single record), then add it to the table
            table.put(data)
        }

        if (!Array.isArray(data)) {
            return [data]
        }
        return data
    }

    put(name, keys, data) {
        const table = this.#db.table(name)
        if (Array.isArray(data) && Object.keys(keys).length > 0) {
            table.bulkPut(data, keys)
        } else if (Array.isArray(data)) {
            table.bulkPut(data)
        } else {
            // If the response is not an array (i.e. a single record), then add it to the table
            table.update(this.getNumericalIds(keys), data)
        }

        if (!Array.isArray(data)) {
            return [data]
        }
        return data
    }

    patch(name, keys, data) {
        return this.put(name, keys, data)
    }

    delete(name, keys) {
        const table = this.#db.table(name)
        if (Object.keys(keys).length > 0) {
            table.delete(this.getNumericalIds(keys))
        }
    }

    getNumericalIds(keys) {
        Object.keys(keys).forEach((key) => (keys[key] = parseInt(keys[key])))
        return keys
    }
}
