<template>
    <div class="">
        <div class="form-group row" v-for="breakdown in breakdowns">
            <label :class="labelClasses">{{ breakdown.label }}</label>
            <div :class="dataClasses">
                <div class="input-group input-group-sm option-prices-field">
                    <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                    </div>
                    <input
                        type="text"
                        class="form-control fix-rounded-right"
                        :value="currency(total * breakdown.factor)"
                        readonly
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        total: {
            type: Number,
            default: 0.0,
        },
        breakdowns: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        labelClasses() {
            return "col-md-6 col-lg-8 col-form-label text-right"
        },
        dataClasses() {
            return "col-md-6 col-lg-4"
        },
    },
}
</script>
