import OpportunityApi from "@/modules/Opportunity/api"
import OfflineActions from "@/modules/Opportunity/offlineActions"
import newMapper from "@/middleware/mapper"
import numeral from "numeral"
import reset from "@/store/reset"

export const defaultProduct = {
    isNew: true,
    a_option: "y",
    b_option: "n",
    c_option: "n",
    d_option: "n",
    use_in_order: "y",
    product_type_id: 0,
    opening_width: "",
    package_id: 0,
    qty: null,
    unit_price: 0,
    unit_cost: 0,
    total_price: 0,
    product_id: null,
    bottom_color_id: 0,
    rail_type_id: 0,
    slope: "n",
    scoop: "n",
    slope_type_id: 1,
    number_of_dividers: 0,
    number_of_additional_grooves: 0,
    a_b_or_both: "",
    divider_option: null,
    comments: "",
}

let defaultTotals = {
    material_total: 0.0,
    labor_total: 0.0,
    discount_total: 0.0,
    tax_total: 0.0,
    delivery_total: 0.0,
    initial_deposit: 0.0,
}

const defaultState = () => ({
    opportunities: [],
    opportunity: null,
    products: [],
    options: {
        a_description: "",
        b_description: "",
        c_description: "",
        d_description: "",
    },
    discountTypes: [],
    discountOptions: [],
    discountSelectionsLoaded: false,
    pricingOptions: null,
    pricingOptionsLoaded: false,
    financingOptions: null,
    financingOptionsLoaded: false,
    productPrices: [],
    productPricesLoaded: false,
    opportunityProductsLoaded: false,
    opportunitiesProductsLoaded: false,
    totals: defaultTotals,
    errorsArray: [],
    productRowEditing: false,
    beingSaved: [],
    totalsCalculated: false,
    opportunitiesLoaded: false,
    opportunityLoaded: false,

    opportunitiesFinancingOptions: [],
    opportunitiesFinancingOptionsLoaded: false,
    opportunitiesPricingOptions: [],
    opportunitiesPricingOptionsLoaded: false,
})

export default {
    namespaced: true,
    state: defaultState(),
    getters: {
        getOpportunities: (state) => state.opportunities,
        getOpportunitiesLoaded: (state) => state.opportunitiesLoaded,
        opportunitiesProductsLoaded: (state) => state.opportunitiesProductsLoaded,
        getOpportunity: (state) => state.opportunity,
        getOpportunityLoaded: (state) => state.opportunityLoaded,
        getProducts: (state) => state.products,
        getOptions: (state) => state.options,
        getDiscountTypes: (state) => state.discountTypes,
        getDiscountOptions: (state) => state.discountOptions,
        areDiscountSelectionsLoaded: (state) => state.discountSelectionsLoaded,
        getPricingOptions: (state) => state.pricingOptions,
        arePricingOptionsLoaded: (state) => state.pricingOptionsLoaded,
        getFinanceOptions: (state) => state.financingOptions,
        areFinancingOptionsLoaded: (state) => state.financingOptionsLoaded,
        getProductPrices: (state) => state.productPrices,
        areProductPricesLoaded: (state) => state.productPricesLoaded,
        areOpportunityProductsLoaded: (state) => state.opportunityProductsLoaded,
        getTotals: (state) => state.totals,
        getLastId: (state) => {
            if (state.products.length === 0) {
                return 1
            }
            let ordered = Array.from(state.products).sort((a, b) => (b.id > a.id ? 1 : -1))
            return ordered[0].id
        },

        getErrors: (state) => state.errorsArray,
        getProductRowEditing: (state) => state.productRowEditing,
        getBeingSaved: (state) => state.beingSaved,
        isSaving: (state) => state.beingSaved.length > 0,
        getTotalsCalculated: (state) => state.totalsCalculated,

        opportunitiesFinancingOptions: (state) => state.opportunitiesFinancingOptions,
        opportunitiesFinancingOptionsLoaded: (state) => state.opportunitiesFinancingOptionsLoaded,
        opportunitiesPricingOptions: (state) => state.opportunitiesPricingOptions,
        opportunitiesPricingOptionsLoaded: (state) => state.opportunitiesPricingOptionsLoaded,
    },
    mutations: {
        SET_OPTIONS: (state, payload) => (state.options = payload),
        SET_TOTALS: (state, payload) => (state.totals = payload),
        ADD_PRODUCTS: (state, payload) => (state.products = state.products.concat(payload)),
        SET_DISCOUNT_TYPES: (state, payload) => (state.discountTypes = payload),
        SET_DISCOUNT_OPTIONS: (state, payload) => (state.discountOptions = payload),
        SET_DISCOUNT_SELECTIONS_LOADED: (state, payload) => (state.discountSelectionsLoaded = payload),

        SET_OPPORTUNITY: (state, payload) => (state.opportunity = Object.assign({}, payload)),
        SET_OPPORTUNITY_LOADED: (state, payload) => (state.opportunityLoaded = payload),
        SET_OPPORTUNITIES: (state, payload) => (state.opportunities = payload),
        SET_OPPORTUNITIES_LOADED: (state, payload) => (state.opportunitiesLoaded = payload),
        SET_OPPORTUNITIES_PRODUCTS_LOADED: (state, payload) => (state.opportunitiesProductsLoaded = payload),
        SET_PRICING_OPTIONS: (state, payload) => (state.pricingOptions = Object.assign({}, payload)),
        SET_PRICING_OPTIONS_LOADED: (state, payload) => (state.pricingOptionsLoaded = payload),
        SET_FINANCING_OPTIONS: (state, payload) => (state.financingOptions = Object.assign({}, payload)),
        SET_FINANCING_OPTIONS_LOADED: (state, payload) => (state.financingOptionsLoaded = payload),
        SET_PRODUCT_PRICES: (state, payload) => (state.productPrices = Object.assign({}, payload)),
        SET_PRODUCT_PRICES_LOADED: (state, payload) => (state.productPricesLoaded = payload),

        SET_FINANCE_OPTIONS: (state, payload) => (state.financingOptions = payload),
        SET_PRODUCTS: (state, payload) => {
            //console.trace("product being updated to ", payload)
            return (state.products = payload)
        },
        SET_PRODUCTS_LOADED: (state, payload) => (state.opportunityProductsLoaded = payload),
        UPDATE_PRODUCT: (state, { idx, product }) => state.products.splice(idx, 1, product),
        SET_ERRORS_ARRAY: (state, payload) => (state.errorsArray = payload),
        SET_PRODUCT_ROW_IS_EDITING: (state, payload) => (state.productRowEditing = payload),
        SET_BEING_SAVED: (state, payload) => (state.beingSaved = payload),
        SET_TOTALS_CALCULATED: (state, payload) => (state.totalsCalculated = payload),
        RESET: (state) => reset(state, defaultState()),

        SET_OPPORTUNITIES_FINANCING_OPTIONS: (state, payload) => (state.opportunitiesFinancingOptions = payload),
        SET_OPPORTUNITIES_FINANCING_OPTIONS_LOADED: (state, payload) =>
            (state.opportunitiesFinancingOptionsLoaded = payload),
        SET_OPPORTUNITIES_PRICING_OPTIONS: (state, payload) => (state.opportunitiesPricingOptions = payload),
        SET_OPPORTUNITIES_PRICING_OPTIONS_LOADED: (state, payload) =>
            (state.opportunitiesPricingOptionsLoaded = payload),
    },
    actions: {
        loadOpportunity: ({ commit }, opportunity_id) => {
            return newMapper(
                {
                    handler: OpportunityApi.fetchOpportunity,
                    action: "get",
                    keys: { id: opportunity_id },
                },
                "opportunity",
                false,
            ).then((opportunity) => {
                commit("SET_OPPORTUNITY", opportunity[0])
                commit("SET_OPPORTUNITY_LOADED", true)
                //commit("SET_TOTALS", determineResetTotals(state));
                commit("SET_TOTALS_CALCULATED", false)
            })
        },
        loadOpportunityProducts: ({ commit }, opportunity_id) => {
            return newMapper(
                {
                    handler: OpportunityApix.fetchProducts(),
                    action: "get",
                    keys: { id: opportunity_id },
                },
                "opportunity_product",
                false,
            ).then((products) => {
                commit("SET_PRODUCTS", products)
                commit("SET_PRODUCTS_LOADED", true)
            })
        },
        loadOptions: ({ commit }, payload) => {
            commit("SET_OPTIONS", payload)
        },
        loadTotals: ({ commit }, payload) => {
            payload.initial_deposit = numeral(payload.initial_deposit).format(",3.00")
            commit("SET_TOTALS", payload)
            commit("SET_TOTALS_CALCULATED", true)
        },
        loadPricingOption: ({ commit }, pricingOption) => {
            commit("SET_PRICING_OPTIONS", pricingOption)
            commit("SET_PRICING_OPTIONS_LOADED", true)
        },
        fetchPricingOption: ({ commit }, { opportunity_id, isOnline }) => {
            return newMapper(
                {
                    handler: OpportunityApi.fetchPricingOption,
                    action: "get",
                    keys: { opportunity_id },
                },
                "opportunity_pricing_option",
                isOnline,
            ).then((pricingOption) => {
                commit("SET_PRICING_OPTIONS", pricingOption[0])
                commit("SET_PRICING_OPTIONS_LOADED", true)
            })
        },
        updatePricingOption: ({ commit, state }, { opportunity_id, pricingOption, isOnline}) => {
            return newMapper(
                {
                    handler: OpportunityApi.updatePricingOption,
                    offlineHandler: OfflineActions.updatePricingOption,
                    action: "patch",
                    keys: { opportunity_id },
                    data: pricingOption,
                },
                "opportunity_pricing_option",
                isOnline,
            ).then((pricingOption) => {
                commit("SET_PRICING_OPTIONS", pricingOption[0])
                commit("SET_TOTALS", determineResetTotals(state))
                commit("SET_TOTALS_CALCULATED", false)
            })
        },
        fetchFinancingOption: ({ commit }, { opportunity_id, isOnline }) => {
            return newMapper(
                {
                    handler: OpportunityApi.fetchFinancingOption,
                    action: "get",
                    keys: { opportunity_id },
                },
                "opportunity_financing_option",
                isOnline,
            ).then((financingOptions) => {
                commit("SET_FINANCING_OPTIONS", financingOptions)
                commit("SET_FINANCING_OPTIONS_LOADED", true)
            })
        },
        fetchProductPrices: ({ commit, state }, { opportunity_id, isOnline }) => {
            return newMapper(
                {
                    handler: OpportunityApi.fetchProductPrices,
                    action: "get",
                    keys: { opportunity_id },
                },
                "jopportunity_product",
                isOnline,
            ).then((jopportunity_product) => {
                commit("SET_PRODUCT_PRICES", jopportunity_product)
                commit("SET_PRODUCT_PRICES_LOADED", true)
                return jopportunity_product
            })
        },
        fetchTotals: ({ commit }, id, isOnline) => {
            return newMapper(
                {
                    handler: OpportunityApi.calculateTotals,
                    action: "get",
                    keys: { id },
                },
                "opportunity",
                isOnline,
            ).then((results) => {
                let opportunity = results[0]
                let totals = {
                    material_total: opportunity.material_total,
                    labor_total: opportunity.labor_total,
                    delivery_total: opportunity.delivery_total,
                    discount_total: opportunity.discount_total,
                    tax_total: opportunity.tax_total,
                    initial_deposit: opportunity.initial_deposit,
                    opportunity_total: opportunity.opportunity_total,
                }
                console.log(opportunity, totals)
                totals.initial_deposit = numeral(totals.initial_deposit).format(",3.00")
                commit("SET_TOTALS", totals)
                commit("SET_TOTALS_CALCULATED", true)
            })
        },
        updateTotals: ({ commit, state }, { id, totals }, isOnline) => {
            let currentOpportunity = {}
            Object.assign(state.opportunity, currentOpportunity)
            return newMapper(
                {
                    handler: OpportunityApi.updateTotals,
                    offlineHandler: OfflineActions.updateTotals,
                    action: "patch",
                    keys: { id },
                    data: { totals },
                },
                "opportunity",
                isOnline,
            ).then((results) => {
                let opportunity = results[0]
                let totals = {
                    material_total: opportunity.material_total,
                    labor_total: opportunity.labor_total,
                    delivery_total: opportunity.delivery_total,
                    discount_total: opportunity.discount_total,
                    tax_total: opportunity.tax_total,
                    initial_deposit: opportunity.initial_deposit,
                    opportunity_total: opportunity.opportunity_total,
                }
                totals.initial_deposit = numeral(totals.initial_deposit).format(",3.00")
                commit("SET_OPPORTUNITY", { ...currentOpportunity, ...totals })
            })
        },
        updateDelivery({ commit, state }, { id, payload }, isOnline) {
            const pricingOptions = state.pricingOptions
            return newMapper(
                {
                    handler: OpportunityApi.updatePricingOption,
                    offlineHandler: OfflineActions.updatePricingOption,
                    action: "patch",
                    keys: { id },
                    data: {
                        ...pricingOptions,
                        ...payload,
                    },
                },
                "opportunity_pricing_option",
                isOnline,
            ).then((pricingOption) => {
                commit("SET_PRICING_OPTIONS", pricingOption[0])
                commit("SET_TOTALS", determineResetTotals(state))
                commit("SET_TOTALS_CALCULATED", false)
            })
        },
        updateOpportunity: ({ commit, state }, { id, opportunity }, isOnline) => {
            const current = state.opportunity
            return newMapper(
                {
                    handler: OpportunityApi.update,
                    offlineHandler: OfflineActions.update,
                    action: "patch",
                    keys: { id },
                    data: {
                        ...current,
                        ...opportunity,
                    },
                },
                "opportunity",
                isOnline,
            ).then((opportunity) => {
                commit("SET_OPPORTUNITY", opportunity[0])
                commit("SET_TOTALS", determineResetTotals(state))
                commit("SET_TOTALS_CALCULATED", false)
            })
        },
        updateOpportunityNote: ({ commit, state }, { id, note }, isOnline) => {
            return newMapper(
                {
                    handler: OpportunityApi.updateNote,
                    offlineHandler: OfflineActions.updateNote,
                    action: "patch",
                    keys: { id },
                    data: { note },
                },
                "opportunity",
                isOnline,
            ).then((opportunity) => {
                commit("SET_OPPORTUNITY", dopportunity[0])
            })
        },
        fetchOpportunities: ({ commit, state }, isOnline) => {
            return newMapper(
                {
                    handler: OpportunityApi.fetchRecentOpportunities,
                    action: "get",
                },
                "opportunity",
                isOnline,
            ).then((opportunity) => {
                commit("SET_OPPORTUNITIES", opportunity)
                commit("SET_OPPORTUNITIES_LOADED", true)
            })
        },
        fetchOpportunitiesProducts: ({ commit, state }, isOnline) => {
            return newMapper(
                {
                    handler: OpportunityApi.fetchRecentOpportunitiesProducts,
                    action: "get",
                },
                "opportunity_product",
                isOnline,
            ).then((opportunity) => {
                commit("SET_OPPORTUNITIES_PRODUCTS_LOADED", true)
            })
        },
        fetchOpportunitiesPricingOptions: ({ commit, state }, isOnline) => {
            return newMapper(
                {
                    handler: OpportunityApi.fetchRecentOpportunitiesPricingOptions,
                    action: "get",
                },
                "opportunity_pricing_option",
                isOnline,
            ).then((opportunity_pricing_option) => {
                commit("SET_OPPORTUNITIES_PRICING_OPTIONS", opportunity_pricing_option)
                commit("SET_OPPORTUNITIES_PRICING_OPTIONS_LOADED", true)
            })
        },
        fetchOpportunitiesFinancingOptions: ({ commit, state }, isOnline) => {
            return newMapper(
                {
                    handler: OpportunityApi.fetchRecentOpportunitiesFinancingOptions,
                    action: "get",
                },
                "opportunity_financing_option",
                isOnline,
            ).then((opportunity_financing_option) => {
                commit("SET_OPPORTUNITIES_FINANCING_OPTIONS", opportunity_financing_option)
                commit("SET_OPPORTUNITIES_FINANCING_OPTIONS_LOADED", true)
            })
        },
        fetchOpportunity: ({ commit, state }, { opportunity_id, isOnline }) => {
            return newMapper(
                {
                    handler: OpportunityApi.fetchOpportunity,
                    action: "get",
                    keys: { id: opportunity_id },
                },
                "opportunity",
                isOnline,
            ).then((opportunity) => {
                commit("SET_OPPORTUNITY", opportunity[0])
                commit("SET_OPPORTUNITY_LOADED", true)
                //commit("SET_TOTALS", determineResetTotals(state));
                commit("SET_TOTALS_CALCULATED", false)
            })
        },
        fetchProducts: ({ commit, state }, { opportunity_id, isOnline }) => {
            opportunity_id = parseInt(opportunity_id)
            return newMapper(
                {
                    handler: OpportunityApi.fetchProducts,
                    action: "get",
                    keys: { opportunity_id },
                },
                "opportunity_product",
                isOnline,
            ).then((opportunity_product) => {
                commit("SET_PRODUCTS", Array.from(opportunity_product.sort((a, b) => (a.id > b.id ? 1 : -1))))
                commit("SET_PRODUCTS_LOADED", true)
                //commit("SET_TOTALS", determineResetTotals(state));
                commit("SET_TOTALS_CALCULATED", false)
            })
        },
        removeProduct: ({ commit, state }, { id, product }, isOnline) => {
            return newMapper(
                {
                    handler: OpportunityApi.deleteProduct,
                    offlineHandler: OfflineActions.deleteProduct,
                    action: "delete",
                    keys: { id },
                    data: { product },
                },
                "opportunity_product",
                isOnline,
            ).then((response) => {
                let idx = state.products.findIndex(function (row) {
                    return row.id === product.id
                })
                const newState = state.products
                newState.splice(idx, 1)
                commit("SET_PRODUCTS", newState)
                commit("SET_TOTALS", determineResetTotals(state))
                commit("SET_TOTALS_CALCULATED", false)
                return response
            })
        },
        addNewProducts: ({ commit, getters, state }, { opportunity_id, quantity, isOnline, sendToServer = false }) => {
            if (sendToServer) {
                console.trace("addNewProducts", opportunity_id, quantity, isOnline, sendToServer)
                return newMapper(
                    {
                        handler: OpportunityApi.addRows,
                        offlineHandler: OfflineActions.addRows,
                        action: "post",
                        keys: { opportunity_id },
                        data: { quantity },
                    },
                    "opportunity_product",
                    isOnline,
                ).then((products) => {
                    console.log("STORE", products);
                    commit("SET_PRODUCTS", products)
                    commit("SET_TOTALS", determineResetTotals(state))
                    commit("SET_TOTALS_CALCULATED", false)
                })
            }

            commit("ADD_PRODUCTS", [defaultProduct])
            commit("SET_TOTALS", determineResetTotals(state))
            commit("SET_TOTALS_CALCULATED", false)
        },
        saveProduct: ({ commit, state }, { idx, opportunityId, product, whereAt, isOnline }) => {
            return newMapper(
                {
                    handler: OpportunityApi.updateProduct,
                    offlineHandler: OfflineActions.updateProduct,
                    action: "patch",
                    keys: { id: opportunityId },
                    data: { product },
                },
                "opportunity_product",
                isOnline,
            ).then((product) => {
                commit("UPDATE_PRODUCT", { idx, product: product[0] })
                commit("SET_TOTALS", determineResetTotals(state))
                commit("SET_TOTALS_CALCULATED", false)
            })
        },
        fetchDiscountSelections: ({ commit, state }, isOnline) => {
            const discountTypes = newMapper(
                {
                    handler: OpportunityApi.fetchDiscountSelections,
                    action: "GET",
                },
                "discount_types",
                isOnline,
            )
            const discountOptions = newMapper(
                {
                    handler: OpportunityApi.fetchDiscountSelections,
                    action: "GET",
                },
                "discount_options",
                isOnline,
            )
            return Promise.all([discountTypes, discountOptions]).then((results) => {
                let type = results[0]
                let options = results[1].map((row) => {
                    return { value: row.id, label: row.name }
                })
                commit("SET_DISCOUNT_TYPES", type)
                commit("SET_DISCOUNT_OPTIONS", options)
                commit("SET_DISCOUNT_SELECTIONS_LOADED", true)
            })
        },
        updateErrors: ({ commit, state }, { row, errors }) => {
            const currentErrorsArray = state.errorsArray
            let errorIndex = currentErrorsArray.findIndex((data) => {
                return data.rowId == row.toString()
            })

            if (errors.length > 0) {
                if (errorIndex === -1) {
                    currentErrorsArray.push({ rowId: row, errors: errors })
                } else {
                    currentErrorsArray.splice(errorIndex, 1, {
                        rowId: row,
                        errors: errors,
                    })
                }
            } else {
                if (errorIndex > -1) {
                    currentErrorsArray.splice(errorIndex)
                }
            }
            //commit("SET_ERRORS_ARRAY", currentErrorsArray);
        },
        markProductRowIsEditing: ({ commit }) => {
            commit("SET_PRODUCT_ROW_IS_EDITING", true)
        },
        unmarkProductRowIsEditing: ({ commit }) => {
            commit("SET_PRODUCT_ROW_IS_EDITING", false)
        },
        setBeingSaved: ({ commit, state }, sets) => {
            const beingSavedArray = state.beingSaved
            console.log(sets)
            sets.forEach((row) => {
                let idx = beingSavedArray.findIndex((data) => data.rowId == row)
                if (idx === -1) {
                    beingSavedArray.push(row)
                }
            })
        },
        unsetBeingSaved: ({ commit, state }, sets) => {
            const beingSavedArray = state.beingSaved
            sets.forEach((row) => {
                let idx = beingSavedArray.findIndex((data) => data === row)
                beingSavedArray.splice(idx, 1)
            })
        },
        resetTotals: ({ commit, state }) => {
            commit("SET_TOTALS", determineResetTotals(state))
            commit("SET_TOTALS_CALCULATED", false)
        },
        reset: ({ commit }) => commit("RESET"),
    },
}

function determineResetTotals(state) {
    if (state.pricingOptions.delivery_type === "Dollars") {
        return {
            ...defaultTotals,
            delivery_total: state.pricingOptions.cost_delivery,
        }
    }
    return defaultTotals
}
