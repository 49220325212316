import { createStore } from "vuex"

import { alert } from "@/store/alert.module"
import { authentication } from "@/store/authentication.module"
import { users } from "@/store/users.module"
import accessory from "@/modules/Accessory/store"
import franchisee from "@/modules/Franchisee/store"
import opportunity from "@/modules/Opportunity/store"
import pkg from "@/modules/Package/store"
import product from "@/modules/Product/store"
import rail from "@/modules/Rail/store"
import slopeType from "@/modules/SlopeType/store"
import appointment from "@/modules/Appointments/store"

export default createStore({
    modules: {
        alert,
        authentication,
        users,
        accessory,
        franchisee,
        opportunity,
        pkg,
        product,
        rail,
        slopeType,
        appointment,
    },
    actions: {
        async reset({ dispatch }) {
            await dispatch("accessory/reset")
            await dispatch("appointment/reset")
            await dispatch("franchisee/reset")
            await dispatch("opportunity/reset")
            await dispatch("pkg/reset")
            await dispatch("product/reset")
            await dispatch("rail/reset")
            await dispatch("slopeType/reset")
        },
    },
})
