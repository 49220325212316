<template>
    <div>
        <modal :show="beingSaved.length > 0" class="loading-modal">
            <i class="loading-icons fas fa-spinner fa-spin text-info fa-lg" style="margin-right: 35px"></i>
            Updating Opportunity...
        </modal>

        <div class="save-notification">
            <span v-if="isSaving"><i class="fas fa-spinner fa-pulse"></i> Saving...</span>
            <div>{{ savingMessages.join(", ") }}</div>
        </div>

        <div class="row my-4">
            <div class="col flex-grow-0">
                <button type="button" @click="addRow(1)" class="btn-text">
                    <span class="text">Add 1 Row</span> <span class="icon">+</span>
                </button>
            </div>
            <div class="col flex-grow-0">
                <button type="button" @click="addRow(5)" class="btn-text">
                    <span class="text">Add 5 Rows</span> <span class="icon">+</span>
                </button>
            </div>
        </div>

        <OptionsButtons />

        <div class="row my-4 justify-content-center">
            <div class="col flex-grow-0">
                <div class="update-item my-2">
                    <span class="label">Update All Packages:</span>
                    <select-field
                        v-model="selectedPackage"
                        placeholder="N/A"
                        :class-value="'form-control w-auto'"
                        :null-value="null"
                        :options="
                            packages.map((row) => ({
                                value: row.id,
                                label: row.name,
                            }))
                        "
                    />
                </div>
            </div>
            <div class="col flex-grow-0">
                <div class="update-item my-2">
                    <span class="label">Update All Rails:</span>
                    <select-field
                        v-model="selectedRail"
                        placeholder="N/A"
                        :class-value="'form-control w-auto'"
                        :null-value="null"
                        :options="
                            rails
                                .filter((row) => this.selectableRailCodes.includes(row.product_code))
                                .map((row) => ({
                                    value: row.id,
                                    label: row.product_code,
                                }))
                        "
                    />
                </div>
            </div>
            <div class="col flex-grow-0">
                <div class="update-item my-2">
                    <span class="label">Update All Scoops:</span>
                    <select-field
                        v-model="selectedScoop"
                        placeholder="N/A"
                        :class-value="'form-control w-auto'"
                        :null-value="null"
                        :options="[
                            { value: 'y', label: 'Yes' },
                            { value: 'n', label: 'No' },
                        ]"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SelectField from "@/components/base/forms/SelectField.vue"
import Modal from "@/components/base/Modal.vue"
import OptionsButtons from "@/modules/Opportunity/components/partials/OptionsButtons.vue"
import { mapActions, mapGetters } from "vuex"

export default {
    components: { SelectField, Modal, OptionsButtons },
    props: {
        savingList: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            selectedPackage: null,
            selectedRail: null,
            selectedScoop: null,
            selectableRailCodes: ["UM", "SC", "FE", "BR"],
            internalSaving: [],
        }
    },
    computed: {
        ...mapGetters({
            opportunity: "opportunity/getOpportunity",
            packages: "pkg/getPackages",
            rails: "rail/getRailTypes",
            items: "opportunity/getProducts",
            products: "product/getProducts",
            beingSaved: "opportunity/getBeingSaved",
        }),
        savingMessages() {
            return this.savingList.concat(this.internalSaving)
        },
        railOptions() {
            return this.rails
                .filter((row) => this.selectableRailCodes.includes(row.product_code))
                .map((row) => ({ value: row.id, label: row.product_code }))
        },
        isSaving() {
            return !!(this.savingList.length > 0)
        },
    },
    watch: {
        selectedScoop: {
            async handler(newVal) {
                if (newVal === 0 || newVal === null) {
                    return
                }

                this.setBeingSaved(["update_all_scoops"])
                let productUpdates = []
                this.items.forEach((item, idx) => {
                    let product = this.selectedProduct(item.product_type_id, item.package_id, item.opening_width)
                    if (product !== undefined) {
                        item.scoop = newVal
                        if (product.scoop === "n") {
                            item.scoop = "n"
                        }

                        productUpdates.push(
                            this.updatingProduct({
                                idx,
                                opportunityId: this.opportunity.id,
                                product: item,
                                whereAt: "selectedScoop",
                            }),
                        )
                    }
                })

                this.productRowIsEditing()
                Promise.all(productUpdates)
                    .then(() => {
                        let postProductUpdate = [
                            this.updateOpportunity({
                                id: this.opportunity.id,
                                opportunity: this.opportunity,
                            }),
                            this.updateTotals({
                                id: this.opportunity.id,
                                totals: {},
                            }),
                        ]
                        return Promise.all(postProductUpdate)
                    })
                    .then(() => {
                        this.unsetBeingSaved(["update_all_scoops"])
                        this.resetVariable(["selectedScoop"])
                        this.productRowIsNotEditing()
                    })
                return null
            },
        },
        selectedPackage: {
            async handler(newVal) {
                if (newVal === 0 || newVal === null) {
                    return
                }
                this.setBeingSaved(["update_all_packages"])
                let productUpdates = []
                await this.items.forEach((item, idx) => {
                    let product = this.selectedProduct(item.product_type_id, newVal, item.opening_width)
                    if (product !== undefined) {
                        item.package_id = newVal
                        item.product_id = product.id

                        let defaultBottomColor = this.determineDefaultBottomColor(product)
                        let defaultRailType = this.determineDefaultRail(product)

                        item.bottom_color_id = defaultBottomColor ? defaultBottomColor.id : null
                        item.rail_type_id = defaultRailType ? defaultRailType.id : null

                        /** If package is allowed a scoop, then set scoop to Y be default */
                        let selectedPackage = this.packages.find((i) => i.id == item.package_id)
                        if (selectedPackage && ["DL", "DM", "DD", "DW"].includes(selectedPackage.abbr)) {
                            if (product.scoop === "y") {
                                item.scoop = "y"
                            }
                        }
                        if (selectedPackage && !["DL", "DM", "DD", "DW"].includes(selectedPackage.abbr)) {
                            item.scoop = "n"
                        }

                        productUpdates.push(
                            this.updateProduct({
                                idx,
                                opportunityId: this.opportunity.id,
                                product: item,
                                whereAt: "selectedPackages",
                            }),
                        )
                    }
                })

                Promise.all(productUpdates)
                    .then(() => {
                        let postProductUpdate = [
                            this.updateOpportunity({
                                id: this.opportunity.id,
                                opportunity: this.opportunity,
                            }),
                            this.updateTotals({
                                id: this.opportunity.id,
                                totals: {},
                            }),
                        ]
                        return Promise.all(postProductUpdate)
                    })
                    .then(() => {
                        this.unsetBeingSaved(["update_all_packages"])
                        this.resetVariable(["selectedRail"])
                        this.selectedPackage = null
                    })
                newVal = null
                return null
            },
        },
        selectedRail: {
            async handler(newVal) {
                if (newVal === 0 || newVal === null) {
                    return
                }

                this.setBeingSaved(["update_all_rails"])
                let productUpdates = []
                this.items.forEach((item, idx) => {
                    let product = this.selectedProduct(item.product_type_id, item.package_id, item.opening_width)
                    if (product !== undefined) {
                        let selectedRailType = product.rail_types.find((row) => row.id === newVal)
                        if (selectedRailType && !(selectedRailType.product_code === "UM" && item.opening_width < 200)) {
                            item.rail_type_id = newVal
                        }

                        productUpdates.push(
                            this.updatingProduct({
                                idx,
                                opportunityId: this.opportunity.id,
                                product: item,
                                whereAt: "selectedRail",
                            }),
                        )
                    }
                })

                this.productRowIsEditing()
                Promise.all(productUpdates)
                    .then(() => {
                        let postProductUpdate = [
                            this.updateOpportunity({
                                id: this.opportunity.id,
                                opportunity: this.opportunity,
                            }),
                            this.updateTotals({
                                id: this.opportunity.id,
                                totals: {},
                            }),
                        ]
                        return Promise.all(postProductUpdate)
                    })
                    .then(() => {
                        this.unsetBeingSaved(["update_all_rails"])
                        this.resetVariable(["selectedRail"])
                        this.productRowIsNotEditing()
                    })
                newVal = null
                return null
            },
        },
    },
    created() {},
    methods: {
        ...mapActions({
            addNewProduct: "opportunity/addNewProducts",
            updateProduct: "opportunity/saveProduct",
            updateOpportunity: "opportunity/updateOpportunity",
            updateTotals: "opportunity/updateTotals",
            setBeingSaved: "opportunity/setBeingSaved",
            unsetBeingSaved: "opportunity/unsetBeingSaved",
            productRowIsEditing: "opportunity/markProductRowIsEditing",
            productRowIsNotEditing: "opportunity/unmarkProductRowIsEditing",
        }),
        addRow(quantity) {
            let message = `Adding ${quantity} row(s).`
            this.internalSaving.push(message)
            this.setBeingSaved(["quick_add_rows"])

            console.log(this.opportunity, {
              opportunity_id: this.opportunity.id,
              quantity,
              isOnline: this.isOnline,
              sendToServer: true,
            })
          
            this.addNewProduct({
              opportunity_id: this.opportunity.id,
              quantity,
              isOnline: this.isOnline,
              sendToServer: true,
            }).then((response) => {
                let idx = this.internalSaving.indexOf(message)
                this.internalSaving.splice(idx, 1)
                this.unsetBeingSaved(["quick_add_rows"])
            })
        },
        resetVariable(property) {
            this[property] = null
        },
        selectedProduct(productTypeId, packageId, openingWidth) {
            return this.selectableProducts(productTypeId, packageId, openingWidth).find((row) => {
                return row.package_id === packageId
            })
        },
        selectableProducts(productTypeId, packageId, openingWidth) {
            return this.products.filter((row) => {
                return (
                    row.product_type_id === productTypeId &&
                    row.min_width <= openingWidth &&
                    row.max_width >= openingWidth
                )
            })
        },
        determineDefaultBottomColor(product) {
            for (let bottomColor of product.bottom_colors) {
                let checkStr = `base_cost_subtract_${bottomColor.product_code.toLowerCase()}_bottom_color`
                if (product[checkStr] === "y") {
                    return bottomColor
                }
            }
            return null
        },
        determineDefaultRail(product) {
            for (let railType of product.rail_types) {
                let checkStr = `base_cost_subtract_${railType.product_code.toLowerCase()}_rail`
                if (product[checkStr] === "y") {
                    return railType
                }
            }
            return null
        },
        updatingProduct(product) {
            return this.updateProduct(product, this.isOnline)
        },
    },
}
</script>

<style scoped>
.quick-action-section {
    padding: 15px 0;
}

.loading-modal {
    margin-top: 200px;
}
</style>