<template>
    <tr>
        <th>Item #</th>
        <th>A</th>
        <th>B</th>
        <th>C</th>
        <th>D</th>
        <th>Order</th>
        <th>Description</th>
        <th>Type</th>
        <th>Opening<br>Width</th>
        <th>Package</th>
        <th>Quantity</th>
        <th>Unit</th>
        <th>Bottom<br>Color</th>
        <th>Rails</th>
        <th>Scoop</th>
        <th>Slope</th>
        <th>Dividers</th>
        <th>Additional<br>Grooves</th>
        <th>A/B/Both</th>
        <th>Divider<br>Option</th>
        <th>Notes</th>
    </tr>
</template>

<script>
export default {

};
</script>

<style scoped>
th {
  #position: -webkit-sticky;
  #position: sticky;
  top: 0;
}
</style>
