<template>
    <div class="table-responsive shadow bg-white pb-3">
        <table class="table table-product position-relative">
            <thead>
                <order-table-header />
            </thead>
            <order-table-body
                :items="items"
                :is-loaded="isLoaded"
                :read-only="hasRowsSaving"
                v-on:saving-row="triggerSavingRow"
            />
        </table>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"

import OrderTableBody from "@/modules/Opportunity/Components/Partials/OrderTableBody.vue"
import OrderTableHeader from "@/modules/Opportunity/Components/Partials/OrderTableHeader.vue"
import OrderProductRow from "@/modules/Opportunity/Components/Partials/OrderProductRow.vue"

export default {
    components: { OrderTableBody, OrderProductRow, OrderTableHeader },
    props: {
        isLoaded: {
            type: Boolean,
            default: false,
        },
    },
    emits: ["saving-row"],
    data() {
        return {
            itemList: [],
            rowsBeingSaved: [],
        }
    },
    computed: {
        ...mapGetters({
            items: "opportunity/getProducts",
            opportunity: "opportunity/getOpportunity",
        }),
        hasRowsSaving() {
            return this.rowsBeingSaved.length > 0
        },
    },
    mounted() {
        this.itemList = this.items
        console.log("OrderTable Mounted", this.items)
        this.checkToAddNewRow(this.items)
    },
    watch: {
        items(newValue, oldValue) {
            console.log("Triggering new items", newValue)
            //this.checkToAddNewRow(newValue)
            //this.itemList = newValue
        },
    },
    methods: {
        ...mapActions({
            addRow: "opportunity/addNewProducts",
        }),
        listKeyBuilder(product, idx) {
            let joinList = []
            joinList.push(idx)
            if (product.hasOwnProperty("id")) {
                joinList.push(product.id)
            }
            return joinList.join(".")
            // if (!["", null].includes(product.product_type_id)) {
            //     joinList.push(product.product_type_id)
            // }
            //
            // if (!["", null].includes(product.package_id)) {
            //     joinList.push(product.package_id)
            // }
            // if (!["", null].includes(product.product_id)) {
            //     joinList.push(product.product_id)
            // }
            // if (!["", null].includes(product.rail_type_id)) {
            //     joinList.push(product.rail_type_id)
            // }
            // return joinList.join(".")
        },
        triggerSavingRow(value) {
            this.updateRowSaving(value)
            this.$emit("saving-row", value)
        },
        updateRowSaving(savingData) {
            if (savingData.isSaving) {
                this.rowsBeingSaved.push(savingData.row)
            } else {
                let idx = this.rowsBeingSaved.indexOf(savingData.row)
                this.rowsBeingSaved.splice(idx, 1)
            }
        },
        checkToAddNewRow(newValue) {
            let addNewRow = true
            newValue.forEach((row, index) => {
                if (row.isNew) {
                    console.log(index, row)
                    addNewRow = false
                }
                let rowIndex = `1.${index.toString().padStart(2, "0")}`
                this.updateRowSaving({ row: rowIndex, isSaving: false })
                this.$emit("saving-row", { row: rowIndex, isSaving: false })
            })

            if (addNewRow) {
                console.log("Attempting to add new row")
                this.addRow({ opportunity_id: this.opportunity.id, quantity: 1, isOnline: this.isOnline })
            }
        },
    },
}
</script>

<style>
.opportunity-product-list {
    position: relative;
}
</style>