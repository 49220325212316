<template>
    <tr
        :class="{
            'has-error': rowHasError,
            'is-deleting': disableMode,
            'is-updating': isUpdating,
        }"
    >
        <td>
            {{ itemNumber }}
            <div v-if="showDetails" @click="openDetailModal">See Details</div>

            <modal
                :show="showDetails && openModal"
                v-on:close="closeDetailModal"
                static-backdrop
                :header-text="`Row Calculations: ${this.itemNumber}`"
            >
                Base Price:
                {{ currency(calculatedDetails ? calculatedDetails.unit_price : 0) }}<br />
                Dividers: {{ calculatedDetails ? calculatedDetails.dividers : 0 }}<br />
                Additional Grooves:
                {{ calculatedDetails ? calculatedDetails.grooves : 0 }}<br />
                Scoop: {{ calculatedDetails ? calculatedDetails.scoop : 0 }}<br />
                Slope: {{ calculatedDetails ? calculatedDetails.slope : 0 }}<br />
                BC Type:
                {{ calculatedDetails ? calculatedDetails.bottom_color : 0 }}<br />
                Rail Type:
                {{ calculatedDetails ? calculatedDetails.rail_type : 0 }}<br />
                Layout: {{ calculatedDetails ? calculatedDetails.layout : 0 }}<br />
                Subtotal:
                {{ currency(calculatedDetails ? calculatedDetails.subtotal : 0) }}<br />
                Materials:
                {{ currency(calculatedDetails ? calculatedDetails.material : 0) }}<br />
                Labor:
                {{ currency(calculatedDetails ? calculatedDetails.labor : 0) }}<br />
            </modal>
        </td>
        <td>
            <check-box
                :disabled="isDisabled"
                v-on:focus="fieldFocus('a_option')"
                v-on:change.native="fieldUnfocus('a_option', true)"
                v-model="item.a_option"
                is-check-value="y"
                is-unchecked-value="n"
            />
        </td>
        <td>
            <check-box
                :disabled="isDisabled"
                v-on:focus="fieldFocus('b_option')"
                v-on:change.native="fieldUnfocus('b_option', true)"
                v-model="item.b_option"
                is-check-value="y"
                is-unchecked-value="n"
            />
        </td>
        <td>
            <check-box
                :disabled="isDisabled"
                v-on:focus="fieldFocus('c_option')"
                v-on:change.native="fieldUnfocus('c_option', true)"
                v-model="item.c_option"
                is-check-value="y"
                is-unchecked-value="n"
            />
        </td>
        <td>
            <check-box
                :disabled="isDisabled"
                v-on:focus="fieldFocus('d_option')"
                v-on:change.native="fieldUnfocus('d_option', true)"
                v-model="item.d_option"
                is-check-value="y"
                is-unchecked-value="n"
            />
        </td>
        <td>
            <check-box
                :disabled="isDisabled"
                v-on:focus="fieldFocus('use_in_order')"
                v-on:change.native="fieldUnfocus('use_in_order', true)"
                v-model="item.use_in_order"
                is-check-value="y"
                is-unchecked-value="n"
            />
        </td>
        <td>
            <input
                type="text"
                class="form-control border-dark"
                @focus="fieldFocus('cabinet_location')"
                @blur="fieldUnfocus('cabinet_location')"
                v-model="item.cabinet_location"
                :disabled="isDisabled"
            />
        </td>
        <td>
            <select-field
                ref="product_type_id"
                class="product_type_select"
                v-on:focus.native="fieldFocus('product_type_id')"
                v-on:blur.native="fieldUnfocus('product_type_id')"
                v-model="item.product_type_id"
                placeholder="Select"
                null-value="0"
                :options="
                    productTypes.map((row) => ({
                        value: row.id,
                        label: `${row.name} | ${row.description}`,
                        code: row.name,
                    }))
                "
                :disabled="isDisabled"
            />
        </td>
        <td>
            <input
                type="text"
                class="form-control"
                @focus="fieldFocus('opening_width')"
                @blur="fieldUnfocus('opening_width')"
                v-model="item.opening_width"
                :disabled="isDisabled || allowOpeningWidth"
            />
        </td>
        <td>
            <select-field
                v-on:focus.native="fieldFocus('package_id')"
                v-on:blur.native="fieldUnfocus('package_id')"
                v-model="item.package_id"
                placeholder=""
                null-value="0"
                :options="
                    selectablePackages.map((row) => ({
                        value: row.id,
                        label: row.name,
                    }))
                "
                :disabled="isDisabled || isSpLike || selectablePackages.length === 0"
            />
        </td>
        <td>
            <input
                type="text"
                class="form-control"
                @focus="fieldFocus('qty')"
                @blur="fieldUnfocus('qty')"
                v-model="item.qty"
                :disabled="isDisabled || !allowMultipleQuantity"
            />
        </td>
        <td>{{ selectedProduct ? selectedProduct.name : "" }}</td>
        <td>
            <select-field
                class="yesNoSelects"
                v-on:focus.native="fieldFocus('bottom_color_id')"
                v-on:blur.native="fieldUnfocus('bottom_color_id')"
                v-model="item.bottom_color_id"
                placeholder=""
                null-value="0"
                :options="
                    selectableBottomColors.map((row) => ({
                        value: row.id,
                        label: row.product_code,
                    }))
                "
                :disabled="isDisabled || !hasBottomColors"
            />
        </td>
        <td>
            <select-field
                class="yesNoSelects"
                v-on:focus.native="fieldFocus('rail_type_id')"
                v-on:blur.native="fieldUnfocus('rail_type_id')"
                v-model="item.rail_type_id"
                placeholder=""
                null-value="0"
                :options="
                    selectableRailTypes.map((row) => ({
                        value: row.id,
                        label: row.product_code,
                    }))
                "
                :disabled="isDisabled || !hasRailTypes"
            />
        </td>
        <td>
            <select-field
                v-on:focus.native="fieldFocus('scoop')"
                v-on:blur.native="fieldUnfocus('scoop')"
                class="yesNoSelects"
                v-model="item.scoop"
                disable-null-option
                :options="
                    allowsScoop
                        ? [
                              { value: 'y', label: 'Yes' },
                              { value: 'n', label: 'No' },
                          ]
                        : [{ value: 'n', label: 'No' }]
                "
                :disabled="isDisabled || !allowsScoop"
            />
        </td>
        <td>
            <select-field
                v-on:focus.native="fieldFocus('slope_type_id')"
                v-on:blur.native="fieldUnfocus('slope_type_id')"
                class="yesNoSelects"
                v-model="item.slope_type_id"
                disable-null-option
                :options="selectableSlopeTypes"
                :disabled="isDisabled || !allowsSlope"
            />
        </td>
        <td>
            <select-field
                v-on:focus.native="fieldFocus('number_of_dividers')"
                v-on:blur.native="fieldUnfocus('number_of_dividers')"
                class="yesNoSelects"
                v-model="item.number_of_dividers"
                placeholder=""
                :options="
                    Array.from(Array(maxDividerCount).keys()).map((row) => ({
                        value: row,
                        label: row,
                    }))
                "
                :disabled="isDisabled || !allowsDividers"
            />
        </td>
        <td>
            <input
                @focus="fieldFocus('number_of_additional_grooves')"
                @blur="fieldUnfocus('number_of_additional_grooves')"
                type="text"
                class="form-control"
                v-model="item.number_of_additional_grooves"
                :disabled="isDisabled || !allowsAdditionalGrooves"
            />
        </td>
        <td>
            <select-field
                v-on:focus.native="fieldFocus('a_b_or_both')"
                v-on:blur.native="fieldUnfocus('a_b_or_both')"
                class="yesNoSelects"
                v-model="item.a_b_or_both"
                placeholder=""
                :null-value="null"
                :options="selectableAorBorBoth"
                :disabled="isDisabled || !allowsABSelection"
            />
        </td>
        <td>
            <select-field
                v-on:focus.native="fieldFocus('divider_option')"
                v-on:blur.native="fieldUnfocus('divider_option')"
                class="yesNoSelects"
                v-model="item.divider_option"
                placeholder=""
                null-value=""
                :options="selectableDividerOptions"
                :disabled="isDisabled || !allowDividerOptions"
            />
        </td>
        <td>
            <input
                type="text"
                class="form-control border-dark"
                @focus="fieldFocus('comments')"
                @blur="fieldUnfocus('comments')"
                v-model="item.comments"
                :disabled="isDisabled"
            />
        </td>
    </tr>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import CheckBox from "@/components/base/forms/CheckBoxField.vue"
import SelectField from "@/components/base/forms/SelectField.vue"
import OpportunityApi from "@/modules/Opportunity/api"
import OpportunityCalculator from "@/modules/Opportunity/OpportunityCalculator"
import Modal from "@/components/base/Modal.vue"
import OrderTableHeader from "@/modules/Opportunity/Components/Partials/OrderTableHeader.vue"

export default {
    components: { OrderTableHeader, Modal, SelectField, CheckBox },
    props: {
        modelValue: {
            type: Object,
            default: null,
        },
        productItem: {
            type: Object,
            required: true,
        },
        isLoaded: {
            type: Boolean,
            default: false,
        },
        isNewRow: {
            type: Boolean,
            default: false,
        },
        showDetails: {
            type: Boolean,
            default: false,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        rowKey: [String, Number],
    },
    emits: ["update:modelValue", "is-focused", "saving-row"],
    data() {
        return {
            item: null,
            prevItemVersion: null,
            focusedElements: new Set(),
            maxDividerCount: 0,
            gOnlyList: ["STORS", "STOLS", "DS", "TDS"],
            bOnlyList: ["STORS", "STOLS"],
            openingWidth1499: ["BSG", "BSC", "BS"],
            noPackageRequired: ["EM", "EL", "SRR"],
            widthRestrictedGrooves55: ["DS55", "TDS55"],
            widthRestrictedGrooves75: ["DS75", "TDS75"],
            rowHasError: false,
            disableMode: false,
            isUpdating: false,
            openModal: false,
            initialRendered: false,
            calculatedDetails: null,
            displayHeaderOnRow: false,
        }
    },
    computed: {
        ...mapGetters({
            opportunity: "opportunity/getOpportunity",
            products: "product/getProducts",
            productTypes: "product/getProductTypes",
            packages: "pkg/getPackages",
            slopeTypes: "slopeType/getSlopeTypes",
            accessories: "accessory/getAccessories",
        }),
        isDisabled() {
            return this.readOnly || this.disableMode
        },
        requiresDirection() {
            return !["0", 0, "", null].includes(this.item.number_of_dividers)
        },
        packageNotRequired() {
            let productType = this.productTypes.find((row) => row.id === this.item.product_type_id)
            return this.noPackageRequired.includes(productType.name) || this.selectablePackages.length === 0
        },
        requiresOpeningWidth() {
            return this.selectableProducts.filter((row) => row.min_width > 0)
        },
        shouldHaveSelectedProduct() {
            return ![null, "", 0].includes(this.item.package_id)
        },
        rowInFocus() {
            return !!(this.focusedElements.size > 0)
        },
        rowKeyIndex() {
            if (typeof this.rowKey === "number") {
                return `1.${this.rowKey.toString().padStart(2, "0")}`
            }
            let splitValue = this.rowKey.split(".")
            return parseInt(parseInt(splitValue[0]) + 1)
        },
        itemNumber() {
            let index = this.rowKeyIndex
            return `1.${index.toString().padStart(2, "0")}`
        },
        selectedProduct() {
            if (this.item === null || ["", "0", 0, null].includes(this.item.product_type_id)) {
                return { name: "" }
            }

            return (
                this.selectableProducts.find((row) => {
                    return this.packageNotRequired || row.package_id === this.item.package_id
                }) || { name: "" }
            )
        },
        selectableProducts() {
            let openingWidth = parseInt(this.item ? this.item.opening_width : "")
            openingWidth = !isNaN(openingWidth) ? openingWidth : ""
            if (this.item === null) {
                return []
            }
            return this.products.filter((row) => {
                return (
                    !this.valueIsEmpty(this.item.product_type_id) &&
                    row.product_type_id === this.item.product_type_id &&
                    (["", null].includes(this.item.opening_width) ||
                        (row.min_width <= openingWidth && row.max_width >= openingWidth))
                )
            })
        },
        requireDividers() {
            let productType = this.productTypes.find((row) => row.id === this.item.product_type_id)
            return (
                productType &&
                (this.widthRestrictedGrooves55.includes(productType.name) ||
                    this.widthRestrictedGrooves75.includes(productType.name))
            )
        },
        selectableAccessories() {
            return this.selectedProduct
                ? this.accessories.filter((row) => row.distributor_id === this.selectedProduct.distributor_id)
                : []
        },
        selectablePackages() {
            let packageIds = this.selectableProducts.map((row) => row.package_id)
            return this.packages.filter((row) => packageIds.includes(row.id))
        },
        selectableBottomColors() {
            return this.selectedProduct.bottom_colors || []
        },
        selectableRailTypes() {
            return this.selectedProduct.rail_types || []
        },
        selectableSlopeTypes() {
            let productType = this.productTypes.find((row) => row.id === this.item.product_type_id)
            if (productType && productType.name === "RII") {
                let riiSlopeOptions = ["SAHR", "SAHL"]
                return this.slopeTypes
                    .filter((row) => riiSlopeOptions.includes(row.name))
                    .map((row) => {
                        return { label: row.name, value: row.id }
                    })
            }
            let defaultSlopeOptions = ["SB1", "SB2", "SAHL1", "SAHL2", "SAHR1", "SAHR2", "SBR1", "SBR2", "No"]
            return this.slopeTypes
                .filter((row) => defaultSlopeOptions.includes(row.name))
                .map((row) => {
                    return { label: row.name, value: row.id }
                })
        },
        selectableAorBorBoth() {
            let productType = this.productTypes.find((row) => row.id === this.item.product_type_id)

            return productType && this.bOnlyList.includes(productType.name)
                ? [{ value: "B", label: "B" }]
                : [
                      { value: "A", label: "A" },
                      { value: "B", label: "B" },
                      { value: "Both", label: "Both" },
                  ]
        },
        selectableDividerOptions() {
            let productType = this.productTypes.find((row) => row.id === this.item.product_type_id)
            return productType && this.gOnlyList.includes(productType.name)
                ? [{ value: "G", label: "G" }]
                : [
                      { value: "C", label: "C" },
                      { value: "G", label: "G" },
                  ]
        },
        hasRailTypes() {
            return !!this.selectableRailTypes.length > 0
        },
        hasBottomColors() {
            return !!this.selectableBottomColors.length > 0
        },
        allowsScoop() {
            return this.selectedProduct.scoop === "y" || false
        },
        allowsSlope() {
            return this.selectedProduct.slope === "y" || false
        },
        allowsDividers() {
            return !!(this.selectedProduct.number_of_dividers > 0)
        },
        allowsAdditionalGrooves() {
            return (
                !!(this.selectedProduct.number_of_dividers > 0) &&
                this.item.number_of_dividers > 0 &&
                !this.hasGrooveRestrictions
            )
        },
        hasGrooveRestrictions() {
            let productType = this.productTypes.find((row) => row.id === this.item.product_type_id)

            return (
                !!productType &&
                (this.widthRestrictedGrooves55.includes(productType.name) ||
                    this.widthRestrictedGrooves75.includes(productType.name))
            )
        },
        allowsABSelection() {
            return !!(
                this.allowsDividers &&
                this.item.number_of_dividers > 0 &&
                this.selectedProduct.a_b_or_both === "y" &&
                this.selectableAorBorBoth.length > 1
            )
        },
        allowMultipleQuantity() {
            return !!(this.selectedProduct.order_multiple === "y")
        },
        allowOpeningWidth() {
            return (
                !!(this.selectedProduct.min_width === 0 && this.selectedProduct.max_width == 0) ||
                (this.selectedProduct && this.selectedProduct.look_up_item_number === "y")
            )
        },
        isSpLike() {
            return !!(this.selectedProduct.id !== undefined && this.selectedProduct.is_sp_like === "y")
        },
        allowDividerOptions() {
            return !!(this.item.number_of_dividers > 0 && this.selectableDividerOptions.length > 1)
        },
    },
    watch: {
        isLoaded: {
            handler: function (newValue) {
                if (newValue === true) {
                    this.rowHasError = this.rowHasErrorCheck()
                }
            },
        },
        rowInFocus: {
            handler: function (newValue) {
                console.log("focusCheck", newValue)
                if (newValue === false) {
                    this.triggerRowIsNotFocused()
                    console.log(this.item.product_id, this.item.cabinet_location);
                    if ([0, "0", "", null].includes(this.item.product_id) && ["", null].includes(this.item.cabinet_location)) {
                        if (
                            ["0", 0, "", null].includes(this.item.product_type_id) &&
                            ["0", 0, "", null].includes(this.item.package_id)
                            //&& this.item.product_type_id !== this.prevItemVersion.product_type_id
                        ) {
                            this.disableMode = true
                            this.removeProduct({
                                id: this.opportunity.id,
                                product: this.item,
                            }).then((response) => {
                                this.productRowIsNotEditing()
                            }).catch((error) => {
                                let errors = ["There was an issue on deleting the product."]
                                this.updateErrors({
                                    row: this.itemNumber,
                                    errors,
                                })
                                this.$emit("saving-row", {
                                    row: this.itemNumber,
                                    isSaving: false,
                                })
                                this.productRowIsNotEditing()
                                this.disableMode = true
                            })
                        }
                        return
                    }
                    console.log("trigger update")
                    this.productRowIsNotEditing()
                    this.$emit("saving-row", {
                        row: this.itemNumber,
                        isSaving: true,
                    })
                    // Placeholder for API to update DB
                    if (
                        ["", null].includes(this.item.qty) ||
                        (this.isNewRow && [null].includes(this.item.product_id))
                    ) {
                        this.productRowIsNotEditing()
                        this.$emit("saving-row", {
                            row: this.itemNumber,
                            isSaving: false,
                        })
                        this.disableMode = false
                        return
                    }

                    this.updateProductApi()
                    this.resetTotals()
                } else {
                  this.productRowIsEditing()
                  this.triggerRowIsFocused()
                }
            },
        },
      selectedProduct: {
        handler: function (newValue, oldValue) {
          if (this.item === null || !this.initialRendered || !this.isLoaded) {
            return;
          }
          console.log("selectedProduct::watch", newValue);
          if(newValue !== null && newValue.id !== undefined && this.item.qty === null) {
            this.item.qty = 1;
          }
          this.maxDividerCount = newValue.number_of_dividers || 0;
          this.item.product_id = newValue.id || 0;
        },
        immediate: true
      },
        products: {
          handler: function (newValue) {
            this.calculator = new OpportunityCalculator(this.opportunity, this.pricingOption, newValue, this.accessories);
          },
        },
        item: {
          handler(newVal) {
            if(this.isLoaded) {
              this.itemUpdateCheck();
              this.rowHasError = this.rowHasErrorCheck();
              this.initialRendered = true;
            }
          },
          deep: true,
        },
        modelValue: {
          handler(newValue) {
            //console.log("OrderProductRow::watch-value", newValue);
            this.item = Object.assign({}, newValue);
            //this.prevItemVersion = Object.assign({}, this.item);
          },
          deep: true
        },
    },
    created() {
        this.calculator = new OpportunityCalculator(
            this.opportunity,
            this.opportunity.pricing_option,
            this.products,
            this.accessories,
        )
        this.item = Object.assign({}, this.modelValue)
        //this.generateCalculatedDetails();
    },
    mounted() {
        this.prevItemVersion = Object.assign({}, this.item)
        this.itemUpdateCheck()
        this.rowHasError = this.rowHasErrorCheck()
        this.prevItemVersion = Object.assign({}, this.item)
        this.initialRendered = true
    },
    methods: {
        ...mapActions({
            addRows: "opportunity/addNewProducts",
            fetchProducts: "opportunity/fetchProducts",
            updateProduct: "opportunity/saveProduct",
            removeProduct: "opportunity/removeProduct",
            updateErrors: "opportunity/updateErrors",
            productRowIsEditing: "opportunity/markProductRowIsEditing",
            productRowIsNotEditing: "opportunity/unmarkProductRowIsEditing",
            resetTotals: "opportunity/resetTotals",
        }),
        valueIsEmpty(value) {
            return ["", null, 0].includes(value)
        },
        triggerRowIsFocused() {
            this.$emit("is-focused", true)
        },
        triggerRowIsNotFocused() {
            this.$emit("is-focused", false)
        },
        openDetailModal() {
            this.openModal = true
            OpportunityApi.fetchCalculationDetails(this.opportunity.id, this.item).then(
                ({ data }) => (this.calculatedDetails = data.pricingBreakdown),
            )
        },
        closeDetailModal() {
            this.openModal = false
        },
        updateProductApi() {
            let idx = this.rowKeyIndex - 1
            this.disableMode = true
            this.isUpdating = true
            this.productRowIsNotEditing()
            this.triggerRowIsNotFocused()


            let data = {
              idx,
              opportunityId: this.opportunity.id,
              product: this.item,
              whereAt: "OrderProductRow::updateProductApi",
              isOnline: this.isOnline
            };

            this.updateProduct(data)
                .then(() => {
                    console.log("testng1")
                    this.productRowIsNotEditing()
                    this.$emit("saving-row", {
                        row: this.itemNumber,
                        isSaving: false,
                    })
                    console.log("testng2")
                    this.disableMode = false
                    this.isUpdating = false
                    this.triggerRowIsNotFocused();
                    console.log("testng")
                })
                .catch((error) => {
                    console.log(error, error.response, error.message)
                    let errors = ["There was an issue on updating the product."]
                    this.updateErrors({ row: this.itemNumber, errors })
                    this.$emit("saving-row", {
                        row: this.itemNumber,
                        isSaving: false,
                    })
                    this.productRowIsNotEditing()
                    this.disableMode = false
                    this.isUpdating = false
                })
        },
        rowHasErrorCheck() {
            let errors = []
            let hasErrors = false
            if (!this.isLoaded || this.item === null) {
                this.updateErrors({ row: this.itemNumber, errors })
                return false
            }
            let emptyValues = ["", 0, "0", null]

            if (
                emptyValues.includes(this.item.product_type_id) &&
                emptyValues.includes(this.item.opening_width) &&
                emptyValues.includes(this.item.package_id)
            ) {
                this.updateErrors({ row: this.itemNumber, errors })
                return hasErrors
            }

            let productType = this.productTypes.find((row) => row.id === this.item.product_type_id)
            if (!this.isSpLike && emptyValues.includes(this.item.package_id) && !this.packageNotRequired) {
                errors.push("You need to select a Package.")
                hasErrors = true
            }

            let openingWidth = parseInt(this.item ? this.item.opening_width : 0)
            openingWidth = !isNaN(openingWidth) ? openingWidth : 0

            if (
                this.item.product_type_id &&
                !_.inRange(openingWidth, this.selectedProduct.min_width, this.selectedProduct.max_width + 1) &&
                this.selectedProduct.look_up_item_number !== "y"
            ) {
                errors.push("Opening width does not match available products.")
                hasErrors = true
            }
            if (this.selectedProduct.look_up_item_number === "y") {
                if (this.item.comments === null || this.item.comments.length === 0) {
                    errors.push("Accessory ID Required for this row.")
                    hasErrors = true
                } else {
                    let selectedAccessory = this.selectableAccessories.find((row) => {
                        return row.item_number.toLowerCase() === this.item.comments.toLowerCase() || ""
                    })

                    if (!selectedAccessory) {
                        errors.push("Accessory is not available for this product.")
                        hasErrors = true
                    }
                }
            }

            if (this.selectedProduct.id) {
                let umRail = this.selectedProduct.rail_types.find((row) => row.product_code == "UM")

                //console.log("RAIL VALIDATION: ", this.selectedProduct.name, this.item.rail_type_id, umRail);
                if (
                    umRail &&
                    this.item.rail_type_id === umRail.id &&
                    this.item.opening_width < 220 &&
                    this.item.opening_width > 0
                ) {
                    errors.push("Minimum width of 220 is required for UM Rails.")
                    hasErrors = true
                }
            }

            if (this.selectableBottomColors.length > 0 && ("", null, 0, "0").includes(this.item.bottom_color_id)) {
                errors.push("This product requires a Bottom Color.")
                hasErrors = true
            }

            if (this.selectableRailTypes.length > 0 && ("", null, 0, "0").includes(this.item.rail_type_id)) {
                errors.push("This product requires a Rail Type.")
                hasErrors = true
            }

            let accessory = this.selectableAccessories.find((accessory) => accessory.item_number === this.item.comments)
            if ([undefined, null].includes(accessory) && this.selectedProduct.look_up_item_number === "y") {
                errors.push("Accessory not found based on provided ID.")
                hasErrors = true
            }

            /** Checks for divider requirements **/
            if (this.requireDividers && ["", null, 0].includes(this.item.number_of_dividers)) {
                errors.push("Product requires a min of 1 divider.")
                hasErrors = true

                this.updateErrors({ row: this.itemNumber, errors })
                return hasErrors
            }

            /** Set RestrictedGrooveCount 55 Validation **/
            if (productType && this.widthRestrictedGrooves55.includes(productType.name)) {
                let maxCount = this.restrictGrooveCount55(this.item.opening_width)
                let currentDivider = this.item.number_of_dividers
                let currentGrooves = this.item.number_of_additional_grooves

                if (maxCount === 0) {
                    errors.push(`Opening width must be at least 156.`)
                    hasErrors = true
                } else if (maxCount < currentDivider + currentGrooves) {
                    errors.push(`Divider count cannot exceed ${maxCount}.`)
                    hasErrors = true
                }
            }

            /** Set RestrictedGrooveCount 75 Validation **/
            if (productType && this.widthRestrictedGrooves75.includes(productType.name)) {
                let maxCount = this.restrictGrooveCount75(this.item.opening_width)
                let currentDivider = this.item.number_of_dividers
                let currentGrooves = this.item.number_of_additional_grooves

                if (maxCount === 0) {
                    errors.push(`Opening width must be at least 156.`)
                    hasErrors = true
                } else if (maxCount < currentDivider + currentGrooves) {
                    errors.push(`Divider count cannot exceed ${maxCount}.`)
                    hasErrors = true
                }
            }

            /** Requires SlopeTypes if Product Type RII **/
            if (
                productType &&
                ["RII"].includes(productType.name) &&
                [1, 0, "", "0", null].includes(this.item.slope_type_id)
            ) {
                errors.push(`Product Requires a slope.`)
                hasErrors = true
            }

            if ([null, "", 0, "0"].includes(this.item.product_type_id)) {
                this.updateErrors({ row: this.itemNumber, errors })
                return hasErrors
            }

            if (this.requiresDirection && ["", null].includes(this.item.a_b_or_both)) {
                errors.push("You need to select A/B/Both option.")
                hasErrors = true
            }

            if (!["", null].includes(this.item.a_b_or_both) && ["", null].includes(this.item.divider_option)) {
                errors.push("You need to select a divider option.")
                hasErrors = true
            }

            if (!["", null].includes(this.item.a_b_or_both) && this.item.number_of_dividers === 0) {
                errors.push("Dividers selection must be greater than 0 to have a A/B/Both option.")
                hasErrors = true
            }

            this.updateErrors({ row: this.itemNumber, errors })
            return hasErrors
        },
        generateCalculatedDetails() {
            let product = this.products.find((product) => product.id === this.item.product_id)
            let slopeTypeObj = this.slopeTypes.find((slopeType) => {
                return slopeType.id === this.item.slope_type_id
            })

            let baseCost = product ? this.calculator.fetchBaseCost(this.item, product) : 0.0
            let scoopPrice = product ? this.calculator.getScoopPrice(this.item, product) : 0.0
            let slopePrice = product ? this.calculator.getSlopePrice(this.item, product) : 0.0

            let bottomColor = product
                ? product.bottom_colors.find((bottomColor) => bottomColor.id === this.item.bottom_color_id)
                : { product_code: "Unknown" }
            let railType = product
                ? product.rail_types.find((railType) => railType.id === this.item.rail_type_id)
                : { product_code: "Unknown" }
            let bottomColorCost = product ? this.calculator.selectedBottomColorsCost(product, this.item, null) : 0.0
            let railTypeCost = product ? this.calculator.selectedRailsCost(product, this.item, null) : 0.0

            let aBorBoth = product ? this.calculator.getAbOrBothPrice(this.item) : 0.0
            let subtotal = product ? this.calculator.fetchFinalPrice(this.item) : 0.0
            let material = product ? this.calculator.calculateMaterialPrice(this.item) : 0.0
            let labor = product ? this.calculator.calculateLaborPrice(this.item) : 0.0

            this.calculatedDetails = {
                unit_price: baseCost.toFixed(2),
                dividers: `${this.item.number_of_dividers} x ${this.opportunity.pricing_option.cost_dividers.toFixed(
                    2,
                )}`,
                grooves: `${
                    this.item.number_of_additional_grooves
                } x ${this.opportunity.pricing_option.cost_grooves.toFixed(2)}`,
                scoop: `${this.item.scoop === "y" ? "Yes" : "No"}: ${scoopPrice.toFixed(2)}`,
                slope: `${slopeTypeObj ? slopeTypeObj.name : "Unknown"}: ${slopePrice.toFixed(2)}`,
                bottom_color: `${bottomColor ? bottomColor.product_code : "Unknown"} → ${bottomColorCost.toFixed(2)}`,
                rail_type: `${railType ? railType.product_code : "Unknown"} → ${railTypeCost.toFixed(2)}`,
                layout: `${this.item.a_b_or_both}: ${aBorBoth.toFixed(2)}`,
                subtotal: subtotal.toFixed(2),
                material: material.toFixed(2),
                labor: labor.toFixed(2),
            }
        },
        restrictGrooveCount55(openingWidth) {
            let list = [
                { min: 156, max: 217, count: 1 },
                { min: 218, max: 278, count: 2 },
                { min: 279, max: 339, count: 3 },
                { min: 340, max: 401, count: 4 },
                { min: 402, max: 462, count: 5 },
                { min: 463, max: 523, count: 6 },
                { min: 524, max: 585, count: 7 },
                { min: 586, max: 646, count: 8 },
                { min: 647, max: 708, count: 9 },
                { min: 709, max: 769, count: 10 },
                { min: 770, max: 830, count: 11 },
                { min: 831, max: 892, count: 12 },
                { min: 893, max: 953, count: 13 },
                { min: 954, max: 1014, count: 14 },
                { min: 1015, max: 1076, count: 15 },
                { min: 1077, max: 1137, count: 16 },
                { min: 1138, max: 1200, count: 17 },
            ]

            for (let row of list) {
                if (row.min <= openingWidth && openingWidth <= row.max) {
                    return row.count
                }
            }

            return 0
        },
        restrictGrooveCount75(openingWidth) {
            let list = [
                { min: 156, max: 236, count: 1 },
                { min: 237, max: 318, count: 2 },
                { min: 319, max: 399, count: 3 },
                { min: 400, max: 480, count: 4 },
                { min: 481, max: 562, count: 5 },
                { min: 563, max: 643, count: 6 },
                { min: 644, max: 724, count: 7 },
                { min: 725, max: 805, count: 8 },
                { min: 806, max: 887, count: 9 },
                { min: 888, max: 968, count: 10 },
                { min: 969, max: 1049, count: 11 },
                { min: 1050, max: 1131, count: 12 },
                { min: 1132, max: 1200, count: 13 },
            ]

            for (let row of list) {
                if (row.min <= openingWidth && openingWidth <= row.max) {
                    return row.count
                }
            }

            return 0
        },
        determineDefaultBottomColor(product) {
            for (let bottomColor of this.selectableBottomColors) {
                console.log(this.selectableBottomColors, bottomColor.product_code)
                let checkStr = `base_cost_subtract_${bottomColor.product_code.toLowerCase()}_bottom_color`
                if (product[checkStr] === "y") {
                    return bottomColor
                }
            }
            return null
        },
        determineDefaultRail(product) {
            for (let railType of this.selectableRailTypes) {
                let checkStr = `base_cost_subtract_${railType.product_code.toLowerCase()}_rail`
                if (product[checkStr] === "y") {
                    return railType
                }
            }
            return null
        },
        fieldFocus(fieldName) {
            console.log("focusing", fieldName)
            const currentState = this.focusedElements
            currentState.add(fieldName)
            this.focusedElements = new Set(currentState)
        },
        fieldUnfocus(fieldName, isCheckbox = false) {
            console.log("unfocusing", fieldName)
            setTimeout(() => {
                this.focusedElements.delete(fieldName)
                this.focusedElements = new Set(this.focusedElements.values())
            }, 100)
        },
        notesChanged(accItemNumber) {
            let accessory = this.selectableAccessories.find((accessory) => accessory.item_number === accItemNumber)
            if (accessory !== undefined) {
                let priceRow = accessory.franchisee_prices.find(
                    (price) => price.franchisee_id === this.opportunity.franchisee_id,
                )
                this.item.opening_width = priceRow.retail
            } else {
                this.item.opening_width = ""
            }
        },
        shallowEqual(object1, object2) {
            const keys1 = Object.keys(object1)
            const keys2 = Object.keys(object2)

            if (keys1.length !== keys2.length) {
                console.log("Key Length is off")
                return false
            }

            for (let key of keys1) {
                if (object1[key] !== object2[key]) {
                    console.log(`${key} values differ ${object1[key]}, ${object2[key]}`)
                    return false
                }
            }

            return true
        },
        itemUpdateCheck(newVal, oldVal) {
            const oldValue = Object.assign({}, this.prevItemVersion)
            const newValue = this.item

            console.log("itemUpdateCheck init", oldValue, newValue, {isLoaded: this.isLoaded, initialRendered: this.initialRendered})

            //console.log("itemUpdateCheck", "Check if row has not been loaded/renderred or no OldValue available")
            if (oldValue === null || !this.initialRendered || !this.isLoaded) {
                return newValue
            }

            //console.log("itemUpdateCheck", "Check shallow Equals Check")
            if (this.shallowEqual(oldValue, newValue)) {
                return
            }

            //console.log("itemUpdateCheck", "Check Reset Quantity to 1")
            if (["0", 0, "", null].includes(oldValue.product_id) && newValue.product_id !== oldValue.product_id) {
                console.log("itemUpdateCheck() - 1", newValue)
                this.item.qty = 1
            }

            //console.log("itemUpdateCheck", "Check if Product Type Changed: Reset")
            if (
                oldValue.product_type_id !== newValue.product_type_id ||
                ["0", 0, "", null].includes(oldValue.product_type_id)
            ) {
                console.log("itemUpdateCheck() - 2", newValue)
                this.item.qty = ["0", 0, "", null].includes(newValue.product_type_id) ? 0 : 1
                this.item.opening_width = null
                this.item.package_id = null
                //&& ["", null].includes(newValue.cabinent_location)
                this.item.product_id = ["0", 0, "", null].includes(newValue.product_type_id)
                    ? null
                    : this.selectedProduct.hasOwnProperty("id")
                    ? this.selectedProduct.id
                    : 0
                this.item.number_of_dividers = 0
                this.item.number_of_additional_grooves = 0
                this.item.slope_type_id = 1
                this.item.scoop = "n"
                this.item.a_option = "y"
                this.item.use_in_order = "y"
            }

            //console.log("itemUpdateCheck", "Check if Only one Package available and set as default")
            if (this.selectablePackages.length === 1) {
                this.item.package_id = this.selectedProduct.package ? this.selectedProduct.package.id : null
            }

            //console.log("itemUpdateCheck", "Check selected product is a Lookup Item")
            if (this.selectedProduct && this.selectedProduct.look_up_item_number === "y") {
                this.notesChanged(newValue.comments)
                if (this.item.comments && this.item.comments.length === 0) {
                    this.item.opening_width = null
                }
            }

            /** Reset Additional, A/B/Both and DividerOption when Dividers = 0 **/
            //console.log("itemUpdateCheck", "Reset A/B/Both and Divider Options")
            if ([0, "", null].includes(this.item.number_of_dividers)) {
                this.item.a_b_or_both = ""
                this.item.divider_option = ""
            }

            /** Handle BottomColor & RailType Defaults **/
            console.log("itemUpdateCheck", "Handle Bottom Color and Rail Default", this.item.product_id, this.prevItemVersion.product_id)
            if (this.prevItemVersion.package_id !== this.item.package_id) {
                let product = this.selectedProduct
                let defaultBottomColor = this.determineDefaultBottomColor(product)
                let defaultRailType = this.determineDefaultRail(product)

                this.item.bottom_color_id = defaultBottomColor ? defaultBottomColor.id : null
                this.item.rail_type_id = defaultRailType ? defaultRailType.id : null
                console.log("itemUpdateCheck", "Has Set Default Bottom Color and Rail Type", defaultBottomColor, defaultRailType)
            }

            //console.log("itemUpdateCheck", "Check if only 1 Rail anc set as default")
            if (this.selectableRailTypes.length === 1) {
                this.item.rail_type_id = this.selectableRailTypes[0].id
            }

            let productType = this.productTypes.find((row) => row.id === this.item.product_type_id)
            if (productType === undefined) {
                this.prevItemVersion = Object.assign({}, this.item)
                return
            }

            if (
                [0, "", null].includes(this.item.number_of_dividers) &&
                !this.widthRestrictedGrooves55.includes(productType.name) &&
                !this.widthRestrictedGrooves75.includes(productType.name)
            ) {
                this.item.number_of_additional_grooves = 0
            }

            /** Set B Option **/
            if (productType && this.bOnlyList.includes(productType.name)) {
                this.item.a_b_or_both = "B"
            }

            /** Set G Divider Options **/
            if (productType && this.gOnlyList.includes(productType.name)) {
                this.item.divider_option = "G"
            }

            /** Set OpeningWidth on BSG/BSC select **/
            if (this.prevItemVersion.product_type_id !== newValue.product_type_id) {
                if (productType && this.openingWidth1499.includes(productType.name)) {
                    this.item.opening_width = 1499
                }
            }

            /** Set RestrictedGrooveCount 55 **/
            if (
                productType &&
                this.widthRestrictedGrooves55.includes(productType.name) &&
                (newValue.number_of_dividers !== this.prevItemVersion.number_of_dividers ||
                    newValue.opening_width !== this.prevItemVersion.opening_width)
            ) {
                let maxCount = this.restrictGrooveCount55(newValue.opening_width)
                let currentDivider = newValue.number_of_dividers

                this.item.number_of_dividers = currentDivider || 0
                this.item.number_of_additional_grooves = currentDivider < maxCount ? maxCount - currentDivider : 0
            }
            /** Set RestrictedGrooveCount 75 **/
            if (
                productType &&
                this.widthRestrictedGrooves75.includes(productType.name) &&
                (newValue.number_of_dividers !== this.prevItemVersion.number_of_dividers ||
                    newValue.opening_width !== this.prevItemVersion.opening_width)
            ) {
                let maxCount = this.restrictGrooveCount75(newValue.opening_width)
                let currentDivider = newValue.number_of_dividers

                this.item.number_of_dividers = currentDivider || 0
                this.item.number_of_additional_grooves = currentDivider < maxCount ? maxCount - currentDivider : 0
            }

            if (
                this.widthRestrictedGrooves55.includes(productType.name) ||
                this.widthRestrictedGrooves75.includes(productType.name)
            ) {
                if (
                    newValue.number_of_dividers !== this.prevItemVersion.number_of_dividers &&
                    this.prevItemVersion.number_of_dividers === 0
                ) {
                    this.item.a_b_or_both = "B"
                    this.item.divider_option = "G"
                }
            }

            if (this.prevItemVersion.number_of_dividers > 0) {
                if (this.item.a_b_or_both === "Both" && this.prevItemVersion.a_b_or_both !== "Both") {
                    this.item.divider_option = "G"
                }
            }

            /** If package is allowed a scoop, then set scoop to Y be default */
            let selectedPackage = this.packages.find((i) => i.id == this.item.package_id)
            if (
                this.item.package_id !== this.prevItemVersion.package_id &&
                selectedPackage &&
                ["DL", "DM", "DD", "DW"].includes(selectedPackage.abbr)
            ) {
                if (this.selectedProduct.scoop === "y") {
                    this.item.scoop = "y"
                }
            }
            if (
                this.item.package_id !== this.prevItemVersion.package_id &&
                selectedPackage &&
                !["DL", "DM", "DD", "DW"].includes(selectedPackage.abbr)
            ) {
                this.item.scoop = "n"
            }

            this.prevItemVersion = Object.assign({}, this.item)
        },
    },
}
</script>

<style scoped>
td .form-check {
    text-align: center;
}

input.qty-input {
    width: 60px;
}

select.yesNoSelects {
    width: 70px;
}

select.product_type_select {
    width: 80px;
}

tr.has-error td {
    background-color: #f9d7db !important;
    border-bottom: 1px solid #632329;
    transition: 1s linear all;
}

.notes-textarea {
    min-width: 150px;
}

.is-deleting {
    opacity: 15%;
    animation: slideup 1s;
    -moz-animation: slideup 1s;
    -webkit-animation: slideup 1s;
    -o-animation: slideup 1s;
    transition: 1s linear all;
}

.is-updating {
    transition: 1s linear all;
    opacity: 45%;
    animation: slideup 1s;
    -moz-animation: slideup 1s;
    -webkit-animation: slideup 1s;
    -o-animation: slideup 1s;
}

.is-updating:not(.has-error) {
    background-color: #d3f8d3 !important;
}
</style>