<template>
    <div class="fixed-bottom">
    <LocalStorageStats/>
    <footer class="footer d-flex justify-content-between">
      <div>ShelfGenie - WishMaker</div>
      <div><b>Version:</b> <i>test</i></div>
    </footer>
    </div>
</template>

<script>
import LocalStorageStats from "@/components/LocalStorageStats.vue";

export default {
  components: {LocalStorageStats}
}
</script>

<style>
  .footer {
    background-color: #eeeeee;
    padding: 15px;
    vertical-align: middle;
  }
</style>