<template>
    <div>
        <div class="col my-1 flex-grow-0">
            <button
                class="btn btn-secondary"
                :class="{ disabled: disabled }"
                v-tooltip-raw:top="buttonTooltip"
                @click="openPaymentModal"
            >
                Payment Options
            </button>
        </div>
        <div class="d-inline">
            <modal :show="openModal" v-on:close="closePaymentModal" static-backdrop header-text="Payment Options">
                <div class="title-label">
                    Total Project Cost: <span class="-pull-right">${{ currency(totals.opportunity_total) }}</span>
                </div>
                <p class="subtitle-label">Please select the preferred payment option:</p>
                <div v-if="initializing" class="m-auto p-5 text-center">
                    <i class="loading-icons fas fa-spinner fa-spin text-info fa-4x" style="margin-right: 35px"></i>
                </div>
                <div v-else>
                    <div v-if="currentPaymentMonths === 0">
                        You have recently selected "Today's Special." Please reset your Discount Options to enable
                        Payment Options.
                    </div>
                    <!--
<div class="row">
    <div class="col-1">
        <input type="radio" class="mt-2" v-model="financeOption" :value="0" />
    </div>
    <div class="col-11 mb-3">
        <div class="option-label">No Payment Option Selected</div>
    </div>
</div>
-->
                    <div v-else v-for="(finance, idx) in paymentOptions" :key="idx" class="row">
                        <div class="col-1">
                            <input type="radio" class="mt-2" v-model="financeOption" :value="finance.id" />
                        </div>
                        <div class="col-8 mb-3">
                            <div class="option-label" v-if="finance.months === 0">Today's Special: Cash or Check</div>
                            <div class="option-label" v-else>
                                {{ paymentOptionLabel(idx, finance, paymentOptions[0]) }}
                            </div>
                            <div class="option-sublabel">
                                Minimum required deposit: ${{ currency(finance.deposit) }}
                                <span v-if="finance.months > 0">*</span>
                            </div>
                        </div>
                        <div class="col-3 mb-3 text-right monthly-label">${{ currency(finance.monthly_payment) }}</div>
                    </div>
                </div>
                <p class="payment-disclaimer">
                    * This is an Estimated Monthly Payment Plan dependent on Credit Approval.
                </p>
                <div v-if="errors.length > 0">
                    <p v-for="(error, idx) in errors" :key="idx" v-text="error" class="error-messages" />
                </div>
                <div v-if="!initializing && isLoading" class="mb-2 update-message text-success">
                    <i class="fas fa-spinner fa-spin"></i> {{ updatingMessage }}
                </div>
                <div>
                    <button
                        type="button"
                        class="btn btn-primary"
                        @click="setPaymentOptions"
                        :disabled="isLoading || [0, '', null].includes(financeOption)"
                    >
                        Select Payment
                    </button>
                </div>
            </modal>
        </div>
    </div>
</template>

<script>
import Modal from "@/components/base/Modal.vue"
import { mapActions, mapGetters } from "vuex"
import numeral from "numeral"

export default {
    name: "PaymentOptionModal",
    components: { Modal },
    props: {
        opportunity: {
            type: Object,
            required: true,
        },
        show: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        disabledMessage: {
            type: String,
            default: null,
        },
    },
    computed: {
        ...mapGetters({
            financeOptions: "opportunity/getFinanceOptions",
            totals: "opportunity/getTotals",
        }),
        buttonTooltip() {
            return {
                title: this.disabledMessage,
                show: this.disabled,
            }
        },
    },
    data() {
        return {
            paymentOptions: [],
            openModal: false,
            isLoading: false,
            initializing: false,
            financeOption: 0,
            errors: [],
            updatingMessage: "",
            currentPaymentMonths: null,
        }
    },
    created() {
        this.openModal = this.show
    },
    watch: {
        openModal(newValue) {
            if (newValue) {
                this.getPaymentOptions()
            }
        },
        isLoading() {},
    },
    methods: {
        ...mapActions({
            getTotals: "opportunity/fetchTotals",
            fetchPricingOption: "opportunity/fetchPricingOption",
            loadPricingOption: "opportunity/loadPricingOption",
            loadTotals: "opportunity/loadTotals",
        }),
        minimumDeposit(percentage) {
            return this.opportunity.opportunity_total * (percentage / 100)
        },
        paymentOptionLabel(idx, finance, firstOption) {
            if (idx === 0 && finance.percent == 0) {
                return `${finance.months} Months: No Interest, No Payments`
            }
            if (idx === 1 && firstOption.percent == 0) {
                return `${finance.months} Months: Low Interest, Fixed Monthly Payments`
            }
            return `${finance.months} Months: ${numeral(Math.abs(finance.percent) / 100).format(",3.00 %")} Interest`
        },
        getPaymentOptions() {
            this.isLoading = true
            this.initializing = true
            this.errors = []
            axios
                .get(this.opportunity.routes.getPaymentOptions)
                .then((response) => {
                    this.paymentOptions = response.data.financeOptions
                    this.currentPaymentMonths = response.data.paymentMonths
                    this.initializing = false
                    this.isLoading = false
                    this.financeOption = null
                })
                .catch((e) => {
                    this.initializing = false
                    this.isLoading = false
                    this.errors.push("There was an issue attempting to get the available Payment Options.")
                })
        },
        setPaymentOptions() {
            this.updatingMessage = "Updating Opportunity's Payment Option..."
            this.isLoading = true
            this.errors = []
            axios
                .post(
                    this.opportunity.routes.setPaymentOptions,
                    { financeOption: this.financeOption },
                    { timeout: 500000 },
                )
                .then((response) => {
                    this.updatingMessage = "Calculating new totals..."
                    this.loadPricingOption(response.data.pricingOption)
                    setTimeout(() => {
                        this.updatingMessage = "Updating totals..."
                        this.loadTotals(response.data.totals)
                        setTimeout(() => {
                            this.closePaymentModal()
                            this.updatingMessage = ""
                            this.isLoading = false
                        }, 1000)
                    }, 1000)
                })
                .catch((e) => {
                    console.log("ERR:", e.response)
                    this.isLoading = false
                    this.errors.push("There was an issue attempting to select the Payment Option.")
                })
        },
        openPaymentModal() {
            if (!this.disabled) {
                this.openModal = true
            }
        },
        closePaymentModal() {
            this.openModal = false
        },
    },
}
</script>