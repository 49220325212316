const prefix = `/accessories`

export default {
    getAll: (opportunity_id = null) => {
        let params = []
        if (opportunity_id) {
            params.push(`opportunity_id=${opportunity_id}`)
        }
        return axios.get(prefix + `?${params.join("&")}`)
    },
}
