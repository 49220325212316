const prefix = `/opportunity`

export default  {
    syncData,
}

function syncData(sendData) {
        return axios.post(`${prefix}/sync`,sendData)
}

