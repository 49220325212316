import SyncApi from "@/api/syncdataApi";
import Localbase from "localbase"
import Dexie from "dexie"
const db = new Dexie("wishmaker")

export default {
    
    async sendSyncData(isOnline = true) {
        //console.log("D1")
        

        console.log("test")
        //let db = new Localbase("wishmaker")

        let opportunity = [];
        let opportunity_financing_option = [];
        let opportunity_pricing_option = [];
        let opportunity_product = [];

        /* opportunity                     =    await db.opportunity.get();
        opportunity_financing_option    =    await db.opportunity_financing_option.get();
        opportunity_pricing_option      =    await db.opportunity_pricing_option.get();
        opportunity_product             =    await db.opportunity_product.get(); */

        /*    opportunity =  await db.collection("opportunity").get()
            opportunity_financing_option =  await db.collection("opportunity_financing_option").get()
            opportunity_pricing_option =  await db.collection("opportunity_pricing_option").get()
            opportunity_product =  await db.collection("opportunity_product").get() */
            console.log("db",db)

            //opportunity =  await db.franchisee_product_price.get()
            opportunity =  await db.table("franchisee_product_price").toArray()
           /*  opportunity_financing_option =  await db.table("opportunity_financing_option").toArray()
            opportunity_pricing_option =  await db.table("opportunity_pricing_option").toArray()
            opportunity_product =  await db.table("opportunity_product").toArray() */

            console.log("opportunity",opportunity)

        let data = {opportunity,opportunity_financing_option,opportunity_pricing_option,opportunity_product}
        //console.log("D2")
        return await SyncApi.syncData(data)

    },
};

 