import newMapper from "@/middleware/mapper"
import PackageApi from "@/modules/Package/api"
import reset from "@/store/reset"

const defaultState = () => ({
    packages: [],
    packagesLoaded: false,
})

export default {
    namespaced: true,
    state: defaultState,
    getters: {
        getPackages: (state) => {
            return state.packages
        },
        arePackagesLoaded: (state) => {
            return state.packagesLoaded
        },
    },
    mutations: {
        SET_PACKAGES: (state, payload) => {
            state.packages = payload
            state.packagesLoaded = true
        },
        RESET: (state) => reset(state, defaultState()),
    },
    actions: {
        fetchPackages: ({ commit }, isOnline) => {
            newMapper({ handler: PackageApi.getAll, action: "GET" }, "package", isOnline).then((packages) => {
                commit("SET_PACKAGES", packages)
            })
        },
        loadPackages:  ({ commit }) => {
            newMapper({ handler: PackageApi.getAll, action: "GET" }, "package", false).then((packages) => {
                commit("SET_PACKAGES", packages)
            })
        },
        reset: ({ commit }) => commit("RESET"),
    },
}
