const prefix = `/products`

export default {
    getAll: (args = {}) => {
        let params = []
        Object.keys(args).forEach((key) => {
            params.push(`${key}=${args[key]}`)
        })
        return axios.get(prefix + `?${params.join("&")}`)
    },
}
